import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { TableViewParameters } from '@/models/TableView'
import { Markup, MarkupTableViewResult } from '@/models/dto/Markup'

const httpService: HttpService = new HttpService()

export default {
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<MarkupTableViewResult>> {
    const { sorts = null, filters = null, pageSize = 10, page = 1 } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }

    query = encodeURI(query)
    const host = apiBaseUrl()
    const url = `https://${host}/tables/markups?${query}`
    return httpService.get(url)
  },
  byId(id: number): Promise<AxiosResponse<Markup>> {
    const url = `https://${apiBaseUrl()}/markup/${id}`
    return httpService.get(url)
  },
  markups(): Promise<AxiosResponse<{ resultList: Markup[] }>> {
    const url = `https://${apiBaseUrl()}/markup`
    return httpService.get(url)
  },
  create(markups: Markup[]): Promise<AxiosResponse<{ markups: Markup[] }>> {
    const url = `https://${apiBaseUrl()}/markup/batch`
    return httpService.post(url, markups)
  },
  update(markup: Markup): Promise<AxiosResponse> {
    const { markupId: id } = markup
    const url = `https://${apiBaseUrl()}/markup/${id}`
    return httpService.patch(url, markup)
  },
  delete(markup: Markup): Promise<AxiosResponse> {
    const { markupId: id } = markup
    const url = `https://${apiBaseUrl()}/markup/${id}`
    return httpService.delete(url)
  },
}
