import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { InvoiceEmail, InvoiceResult } from '@/models/dto/Invoice'
import { EmailTemplate } from '@/models/dto/Template'
import { ReservationManualPaymentRequest } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  byHash(reservationHashes: string[]): Promise<AxiosResponse<InvoiceResult>> {
    return httpService.post(`https://${apiBaseUrl()}/invoice/reservations`, {
      reservationHashes,
    })
  },
  getEmailTemplate(): Promise<AxiosResponse<EmailTemplate>> {
    const url = `https://${apiBaseUrl()}/invoice/email`
    return httpService.get(url)
  },
  saveEmailTemplate(
    template: EmailTemplate
  ): Promise<AxiosResponse<EmailTemplate>> {
    const url = `https://${apiBaseUrl()}/invoice/email`
    return httpService.post(url, template)
  },
  updateEmailTemplate(
    template: Partial<EmailTemplate>
  ): Promise<AxiosResponse<EmailTemplate>> {
    const url = `https://${apiBaseUrl()}/invoice/email`
    return httpService.patch(url, template)
  },
  sendInvoiceEmail(invoiceEmail: InvoiceEmail): Promise<AxiosResponse<void>> {
    const url = `https://${apiBaseUrl()}/invoice/sendEmail`
    return httpService.post(url, invoiceEmail)
  },
}
