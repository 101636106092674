
import { Vue, Component, Prop } from 'vue-property-decorator'
import companyClient from '@/services/company'
import DocumentUpload from '@/components/DocumentUpload.vue'
import { isValidHexCode } from '@/utils/string'
import { Company } from '@/models/dto/Company'
import SettingsGeneralBrandingPreview from './SettingsGeneralBrandingPreview.vue'
import { staticResource } from '@/utils/env'
import SettingsGeneralFaviconPreview from '@/components/SettingsGeneralFaviconPreview.vue'
import { EventBus } from '@/utils/eventBus'
@Component({
  components: {
    DocumentUpload,
    SettingsGeneralBrandingPreview,
    SettingsGeneralFaviconPreview,
  },
})
export default class SettingsGeneralBranding extends Vue {
  @Prop({ required: true, default: null }) readonly companyId!: number
  logoFile = null
  faviconFile = null
  primaryColor = ''
  secondaryColor = ''
  logoUrl = ''
  faviconUrl = ''
  colorsChanged = false
  logoChanged = false
  faviconChanged = false
  companyName = ''
  isValidHexCode = isValidHexCode

  get logoUrlForPreview(): string {
    return this.logoChanged ? this.logoUrl : staticResource(this.logoUrl)
  }

  get faviconUrlForPreview(): string {
    if (!this.faviconUrl) {
      return this.logoUrlForPreview
    }
    return this.faviconChanged
      ? this.faviconUrl
      : staticResource(this.faviconUrl)
  }

  handleColorChange(isPrimary: boolean): void {
    this.colorsChanged = true
    if (isPrimary) {
      if (!this.primaryColor.includes('#')) {
        this.primaryColor = `#${this.primaryColor}`
      }
    } else if (!this.secondaryColor.includes('#')) {
      this.secondaryColor = `#${this.secondaryColor}`
    }
  }

  handleLogoUpload(newLogo: File): void {
    this.logoChanged = true
    if (!newLogo) {
      this.logoUrl = ''
      return
    }
    const formData = new FormData()
    formData.append('file', newLogo)
    this.logoFile = formData
    this.logoUrl = URL.createObjectURL(newLogo)
    this.$emit(this.logoUrl)
  }

  handleFaviconUpload(favicon: File): void {
    this.faviconChanged = true
    if (!favicon) {
      this.faviconUrl = ''
      return
    }
    const formData = new FormData()
    formData.append('file', favicon)
    this.faviconFile = formData
    const faviconUrl = URL.createObjectURL(favicon)
    this.faviconUrl = faviconUrl
    this.$emit(faviconUrl)
  }

  async submit(): Promise<void> {
    const payload: Partial<Company> = {
      primaryColor: this.isValidHexCode(this.primaryColor)
        ? this.primaryColor
        : '',
      secondaryColor: this.isValidHexCode(this.secondaryColor)
        ? this.secondaryColor
        : '',
    }

    try {
      if (this.colorsChanged) {
        await companyClient.update(this.companyId, payload)
        this.colorsChanged = false
      }
      if (this.logoChanged) {
        if (this.logoFile === null) {
          await companyClient.update(this.companyId, { darkLogoUrl: '' })
        } else {
          await companyClient.uploadBranding(
            this.companyId,
            'dark',
            this.logoFile
          )
        }
        this.logoChanged = false
      }

      if (this.faviconChanged) {
        if (this.faviconFile === null) {
          await companyClient.update(this.companyId, { faviconUrl: '' })
        } else {
          await companyClient.uploadBranding(
            this.companyId,
            'favicon',
            this.faviconFile
          )
        }
        this.faviconChanged = false
      }
      EventBus.$emit('snackbar:success', 'Branding saved successfully.')
    } catch (e) {
      EventBus.$emit('snackbar:error', 'Error updating company')
    }

    this.load()
  }

  async load(): Promise<void> {
    const companyResponse = await companyClient.byId(this.companyId)
    const company = companyResponse?.data?.company
    this.companyName = company.name
    this.logoUrl = company.darkLogoUrl
    this.faviconUrl = company.faviconUrl
    this.primaryColor = company.primaryColor
    this.secondaryColor = company.secondaryColor
  }

  mounted(): void {
    this.load()
  }
}
