
import { Vue, Component } from 'vue-property-decorator'
import { validationRules } from '@/utils/rules'
import { isNotEmptyInput } from '@/utils/validators'
import { timeZones } from '@/utils/time'
import auth from '@/store/modules/auth'
import ChangePassword from '@/components/ChangePassword.vue'
import user from '@/services/user'
import {
  countryPhoneFormatFilter,
  formattedPhoneToNumber,
} from '@/utils/string'
import { UserDetail } from '@/models/dto'
import { EventBus } from '@/utils/eventBus'
import app from '@/store/modules/app'
import quotesClient from '@/services/quotes'

@Component({
  components: {
    ChangePassword,
  },
  metaInfo: {
    title: 'My Profile',
  },
})
export default class SettingsMyProfile extends Vue {
  rules = validationRules
  auth = auth
  showChangePasswordModal = false
  timeZones = [{ zoneName: null, label: 'Sync With Computer' }, ...timeZones]
  changed = false
  loading = false
  initialEmailSenderName = ''

  isNotEmptyInput = isNotEmptyInput

  profile = new UserDetail()

  get senderName(): string {
    return this.profile.emailSenderName
  }

  set senderName(value: string) {
    this.profile.emailSenderName = value
  }

  async submit(): Promise<void> {
    this.loading = true
    const phone = formattedPhoneToNumber(this.profile.phone)

    try {
      const { data: roles } = await user.roles(auth.getUser.userId)
      if (!roles.roles?.length) {
        throw Error('Error fetching user roles')
      }

      await user.update(auth.getUser.userId, {
        ...this.profile,
        phone,
        userRoleNames: roles.roles.map((r) => r.roleName),
      })
      auth.refreshUser()
      this.changed = false
      if (this.initialEmailSenderName !== this.profile.emailSenderName) {
        this.updateQuoteTemplate()
      }
    } catch (e) {
      const error = e as Error
      EventBus.$emit('snackbar:error', error?.message)
    } finally {
      this.loading = false
    }
  }

  async updateQuoteTemplate(): Promise<void> {
    try {
      const { data } = await quotesClient.getEmailTemplate()
      const quoteTemplate = data?.emailCustomizationDTO

      if (
        quoteTemplate.emailCustomizationId &&
        quoteTemplate.replyToEmail === this.profile.email
      ) {
        quoteTemplate.replyToEmailName = this.profile.emailSenderName
        await quotesClient.updateEmailTemplate(quoteTemplate)
      }
    } catch (e) {
      console.error(e)
    }
  }

  mounted(): void {
    const user = auth.getUser || new UserDetail()
    this.profile = {
      ...user,
      phone: countryPhoneFormatFilter(user.phone, user.phoneCountryKey),
    }
    this.initialEmailSenderName = this.profile.emailSenderName

    if (app.isDateFormatSettingEnabled) {
      this.timeZones.push(
        {
          zoneName: 'Australia/Sydney',
          label: 'Australian Eastern Standard Time',
          id: 6,
        },
        {
          zoneName: 'Australia/Adelaide',
          label: 'Australian Central Standard Time',
          id: 7,
        },
        {
          zoneName: 'Australia/Perth',
          label: 'Australian Western Standard Time',
          id: 8,
        }
      )
    }
  }
}
