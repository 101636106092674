var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.company)?_c('div',{staticClass:"padding-y-4 relative"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('h4',{staticClass:"h4-bold margin-b-2"},[_vm._v("Quote Converted")]),_c('CUButton',{style:({ margin: '-46px 0 46px 0' }),attrs:{"color":"primary","loading":_vm.loading,"disabled":!_vm.notificationsEdited,"width":130},on:{"click":_vm.updateCompanyNotificationsSetting}},[_vm._v(" Save ")])],1),_c('div',{staticClass:"d-flex align-center margin-b-2"},[_c('v-switch',{staticClass:"margin-x-0 margin-y-0 padding-y-0 h-32",attrs:{"input-value":_vm.sendCheckoutConvertEmail,"ripple":false},on:{"change":(value) =>
          _vm.handleUpdateCompanyNotification({
            sendCheckoutConvertEmail: value,
          })}}),_c('p',[_vm._v("Notify me about quotes booked through online checkout")]),_c('CUTooltip',{attrs:{"tooltip-text":"When a customer books through online checkout, you'll receive an email","max-width":"350px","bottom":""}},[_c('CUIcon',{staticClass:"cursor-pointer margin-l-2 margin-b-n2",attrs:{"hide-title":"","color":"bg-gray-4","width":"24px"}},[_vm._v(" info ")])],1)],1),_c('div',{staticClass:"d-flex align-center margin-b-5"},[_c('v-switch',{staticClass:"margin-x-0 margin-y-0 padding-y-0 h-32",attrs:{"input-value":_vm.sendManualConvertEmail,"ripple":false},on:{"change":(value) =>
          _vm.handleUpdateCompanyNotification({
            sendManualConvertEmail: value,
          })}}),_c('p',[_vm._v("Notify me about manually converted quotes")]),_c('CUTooltip',{attrs:{"tooltip-text":"When a quote is manually converted through Quote Actions, you'll receive an email","max-width":"350px","bottom":""}},[_c('CUIcon',{staticClass:"cursor-pointer margin-l-2 margin-b-n2",attrs:{"hide-title":"","color":"bg-gray-4","width":"24px"}},[_vm._v(" info ")])],1)],1),_c('h4',{staticClass:"h4-bold margin-b-2"},[_vm._v("Customer Emails")]),_c('div',{staticClass:"d-flex align-center margin-b-2"},[_c('v-switch',{staticClass:"margin-x-0 margin-y-0 padding-y-0 h-32",attrs:{"input-value":_vm.defaultSendDriverInfoEmail,"ripple":false},on:{"change":(value) =>
          _vm.handleUpdateCompanyNotificationsSetting({
            ..._vm.company.notificationsSetting,
            defaultSendDriverInfoEmail: value,
          })}}),_c('p',[_vm._v("Automatically send Driver Info email a day before pickup")]),_c('CUTooltip',{attrs:{"tooltip-text":"Sent at 4:00 PM EST the day prior to pickup for <b>fully assigned</b> reservations only.","max-width":"350px","bottom":""}},[_c('CUIcon',{staticClass:"cursor-pointer margin-l-2 margin-b-n2",attrs:{"hide-title":"","color":"bg-gray-4","width":"24px"}},[_vm._v(" info ")])],1)],1),_c('div',{staticClass:"d-flex align-center margin-b-5"},[_c('v-switch',{staticClass:"margin-x-0 margin-y-0 padding-y-0 h-32",attrs:{"input-value":_vm.defaultSendDriverOnTheWayEmail,"ripple":false},on:{"change":(value) =>
          _vm.handleUpdateCompanyNotificationsSetting({
            ..._vm.company.notificationsSetting,
            defaultSendDriverOnTheWayEmail: value,
          })}}),_c('p',[_vm._v("Automatically send Driver on the Way email when a trip is started")]),_c('CUTooltip',{attrs:{"tooltip-text":"Sent when starting trip tracking in mobile app for <b>fully assigned</b> reservations only.","max-width":"350px","bottom":""}},[_c('CUIcon',{staticClass:"cursor-pointer margin-l-2 margin-b-n2",attrs:{"hide-title":"","color":"bg-gray-4","width":"24px"}},[_vm._v(" info ")])],1)],1),_c('h4',{staticClass:"h4-bold margin-b-2"},[_vm._v("Driver Assignment")]),_c('div',{staticClass:"d-flex align-center margin-b-2"},[_c('v-switch',{staticClass:"margin-x-0 margin-y-0 padding-y-0 h-32",attrs:{"input-value":_vm.defaultSendDriverAssignedEmail,"hide-details":"","ripple":false},on:{"change":_vm.handleUpdateTripAssignmentEmailNotification}}),_c('p',{staticClass:"text-black margin-b-0"},[_vm._v("Notify driver via email")])],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-switch',{staticClass:"margin-x-0 margin-y-0 padding-y-0 h-32",attrs:{"input-value":_vm.defaultSendDriverAppNotification,"hide-details":"","ripple":false},on:{"change":_vm.handleUpdateTripAssignmentAppNotification}}),_c('p',{staticClass:"text-black margin-b-0 body-2-regular"},[_vm._v(" Notify driver via app notification ")])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }