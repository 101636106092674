
import { Component, Vue } from 'vue-property-decorator'
import CUSimpleTable from '@/components/CUSimpleTable.vue'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import { TableAction } from '@/models/TableAction'
import { DataTableColumn } from '@/models/DataTableColumn'
import client from '@/services/customCharges'
import {
  CompanyChargeOption,
  FlatCompanyChargeOption,
  toCompanyChargeOption,
} from '@/models/dto/LineItemCharge'
import { EventBus } from '@/utils/eventBus'
import sidebar from '@/store/modules/sidebar'
import CompanyChargeOptionForm from '@/components/CompanyChargeOptionForm.vue'
import { LineItemChargeTypes, LineItemSectionTypes } from '@/utils/enum'
import HoldUpModal from '@/components/HoldUpModal.vue'
import { currencyFilter } from '@/utils/string'
import messages from '@/data/messages'

@Component({
  components: { HoldUpModal, CUCollectionTable, CUSimpleTable },
})
export default class SettingsCustomChargesCustomCharges extends Vue {
  tableView = client.tableView

  isModalOpen = false
  selectedCustomCharges: FlatCompanyChargeOption[] = []

  get modalConfirmationMessage(): string {
    if (this.selectedCustomCharges?.length === 1) {
      return 'Are you sure you want to delete this charge?'
    }
    return 'Are you sure you want to delete these charges?'
  }

  getCustomChargeRate(row: FlatCompanyChargeOption): string {
    if (row.lineItemChargeTypeKey === LineItemChargeTypes.PERCENTAGE) {
      return `${row.percentage}%`
    }
    return currencyFilter(row.rate)
  }

  snackbarSuccess(): void {
    EventBus.$emit('snackbar:success', messages.success.customChargeCreated)
  }

  refreshCustomCharges(): void {
    EventBus.$emit('refresh-tableview')
  }

  handleAddCustomCharge(): void {
    sidebar.push({
      title: 'Add Charge',
      component: CompanyChargeOptionForm,
      props: {
        label: 'Charge',
        sections: [
          LineItemSectionTypes.BASE_FARE,
          LineItemSectionTypes.ITEMIZED_CHARGE,
        ],
      },
      on: {
        submit: this.handleCreateCustomCharge,
        cancel: this.handleSubmissionCancel,
      },
    })
  }

  handleEditCustomCharge(companyChargeOption: CompanyChargeOption): void {
    sidebar.popAllAndPush({
      title: 'Edit Charge',
      component: CompanyChargeOptionForm,
      props: {
        label: 'Charge',
        sections: [
          LineItemSectionTypes.BASE_FARE,
          LineItemSectionTypes.ITEMIZED_CHARGE,
        ],
        companyChargeOption,
      },
      on: {
        submit: this.handleUpdateCustomCharge,
        cancel: this.handleSubmissionCancel,
      },
    })
  }

  handleCreateCustomCharge(companyChargeOption: CompanyChargeOption): void {
    client
      .create(companyChargeOption)
      .then(this.snackbarSuccess)
      .then(this.refreshCustomCharges)
      .finally(sidebar.close)
  }

  handleUpdateCustomCharge(companyChargeOption: CompanyChargeOption): void {
    client
      .update(companyChargeOption)
      .then(this.refreshCustomCharges)
      .finally(sidebar.close)
  }

  handleCustomChargeSelection(
    customCharges: FlatCompanyChargeOption[] = []
  ): void {
    this.selectedCustomCharges = customCharges
    this.isModalOpen = true
  }

  handleDeleteCustomCharges(): void {
    if (this.selectedCustomCharges?.length) {
      const ids = this.selectedCustomCharges
        .map(toCompanyChargeOption)
        .map(({ id }) => id)
      client.removeAll(ids).then(this.refreshCustomCharges)
    }
  }

  async handleCustomChargeMakeDefault(
    customCharges: FlatCompanyChargeOption[] = []
  ): Promise<void> {
    this.selectedCustomCharges = customCharges
    if (this.selectedCustomCharges?.length) {
      const charges = this.selectedCustomCharges
        .map(toCompanyChargeOption)
        .map((charge) => {
          return { ...charge, isDefault: true }
        })

      await client.batchUpdate(charges)

      this.refreshCustomCharges()
    }
  }

  async handleCustomChargeRemoveDefault(
    customCharges: FlatCompanyChargeOption[] = []
  ): Promise<void> {
    this.selectedCustomCharges = customCharges
    if (this.selectedCustomCharges?.length) {
      const charges = this.selectedCustomCharges
        .map(toCompanyChargeOption)
        .map((charge) => {
          return { ...charge, isDefault: false }
        })

      await client.batchUpdate(charges)

      this.refreshCustomCharges()
    }
  }

  handleSubmissionCancel(): void {
    sidebar.close()
  }

  actions: TableAction[] = [
    {
      displayText: 'Delete',
      key: 'delete',
      icon: 'delete',
      action: this.handleCustomChargeSelection,
    },
    {
      displayText: 'Make Default',
      key: 'make-default',
      icon: 'confirm',
      action: this.handleCustomChargeMakeDefault,
    },
    {
      displayText: 'Remove Default',
      key: 'remove-default',
      icon: 'close',
      iconLabel: 'Remove',
      action: this.handleCustomChargeRemoveDefault,
    },
  ]

  columns: DataTableColumn[] = [
    {
      _t_id: 'ca04f382-fdff-4481-90e2-967de2f6fb65',
      type: 'link',
      text: 'Name',
      value: 'name',
      filterBySearch: true,
      filterProp: 'name',
      sortable: true,
      sortProp: 'name',
      clickEvent: 'custom-charges:edit-charge',
    },
    {
      _t_id: '04b1f24a-a865-4944-9155-f94025c858d6',
      type: 'text',
      text: 'Type',
      sortable: true,
      sortProp: 'lineItemChargeTypeLabel',
      value: 'lineItemChargeTypeLabel',
    },
    {
      _t_id: 'eac9468b-225f-42d7-b3b1-1739af462e66',
      text: 'Rate',
      value: 'rate',
      computedText: this.getCustomChargeRate,
    },
    {
      _t_id: 'b481345b-9d5c-4812-af1c-bfe609417330',
      type: 'text',
      text: 'Placement',
      sortable: true,
      sortProp: 'lineItemSectionTypeLabel',
      value: 'lineItemSectionTypeLabel',
    },
    {
      _t_id: '7d3ed6db-86c8-4c22-aa42-62ade240b009',
      text: 'Default on Quote',
      value: 'isDefault',
      width: '1px',
      filterable: true,
      filterProp: 'isDefault',
      filterType: 'eq',
      filterInterface: 'select',
      filterDropdownItems: [
        { text: 'Default', value: 'true' },
        { text: 'Not Default', value: 'false' },
      ],
      sortable: true,
      sortProp: 'isDefault',
      computedText: (row: FlatCompanyChargeOption): string =>
        row.isDefault ? 'Default' : 'Not Default',
    },
  ]

  mounted(): void {
    EventBus.$on(
      'custom-charges:edit-charge',
      (customCharge: FlatCompanyChargeOption) =>
        this.handleEditCustomCharge(toCompanyChargeOption(customCharge))
    )
  }

  beforeDestroy(): void {
    EventBus.$off(
      'custom-charges:edit-charge',
      (customCharge: FlatCompanyChargeOption) =>
        this.handleEditCustomCharge(toCompanyChargeOption(customCharge))
    )
  }
}
