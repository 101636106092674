
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class EventTypesSidebarItem extends Vue {
  @Prop({ required: true, type: String }) readonly label!: string
  @Prop({ required: true, type: Number }) readonly index!: number

  @Prop({ required: false, type: Boolean, default: false })
  readonly active: boolean

  remove(): void {
    this.$emit('event-types:remove', this.index)
  }
}
