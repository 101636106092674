
import { Vue, Component, Prop } from 'vue-property-decorator'
import sidebar from '@/store/modules/sidebar'
import HoldUpModal from '@/components/HoldUpModal.vue'
@Component({
  components: { HoldUpModal },
})
export default class SettingsIntegrationsSidebarHeader extends Vue {
  state = sidebar
  @Prop({ required: false }) readonly title!: string

}
