
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Days, MarkupTypes } from '@/utils/enum'
import { MarketRateType } from '@/models/dto/Rate'
import sidebar from '@/store/modules/sidebar'
import {
  Markup,
  MarkupDay,
  MarkupType,
  TableViewMarkup,
} from '@/models/dto/Markup'
import { VehicleType } from '@/models/dto'
import { validateRules, validationRules } from '@/utils/rules'
import AutocompleteCustomer from '@/components/AutocompleteCustomer.vue'
import AutocompleteCustomerAccount from '@/components/AutocompleteCustomerAccount.vue'
import tclient from '@/services/type'
import industryClient from '@/services/industries'
import { AxiosResponse } from 'axios'
import dayjs from 'dayjs'

@Component({
  components: { AutocompleteCustomerAccount, AutocompleteCustomer },
})
export default class MarkupForm extends Vue {
  @Prop({ required: false }) readonly markup: TableViewMarkup

  validation = validationRules

  isSubmitting = false
  form: Markup = new Markup({ startDate: '', endDate: '', markupType: null })

  types = MarkupTypes
  markupTypes: MarkupType[] = []
  vehicleTypes: VehicleType[] = []
  marketRateTypes: MarketRateType[] = []
  days: Partial<MarkupDay>[] = Object.keys(Days).map((_, day) => ({ day }))
  industries = []

  @Watch('markup', { immediate: true })
  markupChange(): void {
    if (this.markup?.markupDays?.length === 0) {
      this.markup.markupDays = null
    }
  }

  isMarkupType(type: MarkupTypes): boolean {
    return this.form?.markupType?.key === type
  }

  dayLabel(markupDay: MarkupDay): string {
    return Object.values(Days)[markupDay.day].substring(0, 3) || ''
  }

  handleMarkupTypeChange(markupType: MarkupType): void {
    const markupTypeId = markupType?.markupTypeId
    this.form = { ...this.form, markupType, markupTypeId }
  }

  async submit(): Promise<void> {
    const formIsValid = await validateRules(this)
    if (!formIsValid) {
      return
    }

    // If the start date is after the end date, swap the dates
    if (this.form.startDate && this.form.endDate) {
      if (dayjs(this.form.startDate).isAfter(this.form.endDate)) {
        const startDate = this.form.startDate
        this.form.startDate = this.form.endDate
        this.form.endDate = startDate
      }
    }

    if (!this.isMarkupType(this.types.INDUSTRY)) {
      this.form.industryId = null
    }
    const markup: Markup = {
      ...this.form,
      active: !!this.form.active,
    }
    this.$emit('submit', markup)
  }

  cancel(): void {
    sidebar.pop()
  }

  filterMarkupTypes(markupTypes: MarkupType[]): MarkupType[] {
    const exclusions: string[] = [MarkupTypes.EVENT]
    return (markupTypes || []).filter(
      (markupType) => !exclusions.includes(markupType.key)
    )
  }

  filterMarketRateTypes(marketRateTypes: MarketRateType[]): MarketRateType[] {
    const exclusions: string[] = ['transfer_rate']
    return (marketRateTypes || []).filter(
      (marketRateType) => !exclusions.includes(marketRateType.key)
    )
  }

  async loadIndustries(): Promise<void> {
    const industryResponse: AxiosResponse = await industryClient.tableView({
      pageSize: -1,
      page: 1,
    })
    this.industries = [
      ...industryResponse.data.resultList.map((el) => {
        return { text: el.label, value: el.industryId }
      }),
    ]
  }

  @Watch('markup')
  onMarkupChange(): void {
    if (this.markup) {
      Object.assign(this.form, this.markup)
      if (this.isMarkupType(this.types.VEHICLE_TYPE)) {
        this.form.vehicleTypeKey = this.markup.vehicleType?.key
        this.form.marketRateTypeKey = this.markup.rateType?.key
      }
    }
  }

  mounted(): void {
    if (this.markup) {
      Object.assign(this.form, this.markup)
      if (this.isMarkupType(this.types.VEHICLE_TYPE)) {
        this.form.vehicleTypeKey = this.markup.vehicleType?.key
        this.form.marketRateTypeKey = this.markup.rateType?.key
      }
    }

    tclient
      .markupTypes()
      .then((res) => res.data.resultList)
      .then(this.filterMarkupTypes)
      .then((markupTypes) => (this.markupTypes = markupTypes))

    tclient
      .vehicleTypes()
      .then((res) => res.data)
      .then((vehicleTypes) => (this.vehicleTypes = vehicleTypes))

    tclient
      .marketRateTypes()
      .then((res) => res.data)
      .then(this.filterMarketRateTypes)
      .then((marketRateTypes) => (this.marketRateTypes = marketRateTypes))

    this.loadIndustries()
  }
}
