
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class SettingsIntegrationsBlock extends Vue {
  @Prop({ required: true }) logo!: string
  @Prop({ required: false }) logoWidth: string | number
  @Prop({ required: false }) integrationQty: number
  @Prop({ required: true }) title!: string
  @Prop({ required: true }) integrationKey!: string
  @Prop({ required: true }) tagline!: string
  @Prop({ required: true }) description!: string
  @Prop({ required: false, type: Boolean }) isDefault!: boolean
  @Prop({ required: false, default: false, type: Boolean }) isSyncing!: boolean
  @Prop({ required: false, default: false })
  isInstalled!: boolean | Promise<boolean>

  loading = false
  installed = false

  @Watch('isInstalled', { immediate: true, deep: true })
  async onIsInstalledChanged(): Promise<void> {
    this.loading = true
    this.installed = await this.isInstalled
    this.loading = false
  }

  get isShowNewLabel(): boolean {
    return (
      !this.isDefault &&
      this.integrationKey === 'busify_pay' &&
      this.integrationQty > 1
    )
  }

  getImagePath(): string {
    return require(`@/assets/images/${this.logo}`)
  }
}
