
import { Prop } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { SimpleTableColumn } from '@/models/SimpleTableColumn'
import { Terms } from '@/models/dto/Terms'
import CUSimpleTable from '@/components/CUSimpleTable.vue'

@Component({
  components: { CUSimpleTable },
})
export default class SettingsGeneralTermsList extends mixins(DateMixin) {
  @Prop({ required: false, default: () => [] }) readonly value!: Terms[]
  @Prop({ required: false, default: () => [] }) readonly items!: Terms[]

  headers: SimpleTableColumn<Terms>[] = [
    {
      text: 'Name',
      value: 'label',
      type: 'text',
      cellClass: 'text-green cursor-pointer',
      onClick: (row: Terms): unknown => this.$emit('edit', row),
    },
    {
      text: 'Created Date',
      value: 'createdOn',
      type: 'text',
      formatter: (row: Terms): string => this.formatMediumDate(row.createdOn),
    },
    {
      text: 'Updated Date',
      value: 'updatedOn',
      type: 'text',
      formatter: (row: Terms): string => this.formatMediumDate(row.updatedOn),
    },
    {
      text: 'Default',
      value: 'isDefault',
      type: 'text',
      width: '95px',
      formatter: (row: Terms): string => (row.isDefault ? 'Yes' : 'No'),
    },
  ]
}
