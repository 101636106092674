
import { RateType } from '@/models/DriverPay'
import {
  DriverPaySettings,
  HourlyConfig,
  PercentConfig,
  PerDiemAdditionalPaymentOption,
  PerTripAdditionalPaymentOption,
} from '@/models/dto/DriverPaySettings'
import driverPay from '@/services/driverpay'
import auth from '@/store/modules/auth'
import sidebar from '@/store/modules/sidebar'
import { EventBus } from '@/utils/eventBus'
import { capitalizeFirstLetter } from '@/utils/string'
import { Component, Vue } from 'vue-property-decorator'
import CUButton from './CUButton.vue'
import CUMultiSwitch from './CUMultiSwitch.vue'
import CUTextField from './CUTextField.vue'
import EditRateTypes from './EditRateTypes.vue'

@Component({ components: { CUButton, CUTextField, CUMultiSwitch } })
export default class SettingsDriverPayOptions extends Vue {
  capitalizeFirstLetter = capitalizeFirstLetter

  settings: DriverPaySettings = new DriverPaySettings()
  loading = true
  original: string
  rateTypes: RateType[] = []

  get activeSortedRateTypes(): RateType[] {
    return this.rateTypes
      .filter((rateType) => rateType.isActive !== false)
      .sort((a, b) => a.orderIndex - b.orderIndex)
  }

  get isModified(): boolean {
    return JSON.stringify(this.settings) !== this.original
  }

  get hourly(): HourlyConfig {
    return this.settings?.hourlyConfig || new HourlyConfig()
  }

  get perTripAdditional(): PerTripAdditionalPaymentOption {
    return (
      this.settings?.perTripAdditionalPaymentOption ||
      new PerTripAdditionalPaymentOption()
    )
  }

  get perDiemAdditional(): PerDiemAdditionalPaymentOption {
    return (
      this.settings?.perDiemAdditionalPaymentOption ||
      new PerDiemAdditionalPaymentOption()
    )
  }

  get percentConfig(): PercentConfig {
    return this.settings?.percentConfig || new PercentConfig()
  }

  get formattedDefaultMethod(): string {
    return capitalizeFirstLetter(this.settings?.defaultMethod)
  }

  get formattedLongTripAltRate(): string {
    if (this.hourly.longTripAltRate === 'HOURLY') {
      return 'Hours per trip'
    } else if (this.hourly.longTripAltRate === 'DAILY') {
      return 'Daily rate'
    }
    return null
  }

  updateLongTripAltRate(rate: string): void {
    if (rate === 'Hours per trip') {
      this.hourly.longTripAltRate = 'HOURLY'
    } else {
      this.hourly.longTripAltRate = 'DAILY'
    }
  }

  get formattedOvernightTripAltRate(): string {
    if (this.hourly.overnightTripAltRate === 'HOURLY') {
      return 'Hours per day'
    } else if (this.hourly.overnightTripAltRate === 'DAILY') {
      return 'Daily rate'
    }
    return null
  }

  get formattedPercentConfig(): string {
    if (this.percentConfig?.type === 'BASE_FARE') {
      return 'Base Fare'
    } else if (this.percentConfig?.type === 'TOTAL_CHARGES') {
      return 'Total Charges'
    }
    return null
  }

  get formattedPerTripAdditional(): string {
    if (this.perTripAdditional.rate === 'HOURLY') {
      return 'Hours per trip'
    } else if (this.perTripAdditional.rate === 'PER_TRIP') {
      return 'Per trip rate'
    }
    return null
  }

  get perDiemThresholdUnitLabels(): { label: string; value: string }[] {
    return [
      { label: 'day(s)', value: 'DAYS' },
      { label: 'hour(s)', value: 'HOURS' },
    ]
  }

  updateDefaultMethod(rate: string): void {
    this.settings.defaultMethod = rate.toUpperCase()
  }

  updateOvernightTripAltRate(rate: string): void {
    if (rate === 'Hours per day') {
      this.hourly.overnightTripAltRate = 'HOURLY'
    } else {
      this.hourly.overnightTripAltRate = 'DAILY'
    }
  }

  updatePercentConfig(rate: string): void {
    if (rate === 'Base Fare') {
      this.percentConfig.type = 'BASE_FARE'
    } else {
      this.percentConfig.type = 'TOTAL_CHARGES'
    }
  }

  updatePerTripAdditional(rate: string): void {
    if (rate === 'Hours per trip') {
      this.settings.perTripAdditionalPaymentOption.rate = 'HOURLY'
    } else {
      this.settings.perTripAdditionalPaymentOption.rate = 'PER_TRIP'
    }
  }

  async save(): Promise<void> {
    this.loading = true
    try {
      await driverPay.updateCompanyDefaults(this.settings)
      EventBus.$emit('snackbar:success', 'Pay Options saved successfully.')
    } catch (err) {
      EventBus.$emit('snackbar:error', 'Failed to save pay options.')
    } finally {
      this.loading = false
      this.original = JSON.stringify(this.settings)
      this.settings = JSON.parse(this.original)
    }
  }

  openEditRateTypes(): void {
    sidebar.push({
      component: EditRateTypes,
      persist: true,
      props: {
        initialRateTypes: this.rateTypes,
      },
      on: {
        'immediate-rate-update': this.handleImmediateRateUpdate,
        'apply-rate-types': this.handleApplyRateTypes,
      },
    })
  }

  handleImmediateRateUpdate(updatedRates: Partial<RateType>[]): void {
    // Update existing rates
    this.rateTypes = this.rateTypes.map((rate) => {
      const updatedRate = updatedRates.find((r) => r.id === rate.id)
      if (updatedRate) {
        return { ...rate, ...updatedRate }
      } else {
        return rate
      }
    })

    // Identify new rates (those not present in existing rateTypes)
    const newRates = updatedRates.filter(
      (updatedRate) =>
        !this.rateTypes.some((rate) => rate.id === updatedRate.id)
    )

    if (newRates.length > 0) {
      // Ensure all required properties are present for new rates
      const fullNewRates = newRates.map((newRate, index) => ({
        id: newRate.id, // Now we assume id is present
        label: newRate.label || 'New Rate',
        baseRateKey: newRate.baseRateKey,
        isDefault: newRate.isDefault ?? false,
        isHidden: newRate.isHidden ?? false,
        orderIndex: this.rateTypes.length + index,
        isActive: newRate.isActive ?? true,
      }))

      this.rateTypes = [...this.rateTypes, ...fullNewRates]
    }
  }

  async handleApplyRateTypes(finalRateTypes: RateType[]): Promise<void> {
    const originalRateTypes = [...this.rateTypes]
    this.rateTypes = finalRateTypes

    try {
      await driverPay.saveCompanyRateTypes(finalRateTypes)
      EventBus.$emit('snackbar:success', 'Rate types saved successfully.')
    } catch (err) {
      EventBus.$emit('snackbar:error', 'Failed to save rate types.')
      this.rateTypes = originalRateTypes // Revert to original state
    }
  }

  async fetchRateTypes(): Promise<RateType[]> {
    const response = await driverPay.getCompanyRateTypes()
    return response.data
  }

  async mounted(): Promise<void> {
    const res = await driverPay.getCompanyDefaults(auth.getCompanyId)
    this.rateTypes = await this.fetchRateTypes()
    this.settings = res.data
    if (this.settings.percentConfig === null) {
      this.settings.percentConfig = new PercentConfig()
    }

    this.original = JSON.stringify(this.settings)
    this.loading = false
  }
}
