
import { Vue, Component, Prop } from 'vue-property-decorator'
import QuickbooksCompanySettingsSidebar from './QuickbooksCompanySettingsSidebar.vue'
import SettingsIntegrationsSidebarHeader from './SettingsIntegrationsSidebarHeader.vue'
import { QuickbooksAuthCallbackInfo } from '@/models/dto/Quickbooks'
import quickbooksService from '@/services/quickbooks'
import auth from '@/store/modules/auth'
import QuickbooksIntegrationSplashSidebar from './QuickbooksIntegrationSplashSidebar.vue'
import { EventBus } from '@/utils/eventBus'
import sidebar from '@/store/modules/sidebar'
import QuickBooksActivityLogSidebar from './QuickBooksActivityLogSidebar.vue'

@Component({
  components: {
    SettingsIntegrationsSidebarHeader,
    QuickbooksCompanySettingsSidebar,
    QuickbooksIntegrationSplashSidebar,
  },
})
export default class SettingsIntegrationsQuickbooks extends Vue {
  @Prop({ required: false, default: null })
  readonly quickbooksAuthCallbackInfo!: QuickbooksAuthCallbackInfo

  auth = auth
  loading = true
  taxCodes = []

  get isLoggedIntoQuickbooks(): boolean {
    return auth.getCompanyQuickBooksSettings?.enabled || false
  }

  handleViewActivityLog(): void {
    sidebar.push({
      component: QuickBooksActivityLogSidebar,
      width: 1170,
      wide: true,
    })
  }

  async loadCompanyTaxCodes(): Promise<void> {
    try {
      const res = await quickbooksService.getCompanyTaxCodes(auth.getCompanyId)
      this.taxCodes = res.data.taxCodes
    } catch (e) {
      console.error(e)
    }
  }

  async authenticateQuickbooks(): Promise<void> {
    if (!this.quickbooksAuthCallbackInfo || this.isLoggedIntoQuickbooks) {
      return;
    }
    try {
      const response = await quickbooksService.quickbooksAuthCallback(this.quickbooksAuthCallbackInfo);
      const companySettings = response.data.companySettings;
      auth.setCompanyQuickBooksSettings(companySettings);
    } catch (e) {
      console.error(e);
      EventBus.$emit('snackbar:error', 'There was an error authenticating your Quickbooks Account');
    }
  }


  async mounted(): Promise<void> {
    try {
      await Promise.all([
        this.loadCompanyTaxCodes(),
        this.authenticateQuickbooks()
      ]);
    } catch (e) {
      console.error('Error loading Quickbooks settings:', e);
    } finally {
      this.loading = false
    }
  }

}
