
import { Vue, Component, Prop } from 'vue-property-decorator'
import BusifyLogo from '@/assets/busify-logo.png'
@Component({})
export default class SettingsGeneralFaviconPreview extends Vue {
  @Prop({ required: true, type: String }) readonly companyName!: string
  @Prop({ required: false, type: String }) readonly faviconUrl!: string

  get tabLabel(): string {
    const label = `${this.companyName} - Checkout`
    return label
  }
}
