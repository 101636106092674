import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import {
  CompanyChargeOption,
  FlatCompanyChargeOption,
} from '@/models/dto/LineItemCharge'
import { ResponseEntity } from '@/services/common/ResponseEntity'

const httpService: HttpService = new HttpService()

export default {
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<FlatCompanyChargeOption>>> {
    const { sorts, filters, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const url = `https://${apiBaseUrl()}/tables/customCharges?${query}`
    return httpService.get(url)
  },
  create(
    customCharge: CompanyChargeOption
  ): Promise<AxiosResponse<ResponseEntity<CompanyChargeOption>>> {
    const url = `https://${apiBaseUrl()}/companyChargeOption`
    return httpService.post(url, customCharge)
  },
  update(
    customCharge: Partial<CompanyChargeOption>
  ): Promise<AxiosResponse<ResponseEntity<CompanyChargeOption>>> {
    const url = `https://${apiBaseUrl()}/companyChargeOption`
    return httpService.patch(url, customCharge)
  },
  batchUpdate(
    customCharges: Partial<CompanyChargeOption>[]
  ): Promise<AxiosResponse<ResponseEntity<CompanyChargeOption>>> {
    const url = `https://${apiBaseUrl()}/companyChargeOption/batch`
    return httpService.patch(url, customCharges)
  },
  removeAll(
    ids: number[]
  ): Promise<AxiosResponse<ResponseEntity<CompanyChargeOption>>> {
    const url = `https://${apiBaseUrl()}/companyChargeOption`
    return httpService.delete(url, { data: ids })
  },
  remove(
    id: number
  ): Promise<AxiosResponse<ResponseEntity<CompanyChargeOption>>> {
    const url = `https://${apiBaseUrl()}/companyChargeOption/${id}`
    return httpService.delete(url)
  },
}
