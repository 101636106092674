import { Type } from '@/models/dto/Type'
import { PricingMethodKeys } from '@/utils/enum'

export interface CharterUpApiCredential {
  clientId: string
  clientSecret: string
}

// eslint-disable-next-line no-redeclare
export class CharterUpApiCredential implements CharterUpApiCredential {
  constructor(charterUpApiCredential?: Partial<CharterUpApiCredential>) {
    Object.assign(this, charterUpApiCredential)
  }
}

export interface CharterUpApiIntegration {
  companyId: number
  clientId: string
}

export interface CharterUpRate {
  active: boolean
  companyId: number
  highRate: number
  hourlyMinimum: number
  lowRate: number
  marketId: number
  marketRateId: number
  marketRateTypeKey: string
  marketRateTypeDTO: Type
  marketplace: boolean
  vehicleTypeId: number
  vehicleTypeKey: string
  vehicleTypeDTO: Type
  vehicleId: number
}

// eslint-disable-next-line no-redeclare
export class CharterUpRate implements CharterUpRate {
  constructor(charterUpRate?: Partial<CharterUpRate>) {
    Object.assign(this, charterUpRate)
  }
}

export interface CharterUpMarketRateGroup {
  id: number
  vehicleTypeKey: string
  vehicleTypeId: number
  vehicleId?: number
  vehicleName?: string
  liveMileRate: CharterUpRate
  deadMileRate: CharterUpRate
  hourlyRate: CharterUpRate
  dailyRate: CharterUpRate
  transferRate: CharterUpRate
  hourlyMinimum?: number
}

// eslint-disable-next-line no-redeclare
export class CharterUpMarketRateGroup implements CharterUpMarketRateGroup {
  constructor(
    marketRateGroup?: Partial<CharterUpMarketRateGroup>,
    marketRate?: Partial<CharterUpRate>
  ) {
    this.dailyRate = new CharterUpRate({
      marketRateTypeKey: PricingMethodKeys.DAILY_RATE,
      ...marketRate,
    })
    this.liveMileRate = new CharterUpRate({
      marketRateTypeKey: PricingMethodKeys.MILEAGE_RATE,
      ...marketRate,
    })
    this.deadMileRate = new CharterUpRate({
      marketRateTypeKey: PricingMethodKeys.DEAD_MILE_RATE,
      ...marketRate,
    })
    this.hourlyRate = new CharterUpRate({
      marketRateTypeKey: PricingMethodKeys.HOURLY_RATE,
      ...marketRate,
    })
    this.transferRate = new CharterUpRate({
      marketRateTypeKey: PricingMethodKeys.TRANSFER_RATE,
      ...marketRate,
    })
    this.hourlyMinimum = marketRate?.hourlyMinimum
    Object.assign(this, marketRateGroup)
  }
}

export interface CharterUpVehicleAmenities {
  amenityId: number
  amenityName: string
  isSupported: boolean
}

export interface CharterUpVehicle {
  active: boolean
  vehicleId: number
  vehicleMake: string
  vehicleModel: string
  vehicleName: string
  vehicleTypeId: number
  vehicleTypeName: string
  vehicleYear: number
  vinNumber: string
  passengerCapacity: number
  vehicleAmenities: CharterUpVehicleAmenities[]
  companyId?: number
  garageId?: number
  garageName?: string
  licensePlate?: string
  vehicleAmenityDTOs?: unknown[]
  vehiclePhotoDTOs?: unknown[]
}

export interface CharterUpMarketRateRequest {
  marketRateId: number
  companyId: number
  marketId: number
  highRate: number
  lowRate: number
  hourlyMinimum: number
  marketRateTypeKey: string
  marketRateTypeDTO: Type
  vehicleTypeKey: string
  vehicleTypeDTO: Type
  vehicleId: number
  marketplace: number
}

export interface CharterUpSyncRequest {
  vehicles: boolean
  drivers: boolean
  garages: boolean
}

export interface CharterUpSyncResult {
  vehicles: {
    created: number
    deleted: number
    failed: number
    success: boolean
  }
  drivers: {
    created: number
    deleted: number
    failed: number
    success: boolean
  }
  garages: {
    created: number
    deleted: number
    failed: number
    success: boolean
  }
}
