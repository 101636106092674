
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Address } from '@/models/dto'
import { CompanyStop } from '@/models/CompanyStop'
import { rules, validateRules } from '@/utils/rules'
import AutocompleteAddress from '@/components/AutocompleteAddress.vue'
import auth from '@/store/modules/auth'
import client from '@/services/companyStops'
import CUSimpleTable from '@/components/CUSimpleTable.vue'
import { EventBus } from '@/utils/eventBus'

@Component({
  components: { CUSimpleTable, AutocompleteAddress },
})
export default class CompanyStopsForm extends Vue {
  @Prop({ required: false, default: undefined }) companyStop: CompanyStop
  auth = auth
  rules = rules

  isModeEdit = false
  submitting = false
  loading = false

  name = ''
  notes = ''
  address: Address = null

  async submit(): Promise<void> {
    if (!(await validateRules(this)) || !this.address?.addressName) {
      return
    }

    this.submitting = true
    const req: CompanyStop = {
      ...this.companyStop,
      name: this.name,
      companyId: this.auth.user.companyId,
      addressId: this.address?.addressId,
      address: this.address,
      notes: this.notes,
      isActive: true,
    }
    try {
      const request = this.isModeEdit
        ? client.update(req, this.companyStop.id)
        : client.create(req)
      await request
      EventBus.$emit('company-stops:refresh')
    } catch (err) {
      EventBus.$emit('company-stops:error', err)
    } finally {
      this.submitting = false
    }
  }

  clear(): void {
    this.name = ''
    this.notes = ''
    this.submitting = false
    const form: any = this.$refs.form
    if (form) {
      form.reset()
    }
  }

  @Watch('companyStop', { immediate: true, deep: true })
  onStopUpdate(): void {
    if (this.companyStop) {
      this.isModeEdit = true
      this.name = this.companyStop.name
      this.notes = this.companyStop.notes
      this.address = this.companyStop.address
    } else {
      this.isModeEdit = false
      this.clear()
    }
  }
}
