import { Company } from '@/models/dto/Company'

export interface Terms {
  body: string
  company: Company
  companyTermsId: number
  createdOn: string
  isActive: boolean
  isDefault: boolean
  label: string
  modifiedById: number
  updatedOn: string
  hash: string
}

export interface TermsPageResponse {
  company: Company
  terms: Terms
}

// eslint-disable-next-line no-redeclare
export class Terms implements Terms {
  constructor(terms?: Partial<Terms>) {
    this.isActive = true
    this.isDefault = false
    Object.assign(this, terms)
  }
}
