
import { Component, Prop, Vue } from 'vue-property-decorator'
import SettingsGeneralTermsForm from '@/components/SettingsGeneralTermsForm.vue'
import SettingsGeneralTermsList from '@/components/SettingsGeneralTermsList.vue'
import { Terms } from '@/models/dto/Terms'
import client from '@/services/terms'
import { EventBus } from '@/utils/eventBus'
import messages from '@/data/messages'

@Component({
  components: { SettingsGeneralTermsList, SettingsGeneralTermsForm },
})
export default class SettingsGeneralTerms extends Vue {
  @Prop({ required: true, default: null }) readonly companyId: number
  @Prop({ required: false, default: '' }) readonly mode: string
  @Prop({ required: false, default: null }) readonly termsId: number

  terms: Terms[] = []
  selectedTerms: Terms[] = []
  selected: Terms = new Terms()
  showEditor = false
  loading = true

  modeHandler(): void {
    switch (this.mode) {
      case 'add':
        this.handleAddTerms()
        return
      case 'edit':
        this.openByTermsId()
        return
    }
    this.loading = false
  }

  openByTermsId(): void {
    let termsId = Number(this.termsId)
    if (!termsId) {
      termsId = Number(this.$route.params?.termsId)
    }
    const terms = this.terms.find((terms) => {
      return terms.companyTermsId === termsId
    })
    this.handleEditTerms(terms)
  }

  handleAddTerms(): void {
    this.selected = null
    this.showEditor = true
  }

  handleEditTerms(terms: Terms): void {
    this.selected = { ...terms }
    this.showEditor = true
  }

  handleDeleteTerms({ companyTermsId, isDefault }: Terms): void {
    this.selected = null
    this.showEditor = false
    if (isDefault) {
      EventBus.$emit('snackbar:error', messages.errors.defaultTermsRemoval)
    } else {
      client.delete(companyTermsId).then(this.load)
    }
  }

  cancel(): void {
    this.selected = null
    this.showEditor = false
  }

  submit(terms: Terms): void {
    this.selected = null
    this.showEditor = false
    try {
      if (terms.companyTermsId) {
        client.partialUpdate(terms).then(this.load)
      } else {
        const payload = { ...terms, companyId: this.companyId }
        client.create(payload).then(this.load)
      }
      EventBus.$emit('snackbar:success', 'Terms saved successfully.')
    } catch (err) {
      EventBus.$emit('snackbar:error', 'Error updating terms')
    }
  }

  async load(): Promise<void> {
    await client
      .tableView({ page: -1, pageSize: -1 })
      .then((res) => res.data)
      .then(({ resultList }) => (this.terms = resultList))
  }

  async mounted(): Promise<void> {
    await this.load()
    this.modeHandler()
    this.loading = false
  }
}
