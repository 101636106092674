
import { Component, Prop, Vue } from 'vue-property-decorator'
import { MarketRateGroup } from '@/models/dto/MarketRate'
import { Vehicle, VehicleType } from '@/models/dto'
import { validateRules, validationRules } from '@/utils/rules'
import { MarketRateType } from '@/models/dto/Rate'
import client from '@/services/type'
import cclient from '@/services/company'
import vclient from '@/services/vehicle'
import auth from '@/store/modules/auth'

@Component({})
export default class VehicleRateForm extends Vue {
  @Prop({ required: false }) readonly marketRateGroup: MarketRateGroup
  form = new MarketRateGroup()
  expanded = false

  validation = validationRules
  auth = auth

  vehicles: Vehicle[] = []
  vehicleTypes: VehicleType[] = []
  marketRateTypes: MarketRateType[] = []

  get dailyRate(): number {
    return this.form?.dailyRate?.highRate
  }

  get liveMileRate(): number {
    return this.form?.liveMileRate?.highRate
  }

  get deadMileRate(): number {
    return this.form?.deadMileRate?.highRate
  }

  get hourlyRate(): number {
    return this.form?.hourlyRate?.highRate
  }

  get hourlyMinimum(): number {
    return this.form?.hourlyRate?.hourlyMinimum
  }

  handleVehicleChange(vehicleId: number): void {
    const vehicle = this.vehicles.find((v) => v.vehicleId === vehicleId)
    const vehicleType = this.vehicleTypes.find(
      (v) => v.id === vehicle?.vehicleTypeId
    )
    const marketRateVehicle = {
      vehicleId: vehicle?.vehicleId,
      vehicleName: vehicle?.vehicleName,
      vehicleType: vehicleType?.label,
      vehicleTypeId: vehicleType?.id,
    }
    if (vehicle && vehicleType) {
      this.form = {
        ...this.form,
        ...marketRateVehicle,
        dailyRate: { ...this.form.dailyRate, ...marketRateVehicle },
        liveMileRate: { ...this.form.liveMileRate, ...marketRateVehicle },
        deadMileRate: { ...this.form.deadMileRate, ...marketRateVehicle },
        hourlyRate: { ...this.form.hourlyRate, ...marketRateVehicle },
      }
    }
  }

  handleVehicleTypeChange(vehicleTypeId: number): void {
    const vehicleType = this.vehicleTypes.find((v) => v.id === vehicleTypeId)
    const marketRateVehicle = {
      vehicleId: null,
      vehicleName: null,
      vehicleType: vehicleType?.label,
      vehicleTypeId: vehicleType?.id,
    }
    if (vehicleType) {
      this.form = {
        ...this.form,
        ...marketRateVehicle,
        dailyRate: { ...this.form.dailyRate, ...marketRateVehicle },
        liveMileRate: { ...this.form.liveMileRate, ...marketRateVehicle },
        deadMileRate: { ...this.form.deadMileRate, ...marketRateVehicle },
        hourlyRate: { ...this.form.hourlyRate, ...marketRateVehicle },
      }
    }
  }

  handleUpdateDailyRate(rate: number): void {
    this.form = {
      ...this.form,
      dailyRate: {
        ...this.form.dailyRate,
        lowRate: rate,
        highRate: rate,
      },
    }
  }

  handleUpdateLiveMileRate(rate: number): void {
    this.form = {
      ...this.form,
      liveMileRate: {
        ...this.form.liveMileRate,
        lowRate: rate,
        highRate: rate,
      },
    }
  }

  handleUpdateDeadMileRate(rate: number): void {
    this.form = {
      ...this.form,
      deadMileRate: {
        ...this.form.deadMileRate,
        lowRate: rate,
        highRate: rate,
      },
    }
  }

  handleUpdateHourlyRate(rate: number): void {
    this.form = {
      ...this.form,
      hourlyRate: {
        ...this.form.hourlyRate,
        lowRate: rate,
        highRate: rate,
      },
    }
  }

  handleUpdateHourlyMinimum(hourlyMinimum: number): void {
    this.form = {
      ...this.form,
      hourlyRate: { ...this.form.hourlyRate, hourlyMinimum },
    }
  }

  async submit(): Promise<void> {
    if (await validateRules(this)) {
      this.$emit(!this.marketRateGroup ? 'submit' : 'update', this.form)
    }
  }

  cancel(): void {
    this.$emit('cancel')
  }

  mounted(): void {
    if (this.marketRateGroup) {
      this.form = { ...this.marketRateGroup }
    } else {
      const companyId = this.auth.getCompany.companyId
      const marketId = this.auth.getCompany.address?.nearestMarketId
      this.form = new MarketRateGroup({}, { marketId, companyId })
    }

    vclient
      .tableView({ page: -1, pageSize: -1 })
      .then((res) => res.data.resultList)
      .then((vehicles) => (this.vehicles = vehicles))

    client
      .vehicleTypes()
      .then((res) => res.data)
      .then((vehicleTypes) => (this.vehicleTypes = vehicleTypes))

    client
      .marketRateTypes()
      .then((res) => res.data)
      .then((marketRateTypes) => (this.marketRateTypes = marketRateTypes))
  }
}
