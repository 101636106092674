
import sidebar from '@/store/modules/sidebar'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { isNotEmptyInput } from '@/utils/validators'
import {
  CompanyPaymentGatewayDetail,
  SettingsIntegrationBlockDetail,
} from '@/models/PaymentGateway'
import auth from '@/store/modules/auth'
import payments from '@/services/payments'
import SettingsIntegrationsSidebarHeader from './SettingsIntegrationsSidebarHeader.vue'
import { EventBus } from '@/utils/eventBus'

@Component({ components: { SettingsIntegrationsSidebarHeader } })
export default class SettingsIntegrationsSquare extends Vue {
  @Prop({ required: false, type: Boolean }) isDefault!: boolean
  @Prop({ required: false }) integrationDetail: SettingsIntegrationBlockDetail

  isDefaultProcessor = false
  isInstalled = false
  isNotEmptyInput = isNotEmptyInput
  formData = {
    accessToken: '',
    applicationId: '',
  }

  @Watch('integrationDetail', { immediate: true })
  handleUpdateIntegrationDetail(): void {
    this.isDefaultProcessor = this.isDefault
    if (this.integrationDetail) {
      this.formData.applicationId =
        this.integrationDetail.gatewayDetails?.apiKey
      this.isInstalled = !!this.integrationDetail.gatewayDetails?.apiKey
    }
  }

  close(): void {
    sidebar.pop()
  }

  async submit(): Promise<void> {
    if (!this.$refs.form['validate']()) {
      return
    }

    const applicationId =
      this.formData.applicationId ||
      this.integrationDetail?.gatewayDetails?.apiKey
    const secretKey = this.formData.accessToken
    const payload: CompanyPaymentGatewayDetail = {
      apiKey: applicationId,
      clientKey: applicationId,
      companyId: auth.getCompanyId,
      paymentGatewayTypeKey: 'square',
      secretKey,
    }
    if (this.integrationDetail?.gatewayDetails) {
      payload.companyPaymentGatewayId =
        this.integrationDetail.gatewayDetails.companyPaymentGatewayId
      try {
        if (this.isDefaultProcessor) {
          await payments.updateDefaultPaymentGateway(
            this.integrationDetail.gatewayDetails.companyPaymentGatewayId
          )
        }
        await payments.updatePaymentGateway(payload)
      } catch (e) {
        console.log(e)
        EventBus.$emit(
          'snackbar:error',
          'There was an error adding the payment information.'
        )
        return
      }
    } else {
      try {
        await payments.createPaymentGateway(payload)
      } catch (e) {
        console.log(e)
        EventBus.$emit(
          'snackbar:error',
          'There was an error adding the payment information.'
        )
        return
      }
    }
    EventBus.$emit(
      'snackbar:success',
      'Payment information added successfully!'
    )
    this.$emit('refresh-integrations')
    sidebar.pop()
  }
}
