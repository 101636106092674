
import { Component, Prop, Vue } from 'vue-property-decorator'
import CUSidebarContent from '@/layouts/CUSidebarContent.vue'
import CUButton from '@/components/CUButton.vue'
import draggable from 'vuedraggable'
import sidebar from '@/store/modules/sidebar'
import { EventBus } from '@/utils/eventBus'
import { RateType } from '@/models/DriverPay'
import RateTypeDetails from './RateTypeDetails.vue'
import driverPay from '@/services/driverpay'

@Component({
  components: { CUSidebarContent, CUButton, draggable },
})
export default class EditRateTypes extends Vue {
  @Prop({ type: Array, required: true }) readonly initialRateTypes!: RateType[]

  localRateTypes: RateType[] = []
  deletedRateTypeIds: string[] = []
  selectedRateTypeId: number | null = null
  loading = false

  hoveredId = ''

  created(): void {
    this.localRateTypes = JSON.parse(JSON.stringify(this.initialRateTypes))
      .filter((rateType) => rateType.isActive !== false)
      .sort((a, b) => a.orderIndex - b.orderIndex)
  }

  getIconColor(id: string): string {
    return this.hoveredId === id ? 'primary' : 'bg-gray-4'
  }

  rateTypeIsHovered(id: string): void {
    if (!id) {
      return
    }
    this.hoveredId = id
  }

  updateOrder(): void {
    this.localRateTypes = this.localRateTypes.map((rateType, index) => ({
      ...rateType,
      orderIndex: index,
    }))
    this.hoveredId = null
  }

  toggleHidden(rateType: RateType): void {
    rateType.isHidden = !rateType.isHidden
  }

  deleteRateType(rateType: RateType): void {
    const index = this.localRateTypes.findIndex((rt) => rt.id === rateType.id)
    if (index !== -1) {
      this.localRateTypes.splice(index, 1)
      if (rateType.id) {
        this.deletedRateTypeIds.push(rateType.id)
      }
      this.updateOrder()
    }
  }

  emitImmediateChanges(): void {
    const relevantChanges: Partial<RateType>[] = this.localRateTypes.map(
      (rate) => ({
        id: rate.id,
        label: rate.label,
        baseRateKey: rate.baseRateKey,
      })
    )
    this.$emit('immediate-rate-update', relevantChanges)
  }

  addNewRateType(): void {
    sidebar.push({
      component: RateTypeDetails,
      props: {},
      on: {
        'add-rate-type': this.addRateType,
      },
    })
  }

  editRateType(rateType: RateType): void {
    sidebar.push({
      component: RateTypeDetails,
      props: { initialRateType: rateType },
      on: {
        'update-rate-type': this.updateRateType,
      },
    })
  }

  async updateRateType(updatedRateType: RateType): Promise<void> {
    const index = this.localRateTypes.findIndex(
      (rt) => rt.id === updatedRateType.id
    )
    if (index === -1) {
      console.error('Rate type not found in local state')
      return
    }

    const originalRateType = { ...this.localRateTypes[index] }

    try {
      this.$set(this.localRateTypes, index, updatedRateType)
      await driverPay.updateCompanyRateType(updatedRateType)
      EventBus.$emit('snackbar:success', 'Rate type updated successfully.')
      this.emitImmediateChanges()
    } catch (err: any) {
      console.error('Failed to update rate type:', err)
      this.$set(this.localRateTypes, index, originalRateType)

      if (err?.response?.data?.message?.toLowerCase().includes('duplicate')) {
        EventBus.$emit(
          'snackbar:error',
          'Cannot update rate type. A rate type with this name already exists.'
        )
      } else {
        EventBus.$emit('snackbar:error', 'Failed to update rate type.')
      }
    }
  }

  async addRateType(newRateType: RateType): Promise<void> {
    newRateType.orderIndex = this.localRateTypes.length
    this.localRateTypes.push(newRateType)

    try {
      const response = await driverPay.addCompanyRateType(newRateType)
      newRateType.id = response.data.id
      EventBus.$emit('snackbar:success', 'Rate type added successfully.')
      this.emitImmediateChanges()
    } catch (err: any) {
      if (err?.response?.data?.message?.toLowerCase().includes('duplicate')) {
        EventBus.$emit(
          'snackbar:error',
          'Cannot add rate type. A rate type with this name already exists.'
        )
      } else {
        EventBus.$emit('snackbar:error', 'Failed to add rate type.')
      }
      this.deleteRateType(newRateType)
    }
  }

  cancel(): void {
    sidebar.pop()
  }

  apply(): void {
    const activeRateTypesCount = this.localRateTypes.length
    const finalRateTypes = [
      ...this.localRateTypes,
      ...this.deletedRateTypeIds.map((id, index) => {
        const originalRateType = this.initialRateTypes.find(
          (rt) => rt.id === id
        )
        return {
          id,
          label: originalRateType?.label,
          isActive: false,
          baseRateKey: originalRateType?.baseRateKey,
          isDefault: originalRateType?.isDefault,
          isHidden: originalRateType?.isHidden,
          orderIndex: activeRateTypesCount + index,
        }
      }),
    ]
    this.$emit('apply-rate-types', finalRateTypes)
    sidebar.pop()
  }
}
