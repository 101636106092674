
import sidebar from '@/store/modules/sidebar'
import { Vue, Component } from 'vue-property-decorator'
import SettingsIntegrationsSidebarHeader from './SettingsIntegrationsSidebarHeader.vue'

@Component({ components: { SettingsIntegrationsSidebarHeader } })
export default class SettingsIntegrationsSamsara extends Vue {
  formData = {
    currentAPIToken: '',
    newAPIToken: '',
  }

  deleteIntegration(): void {
    sidebar.pop()
  }

  async submit(): Promise<void> {
    sidebar.pop()
  }
}
