export const pricingSelectionTypes = [
  {
    id: 1,
    key: 'highest_daily',
    label: 'Highest Daily',
    description: 'Trip priced by the highest rate, which is the daily rate',
    active: true,
  },
  {
    id: 2,
    key: 'highest_mileage',
    label: 'Highest Mileage',
    description: 'Trip priced by the highest rate, which is the mileage rate',
    active: true,
  },
  {
    id: 3,
    key: 'highest_hourly',
    label: 'Highest Hourly',
    description: 'Trip priced by the highest rate, which is the hourly rate',
    active: true,
  },
  {
    id: 4,
    key: 'choose_daily',
    label: 'Choose Daily',
    description: 'Trip priced by user selecting the daily rate',
    active: true,
  },
  {
    id: 5,
    key: 'choose_mileage',
    label: 'Choose Mileage',
    description: 'Trip priced by the user selecting the mileage rate',
    active: true,
  },
  {
    id: 6,
    key: 'choose_hourly',
    label: 'Choose Hourly',
    description: 'Trip priced by the user selecting the hourly rate',
    active: true,
  },
  {
    id: 7,
    key: 'choose_daily_mileage',
    label: 'Choose Daily and Mileage',
    description: 'Trip priced by user selecting the daily and mileage rates',
    active: true,
  },
  {
    id: 8,
    key: 'choose_daily_hourly',
    label: 'Choose Daily and Hourly',
    description: 'Trip priced by user selecting the daily and hourly rates',
    active: true,
  },
  {
    id: 9,
    key: 'choose_hourly_mileage',
    label: 'Choose Hourly and Mileage',
    description: 'Trip priced by user selecting the hourly and mileage rates',
    active: true,
  },
  {
    id: 10,
    key: 'choose_daily_mileage_hourly',
    label: 'Choose Daily, Mileage, and Hourly',
    description:
      'Trip priced by user selecting daily, mileage, and hourly rates',
    active: true,
  },
  {
    id: 11,
    key: 'choose_override',
    label: 'Choose Override',
    description: 'Trip priced by user manually setting the base fare',
    active: true,
  },
]
