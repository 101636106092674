import { Address } from '@/models/dto'
import places from '@/services/places'

export const getCenteringDataByAddress = async (
  address: Address
): Promise<{ lat: number; lng: number; timeZone: string }> => {
  if (!address?.state && !address?.city && !address?.postalCode) {
    return { lat: null, lng: null, timeZone: null }
  }

  // Searching with format 'State, City Zip'
  const searchTerm = `${address.state ? `${address.state}` : ''}${
    address.city ? `, ${address.city}` : ''
  }${address.postalCode ? ` ${address.postalCode}` : ''}`

  if (searchTerm) {
    const res = await places.bySearchTerm(searchTerm)
    if (res.data?.[0]?.place_id) {
      const placeResponse = await places.byId(res.data[0].place_id)
      return {
        lat: placeResponse.data?.lat,
        lng: placeResponse.data?.lng,
        timeZone: placeResponse.data?.timeZone,
      }
    }
  }

  return { lat: null, lng: null, timeZone: null }
}
