
import { Vue, Component, Prop } from 'vue-property-decorator'
import sidebar from '@/store/modules/sidebar'
import HoldUpModal from '@/components/HoldUpModal.vue'
@Component({
  components: { HoldUpModal },
})
export default class BusifySidebarHeader extends Vue {
  state = sidebar
  @Prop({ required: false }) readonly title!: string
  @Prop({ required: false, type: Boolean }) readonly isInstalled!: boolean
  @Prop({ type: Boolean, default: false }) readonly readonly!: boolean

  isModalOpen = false

  openModal(): void {
    if (!this.readonly) {
      this.isModalOpen = true
    }
  }

  closeModal(): void {
    this.isModalOpen = false
  }

  closePanel(): void {
    sidebar.close()
    this.$emit('close')
  }
}
