
import Component, { mixins } from 'vue-class-component'
import { DataTableColumn } from '@/models/DataTableColumn'
import sidebar from '@/store/modules/sidebar'
import quickbooksService from '@/services/quickbooks'
import DateMixin from '@/mixins/DateMixin'
import CUCollectionTable2 from './CUCollectionTable2.vue'
import { QUICKBOOKS_ACTIVITY_LOGS_FILTER_ACTION } from '@/models/FilterActionTypes'
import dayjs from 'dayjs'
import auth from '@/store/modules/auth'

@Component({
  components: { CUCollectionTable2 },
})
export default class QuickBooksActivityLogSidebar extends mixins(DateMixin) {
  exportQuickBooksActivityLogs = quickbooksService.exportQuickBooksActivityLogs
  filterActionType = QUICKBOOKS_ACTIVITY_LOGS_FILTER_ACTION

  tableView = quickbooksService.getQuickBooksActivityLogsTableView

  get columns(): DataTableColumn[] {
    return [
      {
        _t_id: 'abf8db24-0904-4a45-a877-3a4faabb8944',
        text: 'Date',
        value: 'date',
        type: 'text',
        width: 200,
        hidden: false,
        sortable: false,
        filterable: false,
        computedText: (row): string =>
          this.formatWeekdayLongDate(dayjs.utc(row.date), {
            showTime: false,
            tz: auth.getUserTimeZone,
          }),
      },
      {
        _t_id: '7640ca54-1819-4faa-96e1-91f62f435f9e',
        text: 'Hour',
        value: 'hour',
        type: 'text',
        width: 120,
        hidden: false,
        sortable: false,
        filterable: false,
        computedText: (row): string =>
          this.formatShortTime(dayjs.utc(row.hour), {
            showMeridianUpper: true,
            showTimezone: true,
            tz: auth.getUserTimeZone,
          }),
      },
      {
        _t_id: '1f3955d3-8e8b-4fe7-8446-5c57cba8aeb3',
        text: 'User',
        value: 'user',
        type: 'text',
        width: 110,
        sortable: false,
        filterable: false,
      },
      {
        _t_id: '6256606a-ba47-40fa-962b-388ab6fc3665',
        text: 'Event',
        value: 'description',
        type: 'text',
        sortable: false,
        filterable: false,
      },
      {
        _t_id: '74c2977d-bbd1-4185-bb59-cc4adbdbc436',
        text: 'Account',
        value: 'realmId',
        type: 'text',
        width: 200,
        sortable: false,
        filterable: false,
      },
    ]
  }

  close(): void {
    sidebar.pop()
  }
}
