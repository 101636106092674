
import { Vue, Component } from 'vue-property-decorator'
import SettingsIntegrationsSidebarHeader from './SettingsIntegrationsSidebarHeader.vue'

@Component({ components: { SettingsIntegrationsSidebarHeader } })
export default class SettingsIntegrationsMotive extends Vue {
  motiveLogin(): void {
    // TODO
  }
}
