
import sidebar from '@/store/modules/sidebar'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { SettingsIntegrationBlockDetail } from '@/models/PaymentGateway'
import SettingsIntegrationsSidebarHeader from './SettingsIntegrationsSidebarHeader.vue'
import {
  CharterUpApiCredential,
  CharterUpMarketRateGroup,
  CharterUpRate,
} from '@/models/dto/CharterUp'
import { EventBus } from '@/utils/eventBus'
import messages from '@/data/messages'
import CUSimpleTable from '@/components/CUSimpleTable.vue'
import { SimpleTableColumn } from '@/models/SimpleTableColumn'
import CUCheckboxCircular from '@/components/CUCheckboxCircular.vue'
import { currencyFilter } from '@/utils/string'
import {
  applyToCharterUpMarketRateGroup,
  toCharterUpMarketRateGroups,
  toCharterUpMarketRateRequest,
} from '@/utils/marketRate'
import SettingsIntegrationsCharterUpVehicleRateForm from '@/components/SettingsIntegrationsCharterUpVehicleRateForm.vue'
import HoldUpModal from '@/components/HoldUpModal.vue'
import client from '@/services/charterup'
import tclient from '@/services/type'
import { VehicleType } from '@/models/dto'
import auth from '@/store/modules/auth'
import quickbooksService from '@/services/quickbooks'
import CUQuickbooksCustomerSync from './CUQuickbooksCustomerSync.vue'
import { QuickbooksCustomerAssignRequest } from '@/models/dto/Quickbooks'
import app from '@/store/modules/app'

@Component({
  components: {
    HoldUpModal,
    CUCheckboxCircular,
    CUSimpleTable,
    SettingsIntegrationsSidebarHeader,
    CUQuickbooksCustomerSync,
  },
})
export default class SettingsIntegrationsCharterUpIntegrated extends Vue {
  @Prop({ required: false, type: Boolean }) isInstalled!: boolean
  @Prop({ required: false }) integrationDetail: SettingsIntegrationBlockDetail
  form: CharterUpApiCredential = new CharterUpApiCredential()
  messages = messages

  isLoading = false
  isSyncing = false
  isSyncingReferrals = false
  isDeletingIntegration = false
  isModalOpen = false
  showRemoveIntegrationModal = false

  vehicleRateGroups: CharterUpMarketRateGroup[] = []
  vehicleTypes: VehicleType[] = []
  integrationSettings: Record<string, boolean> = {
    drivers: true,
    garages: true,
    charterup_vehicle_rates: true,
    vehicles: true,
    tracking: true,
    trip_referrals: true,
    trip_updates: true,
    trip_payments: true,
  }

  quickbooksCustomerId: string = null
  quickbooksContacts = []
  createNewQuickbooksCustomer = false
  auth = auth
  originalQuickbooksCustomerId = null
  showCustomerCreationOverrideModal = false
  submitLoading = false
  quickBooksCustomerChanged = false

  get confirmQBCustomerCreationText(): string {
    const displayName = 'CharterUp'
    return `Your QuickBooks account already has a customer with this name. A new customer with name <strong>${displayName} (#)</strong> will be created instead and synced to this Busify contact.`
  }

  @Watch('auth.isQuickbooksIntegrationEnabled', {
    immediate: true,
    deep: false,
  })
  onQuickbooksIntegrationChanged(newValue: boolean): void {
    if (newValue === true) {
      this.fetchQuickbooksCustomerDetail()
      this.fetchQuickBooksContacts()
    }
  }

  handleQuickbooksSync({
    sync,
    contactId,
    createNewCustomer = false,
  }: {
    sync: boolean
    contactId: string
    createNewCustomer?: boolean
  }): void {
    this.quickBooksCustomerChanged = true
    if (sync) {
      if (createNewCustomer) {
        this.createNewQuickbooksCustomer = true
        this.quickbooksCustomerId = null
        return
      }
      this.createNewQuickbooksCustomer = false
      this.quickbooksCustomerId = contactId
      return
    }
    this.createNewQuickbooksCustomer = false
    this.quickbooksCustomerId = null
  }

  async fetchQuickBooksContacts(): Promise<void> {
    try {
      const response = await quickbooksService.getCompanyQuickbooksCustomers(
        auth.getCompanyId
      )
      const customers = response.data.customers
      this.quickbooksContacts = [
        ...customers.map((contact) => {
          return {
            text: `${contact.name} (ID: ${contact.id})`,
            value: contact.id,
          }
        }),
      ]
    } catch (e) {
      console.log(e)
    }
  }

  async fetchQuickbooksCustomerDetail(): Promise<void> {
    try {
      const quickbooksDetail =
        await quickbooksService.getContactQuickbooksDetail(
          auth.getCompanyId,
          Number(app.charterupReferralUserId)
        )

      this.quickbooksCustomerId =
        quickbooksDetail?.data?.customerSync?.quickbooksCustomerId
    } catch (error) {
      console.log('Error with Quickbooks fetch:', error)
    }
  }

  async overrideCustomerCreation(): Promise<void> {
    this.submitLoading = true
    try {
      const response =
        await quickbooksService.createQuickbooksCustomerWithBusifyContactId(
          auth.getCompanyId,
          Number(app.charterupReferralUserId),
          true
        )
      this.originalQuickbooksCustomerId =
        response.data.customerSync.quickbooksCustomerId
      this.quickbooksCustomerId = this.originalQuickbooksCustomerId
      this.createNewQuickbooksCustomer = false
      this.fetchQuickBooksContacts()
    } catch (e) {
      console.log(e)
      EventBus.$emit(
        'snackbar:error',
        'Unable to create new Quickbooks Customer'
      )
    }
    this.submitLoading = false
  }

  handleAddRate(): void {
    const marketRateGroups: CharterUpMarketRateGroup[] = this.vehicleRateGroups
    sidebar.push({
      component: SettingsIntegrationsCharterUpVehicleRateForm,
      props: { marketRateGroups },
      on: {
        submit: (rates: CharterUpMarketRateGroup) =>
          this.submitRate(marketRateGroups, rates),
        cancel: this.close,
      },
    })
  }

  handleEditRates(marketRateGroup: CharterUpMarketRateGroup): void {
    const marketRateGroups = this.vehicleRateGroups
    sidebar.push({
      component: SettingsIntegrationsCharterUpVehicleRateForm,
      props: { marketRateGroup, marketRateGroups },
      on: {
        update: this.updateRate,
        delete: this.deleteRate,
        cancel: this.close,
      },
    })
  }

  async submit(): Promise<void> {
    this.submitLoading = true
    console.log('submit')
    if (this.createNewQuickbooksCustomer) {
      try {
        const response =
          await quickbooksService.createQuickbooksCustomerWithBusifyContactId(
            auth.getCompanyId,
            Number(app.charterupReferralUserId)
          )
        this.originalQuickbooksCustomerId =
          response.data.customerSync.quickbooksCustomerId
        this.quickbooksCustomerId = this.originalQuickbooksCustomerId
        this.createNewQuickbooksCustomer = false
        this.fetchQuickBooksContacts()
        EventBus.$emit(
          'snackbar:success',
          'QuickBooks customer creation successful'
        )
      } catch (e: any) {
        if (
          e.response.data.status === 'BAD_REQUEST' &&
          (e.response.data.message.includes('Duplicate Name') ||
            e.response.data.message.includes('already exists'))
        ) {
          this.showCustomerCreationOverrideModal = true
          this.submitLoading = false
          this.quickBooksCustomerChanged = false
          return
        }
        console.log(e)
        EventBus.$emit(
          'snackbar:error',
          'Unable to create new Quickbooks Customer'
        )
      }
    } else if (
      this.quickbooksCustomerId &&
      this.quickbooksCustomerId !== this.originalQuickbooksCustomerId
    ) {
      try {
        const request: QuickbooksCustomerAssignRequest = {
          busifyId: Number(app.charterupReferralUserId),
          qboId: this.quickbooksCustomerId,
        }
        await quickbooksService.assignQuickbooksCustomer(
          auth.getCompanyId,
          request
        )
        EventBus.$emit(
          'snackbar:success',
          'QuickBooks customer assignment successful'
        )
      } catch (e) {
        console.log(e)
        EventBus.$emit(
          'snackbar:error',
          'Unable to connect contact to Quickbooks Customer'
        )
        this.submitLoading = false
        return
      }
    } else if (!this.quickbooksCustomerId) {
      try {
        await quickbooksService.deleteBusifyContactQuickbooksCustomerAssociation(
          auth.getCompanyId,
          Number(app.charterupReferralUserId)
        )
        EventBus.$emit(
          'snackbar:success',
          'QuickBooks customer assignment removal successful'
        )
      } catch (e) {
        console.log(e)
        EventBus.$emit(
          'snackbar:error',
          'Unable to delete Quickbooks Customer Sync'
        )
      }
    }
    this.submitLoading = false
    this.quickBooksCustomerChanged = false
    return
  }

  isConflicting(
    vehicleRateGroups: CharterUpMarketRateGroup[],
    vehicleRateGroup: CharterUpMarketRateGroup
  ): boolean {
    return !!vehicleRateGroups.find(
      ({ vehicleTypeKey }) => vehicleTypeKey === vehicleRateGroup.vehicleTypeKey
    )
  }

  submitRate(
    vehicleRateGroups: CharterUpMarketRateGroup[],
    vehicleRateGroup: CharterUpMarketRateGroup
  ): void {
    if (this.isConflicting(vehicleRateGroups, vehicleRateGroup)) {
      EventBus.$emit('snackbar:error', messages.errors.conflictingVehicleRate)
      return
    }
    const applyFn = (r) => client.createRate(toCharterUpMarketRateRequest(r))
    const promises = applyToCharterUpMarketRateGroup(vehicleRateGroup, applyFn)
    Promise.all(promises)
      .then(this.snackbarRateCreatedSuccess)
      .then(sidebar.close)
      .finally(this.loadVehicleRates)
  }

  updateRate(vehicleRateGroup: CharterUpMarketRateGroup): void {
    const applyFn = (r) => client.updateRate(toCharterUpMarketRateRequest(r))
    const promises = applyToCharterUpMarketRateGroup(vehicleRateGroup, applyFn)
    Promise.all(promises)
      .then(this.snackbarRateUpdatedSuccess)
      .then(sidebar.close)
      .finally(this.loadVehicleRates)
  }

  deleteRate(vehicleRateGroup: CharterUpMarketRateGroup): void {
    const applyFn = (r) => client.deleteRate(r?.marketRateId)
    const promises = applyToCharterUpMarketRateGroup(vehicleRateGroup, applyFn)
    Promise.all(promises)
      .then(this.snackbarRateDeletedSuccess)
      .then(sidebar.close)
      .finally(this.loadVehicleRates)
  }

  handleSyncData(): void {
    this.isSyncing = true
    client
      .sync({ vehicles: true, drivers: true, garages: true })
      .then((res) => {
        if (
          !res.data.data.garages.success ||
          !res.data.data.vehicles.success ||
          !res.data.data.drivers.success
        ) {
          this.snackbarSyncPartial()
        } else {
          this.snackbarSyncSuccess()
        }
      })
      .catch(this.snackbarSyncFailure)
      .finally(() => (this.isSyncing = false))
  }

  handleSyncReferrals(): void {
    this.isSyncingReferrals = true
    client
      .syncReferrals()
      .then(this.snackbarSyncReferralsSuccess)
      .catch(this.snackbarSyncReferralsFailure)
      .finally(() => (this.isSyncingReferrals = false))
  }

  snackbarRateCreatedSuccess(): void {
    EventBus.$emit('snackbar:success', messages.success.charterUpRateCreated)
  }

  snackbarRateUpdatedSuccess(): void {
    EventBus.$emit('snackbar:success', messages.success.charterUpRateUpdated)
  }

  snackbarRateDeletedSuccess(): void {
    EventBus.$emit('snackbar:success', messages.success.charterUpRateDeleted)
  }

  snackbarRateCreationFailure(): void {
    EventBus.$emit('snackbar:error', messages.errors.conflictingVehicleRate)
  }

  snackbarSyncSuccess(): void {
    EventBus.$emit('snackbar:success', messages.success.charterUpSync)
  }

  snackbarSyncPartial(): void {
    EventBus.$emit('snackbar:error', messages.partial.charterUpSync)
  }

  snackbarSyncFailure(): void {
    EventBus.$emit('snackbar:error', messages.errors.charterUpSync)
  }

  snackbarSyncReferralsSuccess(): void {
    EventBus.$emit('snackbar:success', messages.success.charterUpSyncReferrals)
  }

  snackbarSyncReferralsFailure(): void {
    EventBus.$emit('snackbar:error', messages.errors.charterUpSyncReferrals)
  }

  snackbarDisableSuccess(): void {
    EventBus.$emit(
      'snackbar:success',
      messages.success.charterUpIntegrationDisabled
    )
  }

  snackbarDisableFailure(): void {
    EventBus.$emit(
      'snackbar:error',
      messages.errors.charterUpIntegrationDisabled
    )
  }

  refreshIntegrations(): void {
    this.$emit('refresh-integrations')
  }

  disableIntegration(): void {
    this.isDeletingIntegration = true
    client
      .deleteCharterUpIntegration()
      .then(this.snackbarDisableSuccess)
      .catch(this.snackbarDisableFailure)
      .finally(this.refreshIntegrations)
      .finally(sidebar.close)
  }

  close(): void {
    sidebar.pop()
  }

  getVehicleTypeLabel(key: string): string {
    return this.vehicleTypes.find((t) => t.key === key)?.label || ''
  }

  vehicleRateHeaders: SimpleTableColumn<CharterUpMarketRateGroup>[] = [
    {
      text: 'Vehicle Type',
      value: 'vehicleTypeKey',
      cellClass: 'text-green cursor-pointer',
      formatter: (row: CharterUpMarketRateGroup): string =>
        this.getVehicleTypeLabel(row.vehicleTypeKey || ''),
      onClick: (row: CharterUpMarketRateGroup): void =>
        this.handleEditRates(row),
    },
    {
      text: 'Transfer',
      value: 'transferRate/highRate',
      formatter: (row: CharterUpMarketRateGroup): string =>
        row.transferRate?.highRate
          ? currencyFilter(row.transferRate.highRate)
          : '--',
    },
    {
      text: 'Dead Mile',
      value: 'deadMileRate/highRate',
      formatter: (row: CharterUpMarketRateGroup): string =>
        row.deadMileRate?.highRate
          ? currencyFilter(row.deadMileRate.highRate)
          : '--',
    },
    {
      text: 'Live Mile',
      value: 'liveMileRate/highRate',
      formatter: (row: CharterUpMarketRateGroup): string =>
        row.liveMileRate?.highRate
          ? currencyFilter(row.liveMileRate.highRate)
          : '--',
    },
    {
      text: 'Hourly',
      value: 'hourlyRate/highRate',
      formatter: (row: CharterUpMarketRateGroup): string =>
        row.hourlyRate?.highRate
          ? currencyFilter(row.hourlyRate.highRate)
          : '--',
    },
    {
      text: 'Hr Min',
      value: '',
      formatter: (row: CharterUpMarketRateGroup): string =>
        row.hourlyRate?.hourlyMinimum
          ? currencyFilter(row.hourlyRate.hourlyMinimum)
          : '--',
    },
    {
      text: 'Daily',
      value: 'dailyRate/highRate',
      width: '0px',
      formatter: (row: CharterUpMarketRateGroup): string =>
        row.dailyRate?.highRate ? currencyFilter(row.dailyRate.highRate) : '--',
    },
  ]

  toVehicleRateGroups(rates: CharterUpRate[]): CharterUpMarketRateGroup[] {
    return Object.values((rates || []).reduce(toCharterUpMarketRateGroups, {}))
  }

  loadVehicleRates(): Promise<CharterUpMarketRateGroup[]> {
    return client
      .getRates()
      .then((res) => res.data?.data)
      .then((rates) => (rates || []).filter((r) => r.active))
      .then(this.toVehicleRateGroups)
      .then((vehicleRateGroups) => (this.vehicleRateGroups = vehicleRateGroups))
  }

  loadVehicleTypes(): Promise<VehicleType[]> {
    return tclient
      .vehicleTypes()
      .then((res) => res.data)
      .then((vehicleTypes) => (this.vehicleTypes = vehicleTypes))
  }

  mounted(): void {
    this.isLoading = true
    Promise.all([this.loadVehicleRates(), this.loadVehicleTypes()]).finally(
      () => (this.isLoading = false)
    )
  }
}
