
import { Vue, Component, Prop } from 'vue-property-decorator'
import { preTripArrivalTimeOffsetList, spotTimeOffsetList } from '@/utils/time'
import { Company } from '@/models/dto/Company'
import {
  isPricingSelectionIdHighest,
  pricingSelectionToRateTypes,
  rateTypes,
  rateTypesToPricingSelectionKey,
} from '@/utils/pricing'
import { TripPricingSelection, Type } from '@/models/dto'
import { EventType } from '@/models/EventType'
import sidebar from '@/store/modules/sidebar'
import EventTypesSidebar from './EventTypesSidebar.vue'
import app from '@/store/modules/app'
import { EventBus } from '@/utils/eventBus'
import { pricingSelectionTypes } from '@/data/pricingSelectionTypes'
import { apiBaseUrl } from '@/utils/env'
import auth from '@/store/modules/auth'
import typeState from '@/store/modules/types'
import deepClone from '@/utils/deepClone'
import { vehicleTypeOrder } from '@/utils/vehicle'
import { Element } from '@/models/dto/Action'
import { Garage } from '@/models/dto/Garage'

@Component({})
export default class SettingsGeneralDefaultsGeneral extends Vue {
  @Prop({ required: true }) readonly company!: Partial<Company>
  @Prop({ required: true, type: Boolean }) readonly hasModifiedCompany!: boolean
  @Prop({ required: true, type: Boolean }) readonly loading!: boolean
  @Prop({ required: true, type: Boolean }) readonly companyLoading!: boolean

  preTripArrivalTimes = [
    { label: 'No default', time: '00:00:00' },
    ...preTripArrivalTimeOffsetList,
  ]

  spotTimesOffsetList = [
    { minutes: 0, label: 'No default' },
    ...spotTimeOffsetList,
  ]

  pricingSelectionTypes = pricingSelectionTypes
  rateTypes = rateTypes

  // COMPUTEDS
  get isLeadingDateToggledOn(): boolean {
    return this.company.defaultDateFormat === 'DD/MM/YYYY'
  }

  get garages(): Partial<Garage>[] {
    const garages = [
      { garageId: null, garageName: 'No default' },
      ...typeState.garages,
    ]
    return garages
  }

  get isDriverCommuteEnabled(): boolean {
    const paramValue = app.getSystemParameters.find(
      ({ name }) => name === 'isDriverCommuteEnabled'
    )?.value
    return paramValue === 'true' || paramValue === auth.getCompanyId?.toString()
  }

  get defaultPricingSelectionType(): Type {
    if (!this.pricingSelectionTypes) {
      return {
        id: 1,
        label: 'Highest Daily',
        key: 'highest_daily',
      }
    }

    const pricingSelectionId = this.company.defaultPricingSelectionId || 1
    return this.pricingSelectionTypes.find(
      ({ id }) => id === pricingSelectionId
    )
  }

  get publicUri(): string {
    return this.company.publicUri
      ? `https://customers.${apiBaseUrl(null)}/widget/${this.company.publicUri}`
      : null
  }

  get currentRateTypes(): Type[] {
    return pricingSelectionToRateTypes(this.defaultPricingSelectionType)
  }

  get selectionTypeLabel(): string {
    return this.isDefaultPricingMethodTypeHighest ? 'Highest' : 'Choose'
  }

  get isDefaultPricingMethodTypeHighest(): boolean {
    return isPricingSelectionIdHighest(this.company.defaultPricingSelectionId)
  }

  get calculationTypeSubtitle(): string {
    if (this.isDefaultPricingMethodTypeHighest) {
      return 'Highest will automatically pick the highest calculated base fare between Daily, Hourly, and Mileage'
    }
    return 'Choose allows you to pick a single calculated rate or sum more than one calculate rates'
  }
  get pricingSelectionTypeOutput(): string {
    if (this.isDefaultPricingMethodTypeHighest) {
      return 'Highest of the three'
    }

    const activeRateTypes = this.currentRateTypes
    return activeRateTypes.map(({ label }) => label).join(' + ')
  }

  get eventTypes(): EventType[] {
    const eventTypes = this.company.eventTypes || []
    eventTypes.sort((a, b) => (a.orderIndex > b.orderIndex ? 1 : -1))
    return eventTypes
  }

  get vehicleTypesList(): Element[] {
    const vehicleTypes = deepClone(this.company.vehicleTypes || [])
    return vehicleTypes
      .map((t) => ({ id: t.id, label: t.label, active: t.active }))
      .sort(
        (a, b) =>
          vehicleTypeOrder.indexOf(a.label) - vehicleTypeOrder.indexOf(b.label)
      )
  }

  get isDateFormatSettingEnabled(): boolean {
    return app.isDateFormatSettingEnabled
  }

  get isDriverAppPayStubsSettingEnabled(): boolean {
    return app.isSystemParameterTrue('isDriverAppPayStubsSettingEnabled')
  }

  // METHODS
  handleSelectionChange(selectionType: TripPricingSelection): void {
    const highestType = this.pricingSelectionTypes.find(
      ({ key }) => key === 'highest_daily'
    )
    const chooseType = this.pricingSelectionTypes.find(
      ({ key }) => key === 'choose_override'
    )
    if (selectionType === 'Highest') {
      this.$emit('updateCompanyPartial', {
        defaultPricingSelectionId: highestType.id,
      })
    } else {
      this.$emit('updateCompanyPartial', {
        defaultPricingSelectionId: chooseType.id,
      })
    }
  }

  isRateTypeSelected(rateType: Type): boolean {
    const types = pricingSelectionToRateTypes(this.defaultPricingSelectionType)
    return !!types.find(({ key }) => key === rateType.key)
  }

  // Update methods
  handleOpenEventTypesSidebar(): void {
    const companyId = this.company.companyId
    const eventTypes = this.eventTypes

    sidebar.popAllAndPush({
      title: 'Edit Event Types',
      component: EventTypesSidebar,
      props: {
        companyId,
        eventTypes,
      },
    })
  }

  handleUpdateVehicleTypes(list: Element[]): void {
    this.$emit('updateCompanyPartial', {
      vehicleTypes: list.map((el) => ({
        ...el,
        vehicleTypeId: this.company.vehicleTypes.find(({ id }) => id === el.id)
          .vehicleTypeId,
        companyId: this.company.companyId,
      })),
    })
  }

  handleUpdateDefaultGarage(defaultGarageId: number): void {
    this.$emit('updateCompanyPartial', { defaultGarageId })
  }

  handleCopyPublicUri(): void {
    navigator.clipboard.writeText(this.publicUri)
    EventBus.$emit('snackbar:success', 'Copied to clipboard')
  }

  handleRateTypeUpdate(rateType: Type): void {
    if (this.isDefaultPricingMethodTypeHighest) {
      return
    }

    let currentRateTypes = [...this.currentRateTypes]
    const rateExists = !!currentRateTypes.find(
      ({ key }) => key === rateType.key
    )
    if (rateExists) {
      currentRateTypes = currentRateTypes.filter(
        ({ key }) => key !== rateType.key
      )
    } else {
      currentRateTypes = [...currentRateTypes, rateType]
    }
    const pricingSelectionKey = rateTypesToPricingSelectionKey(currentRateTypes)
    const { id: defaultPricingSelectionId } = this.pricingSelectionTypes.find(
      ({ key }) => key === pricingSelectionKey
    )
    this.$emit('updateCompanyPartial', {
      defaultPricingSelectionId,
    })
  }

  handleUpdatePreTripArrivalTime(defaultPreTripArrivalTime: string): void {
    this.$emit('updateCompanyPartial', { defaultPreTripArrivalTime })
  }

  handleUpdateDefaultSpotTimeOffset(defaultSpotTimeOffset: number): void {
    this.$emit('updateCompanyPartial', { defaultSpotTimeOffset })
  }

  handleUpdateCompanyIsTrackingOdometry(isTrackingOdometry: boolean): void {
    this.$emit('updateCompanyPartial', { isTrackingOdometry })
  }

  handleUpdateCompanyEnableDriverAppPayStubs(
    enableDriverAppPayStubs: boolean
  ): void {
    this.$emit('updateCompanyPartial', { enableDriverAppPayStubs })
  }

  handleUpdateCompanyEnableTrackingLink(
    defaultEnableTrackingLink: boolean
  ): void {
    this.$emit('updateCompanyPartial', { defaultEnableTrackingLink })
  }

  handleUpdateEnableDriverAssignmentAcceptance(
    enableDriverAssignmentAcceptance: boolean
  ): void {
    this.$emit('updateCompanyPartial', { enableDriverAssignmentAcceptance })
  }

  handleUpdateDefaultEnableDriverCommute(
    defaultEnableDriverCommute: boolean
  ): void {
    this.$emit('updateCompanyPartial', { defaultEnableDriverCommute })
  }

  handleUpdateDateFormatting(e: boolean): void {
    const defaultDateFormat = e ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
    this.$emit('updateCompanyPartial', { defaultDateFormat })
  }

  async mounted(): Promise<void> {
    await typeState.loadGarages()
  }
}
