
import { Vue, Component } from 'vue-property-decorator'
import SettingsIntegrations from '@/components/SettingsIntegrations.vue'
import ContentWithSidebar from '@/layouts/ContentWithSidebar.vue'
import SettingsGeneral from '@/components/SettingsGeneral.vue'
import SettingsVehicleRates from '@/components/SettingsVehicleRates.vue'
import SettingsUsers from '@/components/SettingsUsers.vue'
import SettingsCustomCharges from '@/components/SettingsCustomCharges.vue'
import SettingsDriverPay from '@/components/SettingsDriverPay.vue'
import SettingsGarages from '@/components/SettingsGarages.vue'
import SettingsCompanyStops from '@/components/SettingsCompanyStops.vue'
import SettingsTemplates from '@/components/SettingsTemplates.vue'
import SettingsMyProfile from '@/components/SettingsMyProfile.vue'
import { Component as VueComponent } from 'vue'
import auth from '@/store/modules/auth'
import SettingsIndustries from '@/components/SettingsIndustries.vue'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'
import payments from '@/services/payments'
import { CompanyPaymentGatewayDetail } from '@/models/PaymentGateway'

@Component({
  components: {
    SettingsIntegrations,
    ContentWithSidebar,
    SettingsGeneral,
    SettingsVehicleRates,
    SettingsUsers,
    SettingsCustomCharges,
    SettingsDriverPay,
    SettingsGarages,
    SettingsCompanyStops,
    SettingsTemplates,
    SettingsMyProfile,
  },
})
export default class Settings extends Vue {
  paymentGateways: CompanyPaymentGatewayDetail[] = []
  defaultPaymentGateway: CompanyPaymentGatewayDetail = null
  isBusifyPayInstalled = false

  links = [
    { text: 'General', name: 'settings.general' },
    { text: 'Vehicle Rates', name: 'settings.rates' },
    { text: 'Users', name: 'settings.users' },
    { text: 'Custom Charges', name: 'settings.charges' },
    { text: 'Garages', name: 'settings.garages' },
    { text: 'Saved Stops', name: 'settings.saved-stops', isNew: true },
    { text: 'Driver Pay', name: 'settings.driver-pay' },
    { text: 'Industries', name: 'settings.industries' },
    { text: 'Templates', name: 'settings.templates' },
    { text: 'Integrations', name: 'settings.integrations' },
  ]

  get isAdmin(): boolean {
    return auth.getUserRoleNames.includes(
      ACCESS_SETTINGS_ROLES.COMPANY_SETTINGS
    )
  }

  get subTab(): string {
    return this.isAdmin ? this.$route.name : 'settings.profile'
  }

  get component(): VueComponent {
    switch (this.subTab) {
      case 'settings.general':
        return SettingsGeneral
      case 'settings.rates':
        return SettingsVehicleRates
      case 'settings.users':
        return SettingsUsers
      case 'settings.charges':
        return SettingsCustomCharges
      case 'settings.driver-pay':
        return SettingsDriverPay
      case 'settings.garages':
        return SettingsGarages
      case 'settings.saved-stops':
        return SettingsCompanyStops
      case 'settings.industries':
        return SettingsIndustries
      case 'settings.templates':
        return SettingsTemplates
      case 'settings.integrations':
        return SettingsIntegrations
      case 'settings.profile':
        return SettingsMyProfile
      default:
        return this.isAdmin ? SettingsGeneral : SettingsMyProfile
    }
  }

  mounted(): void {
    try {
      this.loadPaymentGateways()
    } catch (e) {
      console.log(e)
    }
  }

  async loadPaymentGateways(): Promise<void> {
    const allGatewaysRes = await payments.getAllPaymentGateways()
    this.paymentGateways = allGatewaysRes?.data?.paymentGateways || []
    this.defaultPaymentGateway =
      this.paymentGateways.find((g) => g.defaultGateway) || null
  }

  shouldDisplayNewLabel(link: { text: string; name: string }): boolean {
    const shouldDisplay =
      link.name === 'settings.integrations' &&
      this.paymentGateways.length > 1 &&
      this.paymentGateways.some(
        (g) => g.paymentGatewayTypeKey === 'busify_pay' && !g.defaultGateway
      )
    return shouldDisplay
  }
}
