
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import CUSidebarContent from '@/layouts/CUSidebarContent.vue'
import CUSidebarContentWithFooterButtons from '@/components/CUSidebarContentWithFooterButtons.vue'
import { RateType } from '@/models/DriverPay'
import sidebar from '@/store/modules/sidebar'
import CUCheckboxCircular from './CUCheckboxCircular.vue'
import { baseRateCalculationTypes } from '@/data/baseRateCalculationTypes'

@Component({
  components: {
    CUSidebarContent,
    CUSidebarContentWithFooterButtons,
    CUCheckboxCircular,
  },
})
export default class RateTypeDetails extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  readonly initialRateType!: Partial<RateType>

  baseRateCalculationTypes = baseRateCalculationTypes

  errors = {
    label: '',
    baseRateKey: '',
  }

  rateType: RateType = {
    id: null,
    isActive: true,
    label: '',
    baseRateKey: '',
    isDefault: false,
    isHidden: false,
    orderIndex: 0,
  }

  get isEditing(): boolean {
    return !!this.initialRateType.id
  }

  async created(): Promise<void> {
    if (this.isEditing) {
      this.rateType = { ...(this.initialRateType as RateType) }
    }
  }

  @Watch('rateType.label')
  labelChanged(): void {
    this.errors.label = ''
  }

  cancel(): void {
    sidebar.pop()
  }

  selectBaseCalculation(key: string): void {
    this.errors.baseRateKey = ''
    this.rateType.baseRateKey = key
  }

  saveRateType(): void {
    if (!this.rateType.label) {
      this.errors.label = 'Rate Type is required'
    }
    if (!this.rateType.baseRateKey) {
      this.errors.baseRateKey = 'Rate Base Calculation is required'
    }

    if (!this.rateType.label || !this.rateType.baseRateKey) {
      return
    }
    this.$emit(
      this.isEditing ? 'update-rate-type' : 'add-rate-type',
      this.rateType
    )
    sidebar.pop()
  }
}
