
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class CUCheckboxCircular extends Vue {
  @Prop({ required: false, default: false }) readonly value!: boolean
  @Prop({ required: false, default: '' }) readonly label!: string
  @Prop({ type: Boolean, default: false }) readonly readonly!: boolean
  @Prop({ type: String, default: 'checkbox' }) readonly type!:
    | 'checkbox'
    | 'radio'

  handleToggle(): void {
    if (!this.readonly) {
      this.$emit('input', !this.value)
    }
  }
}
