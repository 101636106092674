
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Company } from '@/models/dto/Company'
import companyService from '@/services/company'
import auth from '@/store/modules/auth'
import { NotificationsSetting } from '@/models/NotificationsSetting'
import app from '@/store/modules/app'

@Component({})
export default class SettingsGeneralDefaultsNotifications extends Vue {
  @Prop({ required: true }) readonly company!: Partial<Company>
  @Prop({ required: true, type: Boolean }) readonly hasModifiedCompany!: boolean
  loading = false
  notificationsEdited = false
  quoteNotificationsEdited = false

  get companySettings(): NotificationsSetting {
    return {
      defaultSendDriverInfoEmail: this.defaultSendDriverInfoEmail,
      defaultSendDriverOnTheWayEmail: this.defaultSendDriverOnTheWayEmail,
      defaultSendDriverAppNotification: this.defaultSendDriverAppNotification,
      defaultSendDriverAssignedEmail: this.defaultSendDriverAssignedEmail,
    }
  }

  get sendCheckoutConvertEmail(): boolean {
    return this.company.sendCheckoutConvertEmail || false
  }

  get sendManualConvertEmail(): boolean {
    return this.company.sendManualConvertEmail || false
  }

  get defaultSendDriverInfoEmail(): boolean {
    return this.company.notificationsSetting?.defaultSendDriverInfoEmail ?? true
  }

  get defaultSendDriverOnTheWayEmail(): boolean {
    return (
      this.company.notificationsSetting?.defaultSendDriverOnTheWayEmail ?? true
    )
  }

  // If this is set to true, or it's nullish, have this defaulted to on
  get defaultSendDriverAppNotification(): boolean {
    return (
      this.company.notificationsSetting?.defaultSendDriverAppNotification !==
      false
    )
  }

  // Same as above
  get defaultSendDriverAssignedEmail(): boolean {
    return (
      this.company.notificationsSetting?.defaultSendDriverAssignedEmail !==
      false
    )
  }

  handleUpdateCompanyNotificationsSetting(
    notificationsSetting: NotificationsSetting
  ): void {
    this.$emit('updateCompanyPartial', {
      notificationsSetting,
    })

    this.notificationsEdited = true
  }

  handleUpdateTripAssignmentAppNotification(value: boolean): void {
    const notificationsSetting: NotificationsSetting = {
      ...this.company.notificationsSetting,
      defaultSendDriverAppNotification: value,
    }

    this.$emit('updateCompanyPartial', {
      notificationsSetting,
    })

    this.notificationsEdited = true
  }

  handleUpdateTripAssignmentEmailNotification(value: boolean): void {
    const notificationsSetting: NotificationsSetting = {
      ...this.company.notificationsSetting,
      defaultSendDriverAssignedEmail: value,
    }
    this.$emit('updateCompanyPartial', {
      notificationsSetting,
    })

    this.notificationsEdited = true
  }

  handleUpdateCompanyNotification(notification: Partial<Company>): void {
    this.$emit('updateCompanyPartial', notification)
    this.notificationsEdited = true
    this.quoteNotificationsEdited = true
  }

  async updateCompanyNotificationsSetting(): Promise<void> {
    try {
      this.loading = true
      await companyService.updateNotificationsSetting(
        auth.getCompanyId,
        this.companySettings
      )
      if (this.quoteNotificationsEdited) {
        this.$emit('save')
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.loading = false
      this.notificationsEdited = false
    }
  }
}
