
import sidebar from '@/store/modules/sidebar'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { SettingsIntegrationBlockDetail } from '@/models/PaymentGateway'
import SettingsIntegrationsSidebarHeader from './SettingsIntegrationsSidebarHeader.vue'
import { validationRules, validateRules } from '@/utils/rules'
import { CharterUpApiCredential } from '@/models/dto/CharterUp'
import client from '@/services/charterup'
import { EventBus } from '@/utils/eventBus'
import messages from '@/data/messages'

@Component({ components: { SettingsIntegrationsSidebarHeader } })
export default class SettingsIntegrationsCharterUp extends Vue {
  @Prop({ required: false, type: Boolean }) isInstalled!: boolean
  @Prop({ required: false }) integrationDetail: SettingsIntegrationBlockDetail
  form: CharterUpApiCredential = new CharterUpApiCredential()
  rules = validationRules

  async submit(): Promise<void> {
    if (!(await validateRules(this))) {
      return
    }

    try {
      await client.addCharterUpIntegration(this.form)
      EventBus.$emit('snackbar:success', messages.success.charterUpIntegration)
      this.$emit('refresh-integrations')
      sidebar.close()
    } catch (err) {
      EventBus.$emit('snackbar:error', messages.errors.charterUpIntegration)
    }
  }

  close(): void {
    sidebar.pop()
  }

  async mounted(): Promise<void> {
    try {
      const res = await client.getCharterUpIntegration()
      const { clientId } = res.data.data
      this.form = new CharterUpApiCredential({ clientId })
    } catch (err) {
      this.form = new CharterUpApiCredential()
    }
  }
}
