
import { isValidHexCode } from '@/utils/string'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class SettingsGeneralBrandingPreview extends Vue {
  @Prop({ required: true, default: null }) readonly primaryColor!: string
  @Prop({ required: true, default: null }) readonly secondaryColor!: string
  @Prop({ required: true, default: null }) readonly logoUrl!: string

  isValidHexCode = isValidHexCode

  get lightenedColor(): string {
    if (!isValidHexCode(this.secondaryColor)) {
      return 'light-gray-2'
    }
    return this.secondaryColor.includes('#')
      ? `${this.secondaryColor}1a`
      : this.secondaryColor
  }

  get stopNumberCircleColor(): string {
    return isValidHexCode(this.secondaryColor) ? this.secondaryColor : 'black'
  }
}
