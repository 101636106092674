var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"w-full"},[_c('div',{staticClass:"position-relative d-flex align-center justify-space-between w-full"},[(_vm.logoUrl)?_c('img',{staticClass:"z-5",attrs:{"src":_vm.logoUrl,"height":"34px"}}):_c('span'),_c('p',{staticClass:"d-flex align-center font-12 font-bold position-absolute top-0 left-one-half",style:({ transform: 'translateX(-50%)', height: '34px' })},[_vm._v(" SAMPLE ")]),_c('div',{staticClass:"d-flex border-radius-6 align-center padding-x-2 h-28 font-12 font-bold",style:({ backgroundColor: `${_vm.primaryColor}` })},[_c('CUIcon',{staticClass:"padding-r-1",style:({ width: '20px' }),attrs:{"color":"white"}},[_vm._v(" phone ")]),_c('span',{staticClass:"text-white"},[_vm._v("Company Phone")])],1)]),_c('div',{staticClass:"w-full margin-t-6 border-1 border-solid border-bg-gray-1 border-radius-5 overflow-hidden",style:({
      height: '373px',
    })},[_c('div',{staticClass:"font-12 font-bold d-flex align-center padding-l-2 background-bg-gray-1",style:({
        height: '30px',
        color: `${_vm.primaryColor}`,
        borderBottom: `1px solid ${_vm.primaryColor}`,
      })},[_vm._v(" SAMPLE HEADER ")]),_c('div',{staticClass:"margin-y-6 margin-x-7"},[_c('div',{staticClass:"d-flex align-top"},[_c('div',{staticClass:"d-flex align-center justify-center flex-shrink-0 margin-r-6 w-20 h-20 rounded-circle",style:({ backgroundColor: _vm.lightenedColor })},[_c('p',{staticClass:"font-12 font-bold"},[_vm._v("1")])]),_c('div',{staticClass:"d-flex justify-space-between flex-grow-1"},[_vm._m(0),_c('div',{staticClass:"margin-l-4 flex-shrink-0 w-120"},[_c('p',{staticClass:"font-12 font-bold leading-none",style:({
                color: _vm.stopNumberCircleColor,
              })},[_vm._v(" Pickup → Depart ")]),_c('p',{staticClass:"font-12"},[_vm._v("Mon, 2/4/22")]),_c('p',{staticClass:"font-12"},[_vm._v("8:00am → 9:00am")])])])]),_c('div',{staticClass:"d-flex align-top margin-t-6"},[_c('div',{staticClass:"d-flex align-center justify-center flex-shrink-0 margin-r-6 w-20 h-20 rounded-circle",style:({ backgroundColor: _vm.lightenedColor })},[_c('p',{staticClass:"font-12 font-bold"},[_vm._v("1")])]),_c('div',{staticClass:"d-flex justify-space-between flex-grow-1"},[_vm._m(1),_c('div',{staticClass:"margin-l-4 flex-shrink-0 w-120"},[_c('p',{staticClass:"font-12 font-bold leading-none",style:({
                color: _vm.stopNumberCircleColor,
              })},[_vm._v(" Dropoff ")]),_c('p',{staticClass:"font-12"},[_vm._v("Mon, 2/4/22")]),_c('p',{staticClass:"font-12"},[_vm._v("9:30am")])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex justify-start flex-column flex-grow-1"},[_c('h4',{staticClass:"font-12 font-semibold leading-none"},[_vm._v("Stop Name")]),_c('p',{staticClass:"font-12 margin-t-1 leading-none"},[_vm._v(" 40905 South Main Street, Washington, DC 20002 ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex justify-start flex-column"},[_c('h4',{staticClass:"font-12 font-semibold leading-none"},[_vm._v("Stop Name")]),_c('p',{staticClass:"font-12 margin-t-1 leading-none"},[_vm._v(" 409 H St NE, Washington, DC 20002 ")])])
}]

export { render, staticRenderFns }