
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import sidebar from '@/store/modules/sidebar'
import usersService from '@/services/user'
import CreateUserSidebar from './CreateUserSidebar.vue'
import { TableAction } from '@/models/TableAction'
import { DataTableColumn } from '@/models/DataTableColumn'
import { UserDetail } from '@/models/dto'
import CUCollectionTable from './CUCollectionTable.vue'
import { EventBus } from '@/utils/eventBus'
import EditUserSidebar from './EditUserSidebar.vue'
import auth from '@/store/modules/auth'
import { formatFullName } from '@/utils/string'
import { UserGroupIds } from '@/data/userGroups'

@Component({ components: { CUCollectionTable } })
export default class SettingsUsersList extends mixins(DateMixin) {
  tableView = usersService.tableView
  tableItemCount = 0

  search = ''

  refresh(): void {
    sidebar.pop()
    EventBus.$emit('refresh-tableview')
  }

  error(term: string): void {
    EventBus.$emit('snackbar:error', `There was an error ${term} the user`)
  }

  createUserSuccess(): void {
    EventBus.$emit('snackbar:success', 'User created successfully!')
  }

  editUserSuccess(): void {
    EventBus.$emit('snackbar:success', 'User edited successfully!')
  }

  handleUpdateCount(count: number): void {
    this.tableItemCount = count
  }

  get canCreateUsers(): boolean {
    return auth.getUser?.groupId === UserGroupIds.ADMIN
  }

  isNonAdmin(): boolean {
    return auth.getUser?.groupId !== UserGroupIds.ADMIN
  }

  isNonAdminTooltip(): string {
    return this.isNonAdmin() && 'Only admins can delete users'
  }

  openCreateUser(): void {
    sidebar.push({
      component: CreateUserSidebar,
      width: 675,
    })
  }

  openUserDetails(user: UserDetail): void {
    const newUser = {
      ...user,
      licenseNumber: '',
      drugTestNumber: '',
      drugTestExpiration: '',
      licenseState: '',
      licenseExpirationDay: null,
      licenseExpirationMonth: null,
      licenseExpirationYear: null,
      driverSupportedVehicles: [],
      driverNotes: [],
      groupName: user.groupName === 'User' ? 'Office User' : user.groupName,
    }

    sidebar.popAllAndPush({
      component: EditUserSidebar,
      props: { user: newUser },
      width: 675,
    })
  }

  async handleDeleteUser(userId: number): Promise<void> {
    await usersService.delete(userId)
    this.refresh()
  }

  columns: DataTableColumn[] = [
    {
      _t_id: '7441655e-7198-4dd1-b743-f3d5b18f5a63',
      text: 'Name',
      value: 'name',
      type: 'link',
      computedText: (row: UserDetail): string => formatFullName(row),
      clickEvent: 'users:handle-select-user',
      filterable: true,
      filterBySearch: true,
      filterProp: 'fullName',
      filterAsIs: true,
      filterType: 'contains',
      sortProp: 'lastName',
    },
    {
      _t_id: 'd19c79f8-cb20-40dc-9b11-f1f8747a8574',
      text: 'Email',
      value: 'email',
      filterBySearch: true,
      filterProp: 'email',
    },
    {
      _t_id: 'eafd5acf-e018-47e0-95e1-c780cae70667',
      text: 'Type',
      value: 'groupName',
      filterable: true,
      sortable: true,
      filterProp: 'groupName',
      filterType: 'contains',
      sortProp: 'groupName',
    },
    {
      _t_id: '22d77ef6-64ca-40c4-bc97-6ab70cb4425b',
      text: 'Created On',
      value: 'createdOn',
      computedText: (row): string =>
        this.formatLongDateLongTime(row.createdOn, {
          tz: auth.getUserTimeZone,
          showTimezone: true,
          showMeridianUpper: true,
          showDot: true,
        }),
      filterable: true,
      sortable: true,
      filterProp: 'createdOn',
      filterType: 'contains',
      sortProp: 'createdOn',
    },
    {
      _t_id: '3353cf2e-f3ba-44ea-b210-7d506e80ad99',
      text: 'Updated On',
      value: 'updatedOn',
      computedText: (row): string =>
        this.formatLongDateLongTime(row.updatedOn, {
          tz: auth.getUserTimeZone,
          showTimezone: true,
          showMeridianUpper: true,
          showDot: true,
        }),
      filterable: true,
      sortable: true,
      filterProp: 'updatedOn',
      filterType: 'contains',
      sortProp: 'updatedOn',
    },
    {
      _t_id: '0da57fe5-474e-4516-8bc1-de78db71a084',
      text: 'Last Login',
      value: 'lastLogin',
      computedText: (row): string =>
        this.formatLongDateLongTime(row.lastLogin, {
          tz: auth.getUserTimeZone,
          showTimezone: true,
          showMeridianUpper: true,
          showDot: true,
        }),
      filterable: true,
      sortable: true,
      filterProp: 'lastLogin',
      filterType: 'contains',
      sortProp: 'lastLogin',
    },
  ]

  actions: TableAction[] = [
    {
      displayText: 'Delete User',
      key: 'delete',
      icon: 'delete',
      isSingleSelectOnly: true,
      tooltip: this.isNonAdminTooltip,
      disabled: this.isNonAdmin,
      action: (rows: UserDetail[]): void => {
        EventBus.$emit('users:delete-user', rows[0].userId)
      },
    },
  ]

  mounted(): void {
    EventBus.$on('users:delete-user', this.handleDeleteUser)
    EventBus.$on('users:handle-select-user', this.openUserDetails)
    EventBus.$on('create-user:error', () => this.error('creating'))
    EventBus.$on('create-user:refresh', this.createUserSuccess)
    EventBus.$on('edit-user:error', () => this.error('editing'))
    EventBus.$on('edit-user:refresh', this.editUserSuccess)
    EventBus.$on('update-user:error', () => this.error('updating'))
  }

  beforeDestroy(): void {
    EventBus.$off('users:delete-user', this.handleDeleteUser)
    EventBus.$off('users:handle-select-user', this.openUserDetails)
    EventBus.$off('create-user:error', this.error)
    EventBus.$off('create-user:refresh', this.createUserSuccess)
    EventBus.$off('edit-user:error', this.error)
    EventBus.$off('edit-user:refresh', this.editUserSuccess)
    EventBus.$off('update-user:error', this.error)
  }
}
