
import { Component, Vue } from 'vue-property-decorator'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import client from '@/services/marketRates'
import { TableAction } from '@/models/TableAction'
import { DataTableColumn } from '@/models/DataTableColumn'
import sidebar from '@/store/modules/sidebar'
import { MarketRateGroup } from '@/models/dto/MarketRate'
import { EventBus } from '@/utils/eventBus'
import VehicleRateForm from '@/components/VehicleRateForm.vue'
import {
  applyToMarketRateGroup,
  toMarketRateGroups,
  toMarketRateRequest,
} from '@/utils/marketRate'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { currencyFilter } from '@/utils/string'
import messages from '@/data/messages'
import CUSnackbar from '@/components/CUSnackbar.vue'
import plural from 'pluralize'

@Component({
  components: { CUSnackbar, CUCollectionTable },
})
export default class VehicleRatesList extends Vue {
  sidebar = sidebar
  messages = messages
  marketRateGroups: MarketRateGroup[] = []
  count = 0

  tableView(
    params: TableViewParameters
  ): Promise<{ data: TableViewResult<MarketRateGroup> }> {
    return client
      .tableView({ ...params, page: -1, pageSize: -1 })
      .then((res) => res.data.resultList)
      .then((marketRates) => marketRates.reduce(toMarketRateGroups, {}))
      .then((groups) => Object.values(groups))
      .then((marketRateGroups) => (this.marketRateGroups = marketRateGroups))
      .then((resultList) => ({
        data: { resultList, count: resultList.length },
      }))
  }

  handleAddRate(): void {
    const marketRateGroups = this.marketRateGroups
    this.sidebar.push({
      title: 'Add Vehicle Rates',
      component: VehicleRateForm,
      props: { marketRateGroups },
      on: { submit: this.submit, cancel: this.cancel },
    })
  }

  handleEditRates(marketRateGroup: MarketRateGroup): void {
    const marketRateGroups = this.marketRateGroups
    this.sidebar.push({
      title: 'Edit Vehicle Rates',
      component: VehicleRateForm,
      props: { marketRateGroup, marketRateGroups },
      on: { update: this.update, cancel: this.cancel },
    })
  }

  handleDeleteRates(groups: MarketRateGroup[]): void {
    const applyFn = (r) => client.delete(r?.marketRateId)
    const promises = groups.flatMap((g) => applyToMarketRateGroup(g, applyFn))
    Promise.all(promises).then(() => EventBus.$emit('refresh-tableview'))
  }

  isConflicting(marketRateGroup: MarketRateGroup): boolean {
    return !!this.marketRateGroups.find(
      ({ vehicleTypeId, vehicleId }) =>
        vehicleTypeId === marketRateGroup.vehicleTypeId &&
        vehicleId === marketRateGroup.vehicleId
    )
  }

  submit(marketRateGroup: MarketRateGroup): void {
    if (this.isConflicting(marketRateGroup)) {
      EventBus.$emit('snackbar:error', messages.errors.conflictingVehicleRate)
      return
    }
    const applyFn = (r) => client.create(toMarketRateRequest(r))
    const promises = applyToMarketRateGroup(marketRateGroup, applyFn)
    Promise.all(promises)
      .then(this.sidebar.close)
      .finally(() => EventBus.$emit('refresh-tableview'))
  }

  update(marketRateGroup: MarketRateGroup): void {
    const applyFn = (r) => client.partialUpdate(toMarketRateRequest(r))
    const promises = applyToMarketRateGroup(marketRateGroup, applyFn)
    Promise.all(promises)
      .then(this.sidebar.close)
      .finally(() => EventBus.$emit('refresh-tableview'))
  }

  cancel(): void {
    this.sidebar.pop()
  }

  actions: TableAction[] = [
    {
      displayText: 'Delete',
      key: 'delete',
      icon: 'delete',
      action: this.handleDeleteRates,
    },
  ]

  columns: DataTableColumn[] = [
    {
      _t_id: 'FE5AF50B-170A-4B4B-9EFE-F399E2D8AF4D',
      text: 'ID',
      value: 'id',
      type: 'link',
      linkHandler: this.handleEditRates,
    },
    {
      _t_id: 'D93430F8-5F3E-4CFE-8491-3130B123DDAC',
      text: 'Type',
      value: 'vehicleType',
    },
    {
      _t_id: '2EFD4970-E1B9-4C52-92B2-2F9B77C8FA26',
      text: 'Vehicle',
      value: '',
      computedText: (row: MarketRateGroup): string =>
        row.vehicleName || 'Default',
    },
    {
      _t_id: 'DF7CD526-2B47-4483-8FA5-426E9604D2E8',
      text: 'Live Mile',
      value: 'liveMile',
      computedText: (row: MarketRateGroup): string =>
        currencyFilter(row.liveMileRate.highRate),
    },
    {
      _t_id: '1D2D25FD-4CD6-4363-8B04-CBB9E6049CF3',
      text: 'Dead Mile',
      value: 'deadMile',
      computedText: (row: MarketRateGroup): string =>
        currencyFilter(row.deadMileRate.highRate),
    },
    {
      _t_id: '7CE976C3-6431-4321-941A-771E92DA0A43',
      text: 'Hourly',
      value: 'hourly',
      computedText: (row: MarketRateGroup): string =>
        currencyFilter(row.hourlyRate.highRate),
    },
    {
      _t_id: '9432b54f-392c-432c-81ca-de53850081cb',
      text: 'Min Hours',
      value: 'hourlyMinimum',
      computedText: (row: MarketRateGroup): string =>
        row.hourlyMinimum
          ? `${row.hourlyMinimum} ${plural('hour', row.hourlyMinimum)}`
          : null,
    },
    {
      _t_id: '9D4F8940-0136-4805-903A-FEF38A1F4F7F',
      text: 'Daily',
      value: 'daily',
      computedText: (row: MarketRateGroup): string =>
        currencyFilter(row.dailyRate.highRate),
    },
  ]
}
