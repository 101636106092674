import { SocialLink, SocialLinkTableViewResult } from '@/models/dto/Company'
import { TableViewParameters } from '@/models/TableView'
import { apiBaseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from './common/HttpService'

const httpService: HttpService = new HttpService()

export default {
  tableView(
    companyId: number,
    params: TableViewParameters
  ): Promise<AxiosResponse<SocialLinkTableViewResult>> {
    const { sorts = null, filters = null, pageSize = 10, page = 1 } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }

    if (filters) {
      query += `&${filters}`
    }

    query = encodeURI(query)
    const host = apiBaseUrl()
    const url = `https://${host}/tables/companies/${companyId}/social-links?${query}`
    return httpService.get(url)
  },
  getSocialLink(linkId: number): Promise<AxiosResponse<SocialLink>> {
    const host = apiBaseUrl()
    const url = `https://${host}/v2/social-links/${linkId}`

    return httpService.get(url)
  },
  modifySocialLink(
    linkId: number,
    link: SocialLink
  ): Promise<AxiosResponse<void>> {
    const host = apiBaseUrl()
    const url = `https://${host}/v2/social-links/${linkId}`

    return httpService.put(url, link)
  },
  addSocialLink(
    companyId: number,
    links: SocialLink[]
  ): Promise<AxiosResponse<void>> {
    const host = apiBaseUrl()
    const url = `https://${host}/v2/companies/${companyId}/socialLinks`

    return httpService.post(url, links)
  },
  deleteSocialLink(typeKey: string): Promise<AxiosResponse<void>> {
    const host = apiBaseUrl()
    const url = `https://${host}/v2/social-links/${typeKey}`

    return httpService.delete(url)
  },
}

function op(data: any, arg1: string) {
  throw new Error('Function not implemented.')
}
