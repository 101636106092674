
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import rate from '@/services/rate'
import { SimpleTableColumn } from '@/models/SimpleTableColumn'
import {
  DefaultCompanyPaymentMethod,
  PaymentMethod,
  PaymentMethodKeys,
} from '@/models/dto'
import PaymentLinkCell from '@/components/PaymentLinkCell.vue'
import { Company } from '@/models/dto/Company'
import deepClone from '@/utils/deepClone'
import type from '@/services/type'
import payments from '@/services/payments'
import app from '@/store/modules/app'

@Component({})
export default class SettingsGeneralDefaultsPayment extends Vue {
  @Prop({ required: true }) readonly company!: Partial<Company>
  @Prop({ required: true, type: Boolean }) readonly hasModifiedCompany!: boolean
  @Prop({ required: true, type: Boolean }) readonly loading!: boolean

  app = app
  showOverageReset = false
  overageRateTypes = []
  expirationTypes = []
  selectedDefaultCompanyPaymentMethods = []
  paymentGateways = []
  fromDateItems = [
    { text: 'Before pickup', value: true },
    { text: 'After pickup', value: false },
  ]
  expirationFirstItems = [
    { text: 'First', value: true },
    { text: 'Last', value: false },
  ]
  defaultCompanyPaymentMethodHeaders: SimpleTableColumn<DefaultCompanyPaymentMethod>[] =
    [
      {
        text: 'Method',
        value: 'label',
        type: 'component',
        component: PaymentLinkCell,
        width: 120,
      },
      {
        text: 'Online Processing',
        value: '',
        type: 'text',
        placeholder: '--',
        width: 140,
        formatter: (row: DefaultCompanyPaymentMethod): string =>
          this.getGatewayStatus(row) ? 'Enabled' : '',
      },
      {
        text: 'Processing Fee',
        value: 'processingFee',
        type: 'number',
        valueType: 'percent',
        width: 118,
        editable: true,
        reverse: true,
        placeholder: '%',
      },
      {
        text: 'Payment Note and Instructions for Customer',
        value: 'note',
        type: 'text',
        editable: true,
        placeholder:
          'Include details per payment method on how to pay (e.g. wire account number, Venmo username)',
      },
    ]

  get defaultCompanyPaymentMethodItems(): DefaultCompanyPaymentMethod[] {
    return this.company.defaultCompanyPaymentMethods
  }
  // Watchers
  @Watch('company.defaultCompanyPaymentMethods', { immediate: true })
  onDefaultCompanyPaymentMethodsUpdate(
    newCompanyPaymentMethods: DefaultCompanyPaymentMethod[]
  ): void {
    this.selectedDefaultCompanyPaymentMethods = newCompanyPaymentMethods.filter(
      ({ isActive }) => isActive
    )
  }

  getGatewayStatus(paymentMethod: DefaultCompanyPaymentMethod): boolean {
    switch (paymentMethod.paymentMethodType.key) {
      case PaymentMethodKeys.CREDIT_CARD:
        return !!this.paymentGateways.find(
          (gateway) =>
            gateway.paymentGatewayTypeKey === 'auth_net' ||
            gateway.paymentGatewayTypeKey === 'square'
        )
      case PaymentMethodKeys.ACH:
        return false
      case PaymentMethodKeys.CHECK:
        return false
      case PaymentMethodKeys.WIRE:
        return false
      case PaymentMethodKeys.OTHER:
        return false
      default:
        return false
    }
  }

  // Update methods
  handleDefaultPercentageDueNowChange(event: number): void {
    const defaultPercentageDueNow =
      event == null ? event : Number(Number(event).toFixed(1))
    this.$emit('updateCompanyPartial', { defaultPercentageDueNow })
  }

  handleUpdateDefaultDueFromDate(defaultDueBeforePickup: string): void {
    this.$emit('updateCompanyPartial', { defaultDueBeforePickup })
  }

  handleUpdateDefaultDueDaysFromDate(defaultDueDaysFromDate: string): void {
    this.$emit('updateCompanyPartial', { defaultDueDaysFromDate })
  }

  handleResetOverageRate(): void {
    this.$emit('updateCompanyPartial', {
      defaultOverageRateAmount: null,
      defaultOverageRateTypeId: null,
      defaultOverageRateType: null,
    })
  }

  handleUpdateAllowedPaymentMethod(
    activePaymentMethods: PaymentMethod[]
  ): void {
    const defaultCompanyPaymentMethods = deepClone(
      this.company.defaultCompanyPaymentMethods
    ).map((defaultCompanyPaymentMethod) => {
      const isActive = !!activePaymentMethods.find(
        ({ paymentMethodTypeId }) =>
          paymentMethodTypeId ===
          defaultCompanyPaymentMethod.paymentMethodTypeId
      )
      return {
        ...defaultCompanyPaymentMethod,
        isActive,
      }
    })
    this.$emit('updateCompanyPartial', { defaultCompanyPaymentMethods })
  }

  handleUpdatePaymentMethodInputs(
    updatedPaymentMethod: DefaultCompanyPaymentMethod
  ): void {
    const defaultCompanyPaymentMethods = deepClone(
      this.company.defaultCompanyPaymentMethods
    )
    const companyIndex = defaultCompanyPaymentMethods.findIndex(
      ({ paymentMethodTypeId }) =>
        paymentMethodTypeId === updatedPaymentMethod.paymentMethodTypeId
    )
    if (companyIndex > -1) {
      defaultCompanyPaymentMethods.splice(companyIndex, 1, updatedPaymentMethod)
    }

    this.$emit('updateCompanyPartial', {
      defaultCompanyPaymentMethods,
    })
  }

  handleUpdateEnablePONumber(defaultEnablePONumber: boolean): void {
    if (defaultEnablePONumber) {
      this.handleUpdateAllowedPaymentMethod([])
      this.handleUpdateDefaultEnablePayLater(false)
    }
    this.$emit('updateCompanyPartial', { defaultEnablePONumber })
  }

  handleUpdateDefaultPaymentPolicy(defaultPaymentPolicy: string): void {
    this.$emit('updateCompanyPartial', { defaultPaymentPolicy })
  }

  handleUpdateDefaultOverageRateType(defaultOverageRateTypeId: number): void {
    this.$emit('updateCompanyPartial', { defaultOverageRateTypeId })
  }

  handleUpdateDefaultOverageRateAmount(event: string): void {
    const defaultOverageRateAmount = Number(event)
    this.$emit('updateCompanyPartial', { defaultOverageRateAmount })
  }

  handleUpdateDefaultEnablePayLater(defaultEnablePayLater: boolean): void {
    this.$emit('updateCompanyPartial', { defaultEnablePayLater })
  }

  handleUpdateEnablePayFullAmountNow(
    defaultEnablePayFullAmountNow: boolean
  ): void {
    this.$emit('updateCompanyPartial', { defaultEnablePayFullAmountNow })
  }

  handleUpdateDefaultEnableExpiration(defaultEnableExpiration: boolean): void {
    this.$emit('updateCompanyPartial', { defaultEnableExpiration })
  }

  handleUpdateDefaultExpirationType(defaultExpirationType: number): void {
    this.$emit('updateCompanyPartial', { defaultExpirationType })
  }

  handleUpdateDefaultExpireAfterFirstSend(isAfterFirst: boolean): void {
    this.$emit('updateCompanyPartial', {
      defaultExpireAfterFirstSend: isAfterFirst,
    })
  }

  handleUpdateDefaultRequireSignatureUponCheckout(
    requireSignatureUponCheckout: boolean
  ): void {
    this.$emit('updateCompanyPartial', {
      defaultRequireSignatureUponCheckout: requireSignatureUponCheckout,
    })
  }

  handleUpdateDefaultExpirationDate(defaultExpirationDate: number): void {
    this.$emit('updateCompanyPartial', {
      defaultExpirationDate: defaultExpirationDate
        ? Number(Number(defaultExpirationDate).toFixed())
        : null,
    })
  }

  async loadRateTypes(): Promise<void> {
    const res = await rate.getTypes()
    this.overageRateTypes = res.data
  }

  async loadExpirationTypes(): Promise<void> {
    const res = await type.expiration()
    this.expirationTypes = res.data
  }

  async loadPaymentGateways(): Promise<void> {
    const allGatewaysRes = await payments.getAllPaymentGateways()
    this.paymentGateways = allGatewaysRes?.data?.paymentGateways || []
  }

  async mounted(): Promise<void> {
    await Promise.all([
      this.loadRateTypes(),
      this.loadPaymentGateways(),
      this.loadExpirationTypes(),
    ])
  }
}
