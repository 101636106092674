var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"h-full"},[_c('ContentWithSidebar',{attrs:{"show-sidebar":"","sidebar-width":"240","content-padding":"padding-y-6 padding-x-10"},scopedSlots:_vm._u([{key:"sidebar",fn:function(){return [_c('div',{staticClass:"padding-x-5"},[_c('h3',{staticClass:"h3-bold margin-b-10 margin-t-5"},[_vm._v("Settings")]),(_vm.isAdmin)?[_c('h4',{staticClass:"body-bold text-text-gray-3"},[_vm._v("My Company")]),_c('v-list',_vm._l((_vm.links),function(link,linkIndex){return _c('v-list-item',{key:`settings-link-${link.text}-${linkIndex}`,staticClass:"body-2-regular border-radius-10",class:{
                'background-primary-orange-3': link.name === _vm.subTab,
              }},[_c('div',{staticClass:"d-flex align-center"},[_c('router-link',{staticClass:"w-full d-flex",class:{
                    'font-bold text-orange': link.name === _vm.subTab,
                    'text-text-dark-gray': link.name !== _vm.subTab,
                  },attrs:{"to":{ name: link.name }}},[_vm._v(" "+_vm._s(link.text)+" "),(link.isNew)?_c('div',{staticClass:"margin-l-2 background-additional-blue-2 d-flex align-center justify-center padding-x-2 border-radius-10"},[_c('p',{staticClass:"font-10 text-additional-blue leading-16",style:({ fontWeight: '900' })},[_vm._v(" NEW ")])]):_vm._e()]),(_vm.shouldDisplayNewLabel(link))?_c('div',{staticClass:"ml-2"},[_c('div',{staticClass:"background-additional-blue-2 d-flex h-20 align-center justify-center padding-x-2 border-radius-10"},[_c('p',{staticClass:"font-10 text-additional-blue leading-16",style:({ fontWeight: '900' })},[_vm._v(" NEW ")])])]):_vm._e()],1)])}),1),_c('div',{staticClass:"w-full border-t-1 border-solid border-bg-gray-3 border-b-0 border-x-0 border-radius-10"})]:_vm._e(),_c('div',{staticClass:"margin-t-2 padding-y-3 padding-l-2 border-radius-10 body-2-regular",class:{
            'background-primary-orange-3': _vm.subTab === 'settings.profile',
          }},[_c('router-link',{staticClass:"w-full",attrs:{"to":{ name: 'settings.profile' }}},[_c('h4',{staticClass:"font-16 leading-24",class:{
                'font-bold text-orange': _vm.subTab === 'settings.profile',
                'body-bold text-text-gray-3': _vm.subTab !== 'settings.profile',
              }},[_vm._v(" My Profile ")])])],1)],2)]},proxy:true},{key:"content",fn:function(){return [_c(_vm.component,_vm._b({tag:"component"},'component',_vm.$props,false))]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }