
import { Vue, Component, Prop } from 'vue-property-decorator'
import quickbooksService from '@/services/quickbooks'
import { EventBus } from '@/utils/eventBus'
import { CompanyQuickbooksSettings, QuickbooksTaxCodeEntry } from '@/models/dto/Quickbooks'
import auth from '@/store/modules/auth'
import HoldUpModal from '@/components/HoldUpModal.vue'

@Component({ components: { HoldUpModal } })
export default class QuickbooksCompanySettingsSidebar extends Vue {
  @Prop({ required: false, default: null }) readonly companyId!: number
  @Prop({ required: true }) readonly taxCodes!: QuickbooksTaxCodeEntry[]

  

  isPauseSyncModalOpen = false
  isDeleteIntegrationModalOpen = false

  pauseSyncConfirmationQuestion = `Although you won’t lose any data already in the system, this action will stop all communication from Busify to your QuickBooks account. <br><br> Are you sure you want to <strong>PAUSE</strong> the Synchronization with your QuickBooks Online account?`
  pauseSyncConfirmationText = 'Yes, Pause Sync'
  deleteIntegrationConfirmationQuestion = `This action will <strong>delete the integration</strong> with Quickbooks Online and will not be able to perform any of the actions available trough this integration. <br><br> Are you sure you want to <strong></string><span style="color: #FB7743;">DELETE</span></strong> the integration with QuickBooks Online account?`
  deleteIntegrationConfirmationText = 'Yes, Delete Integration'

  get accountNumber(): string {
    return auth?.getCompanyQuickBooksSettings?.realmId || ''
  }

  get syncIsActive(): boolean {
    return auth?.getCompanyQuickBooksSettings?.syncing || false
  }

  get automaticInvoiceCreation(): boolean {
    return auth?.getCompanyQuickBooksSettings?.automaticInvoiceCreation || false
  }

  get automaticInvoiceVoid(): boolean {
    return auth?.getCompanyQuickBooksSettings?.automaticInvoiceVoid || false
  }

  get multiTripInvoiceConsolidation(): boolean {
    return (
      auth?.getCompanyQuickBooksSettings?.multiTripInvoiceConsolidation || false
    )
  }

  get askToSyncWhenCreateNewContact(): boolean {
    return (
      auth?.getCompanyQuickBooksSettings?.askToSyncWhenCreateNewContact || false
    )
  }

   get defaultTaxCode(): string {
    return auth?.getCompanyQuickBooksSettings?.defaultTaxCode || null
  }

  toggleSyncIsActive(): void {
    if (this.syncIsActive) {
      this.isPauseSyncModalOpen = true
      return
    }
    const settings: Partial<CompanyQuickbooksSettings> = { syncing: true }
    this.updateCompanySettings(settings)
  }

  setSyncIsActiveOff(): void {
    const settings: Partial<CompanyQuickbooksSettings> = { syncing: false }
    this.updateCompanySettings(settings)
  }

  toggleAutomaticInvoiceCreation(toggle: boolean): void {
    const settings: Partial<CompanyQuickbooksSettings> = {
      automaticInvoiceCreation: toggle,
    }
    this.updateCompanySettings(settings)
  }

  toggleAutomaticInvoiceVoid(toggle: boolean): void {
    const settings: Partial<CompanyQuickbooksSettings> = {
      automaticInvoiceVoid: toggle,
    }
    this.updateCompanySettings(settings)
  }

  toggleMultiTripInvoiceConsolidation(toggle: boolean): void {
    const settings: Partial<CompanyQuickbooksSettings> = {
      multiTripInvoiceConsolidation: toggle,
    }
    this.updateCompanySettings(settings)
  }

  toggleAskToSyncWhenCreateNewContact(toggle: boolean): void {
    const settings: Partial<CompanyQuickbooksSettings> = {
      askToSyncWhenCreateNewContact: toggle,
    }
    this.updateCompanySettings(settings)
  }

  updateDefaultTaxCode(defaultTaxCode: string): void {
    const settings: Partial<CompanyQuickbooksSettings> = { defaultTaxCode }
    this.updateCompanySettings(settings)
  }

  async updateCompanySettings(
    settings: Partial<CompanyQuickbooksSettings>
  ): Promise<void> {
    const quickbooksSettings = {
      ...auth.getCompanyQuickBooksSettings,
      ...settings,
    }
    auth.setCompanyQuickBooksSettings(quickbooksSettings)
    try {
      const response = await quickbooksService.patchCompanyQuickbooksSettings(
        this.companyId,
        settings
      )
      EventBus.$emit('snackbar:success', 'Settings saved successfully')
      const companySettings = response.data.companySettings
      auth.setCompanyQuickBooksSettings(companySettings)
    } catch (e) {
      console.log(e)
      EventBus.$emit(
        'snackbar:error',
        'There was an error authenticating your Quickbooks Account'
      )
    }
  }

  confirmDeleteQuickbookIntegration(): void {
    this.isDeleteIntegrationModalOpen = true
  }

  async deleteQuickbookIntegration(): Promise<void> {
    try {
      await quickbooksService.deleteQuickbooksIntegration(this.companyId)
      EventBus.$emit('snackbar:success', 'QuickBooks delete sync successful')
      await auth.getCompanyQuickBooksDetail()
    } catch (e) {
      console.log(e)
      EventBus.$emit(
        'snackbar:error',
        'There was an error deleting your Quickbooks Integration'
      )
    }
  }
}
