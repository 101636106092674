
import { EventType } from '@/models/EventType'
import { Vue, Component, Prop } from 'vue-property-decorator'
import CULabelField from './CULabelField.vue'
import EventTypesSidebarItem from '@/components/EventTypesSidebarItem.vue'
import deepClone from '@/utils/deepClone'
import draggable from 'vuedraggable'
import company from '@/services/company'
import auth from '@/store/modules/auth'
import { EventBus } from '@/utils/eventBus'
import sidebar from '@/store/modules/sidebar'

@Component({
  components: { EventTypesSidebarItem, draggable, CULabelField },
})
export default class EventTypesSidebar extends Vue {
  @Prop({ required: true }) readonly eventTypes!: EventType[]

  model = deepClone(this.eventTypes).sort((a, b) =>
    a.orderIndex > b.orderIndex ? 1 : -1
  )
  activeIndex = null
  newEventTypeInput = ''
  loading = false

  get companyId(): number {
    return auth.user?.companyId
  }

  start(e: { oldIndex }): void {
    this.activeIndex = e.oldIndex
  }

  end(): void {
    this.activeIndex = null
  }

  add(newEventType: string): void {
    if (!newEventType) {
      return
    }

    const eventType: EventType = {
      id: null,
      companyId: null,
      label: newEventType,
      orderIndex: this.model.length,
    }

    this.model.push(eventType)
    this.newEventTypeInput = ''
  }

  remove(index: number): void {
    this.model.splice(index, 1)
  }

  async apply(): Promise<void> {
    this.loading = true

    // Update each orderIndex to match the current order
    this.model = this.model.map((eventType, index) => ({
      ...eventType,
      orderIndex: index,
    }))

    try {
      await company.updateEventTypes(this.companyId, this.model)
      EventBus.$emit('company:refresh-event-types')
      sidebar.close()
    } catch (e: any) {
      const errorMessage =
        e?.response?.data?.message || 'Error updating event types'
      EventBus.$emit('snackbar:error', errorMessage)
    } finally {
      this.loading = false
    }
  }

  cancel(): void {
    sidebar.pop()
  }
}
