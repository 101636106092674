
import { Component, Vue } from 'vue-property-decorator'
import { DataTableColumn } from '@/models/DataTableColumn'
import { EventBus } from '@/utils/eventBus'
import { Garage } from '@/models/dto/Garage'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import GarageForm from '@/components/GarageForm.vue'
import client from '@/services/garage'
import sidebar from '@/store/modules/sidebar'
import { TableAction } from '@/models/TableAction'
import HoldUpModal from '@/components/HoldUpModal.vue'
import messages from '@/data/messages'

@Component({
  components: {
    GarageForm,
    CUCollectionTable,
    HoldUpModal,
  },
  metaInfo: { title: 'Garages' },
})
export default class GaragesList extends Vue {
  tableView = client.tableView
  tableItemCount = 0
  search = ''
  selectedGarages: Garage[] = []
  isModalOpen = false

  handleRefresh(): void {
    sidebar.pop()
    EventBus.$emit('refresh-tableview')
  }

  handleError(err: string): void {
    EventBus.$emit('snackbar:error', err)
  }

  handleUpdateCount(count: number): void {
    this.tableItemCount = count
  }

  handleAddGarage(): void {
    sidebar.push({
      title: 'Add Garage',
      component: GarageForm,
    })
  }

  handleSelectGarage(garage: Garage): void {
    sidebar.popAllAndPush({
      title: 'Garage',
      component: GarageForm,
      props: { garage },
    })
  }

  handleDeleteGarages(garages: Garage[]): void {
    const promises = garages.map(({ garageId }) => garageId).map(client.delete)
    Promise.all(promises)
      .then(() => EventBus.$emit('refresh-tableview'))
      .then(() =>
        EventBus.$emit('snackbar:success', messages.success.garageDeleted)
      )
      .finally(() => (this.selectedGarages = []))
  }

  deleteGarages(garages: Garage[]): void {
    this.isModalOpen = true
    this.selectedGarages = garages
  }

  actions: TableAction[] = [
    {
      displayText: 'Delete',
      key: 'delete',
      icon: 'delete',
      action: this.deleteGarages,
    },
  ]

  columns: DataTableColumn[] = [
    {
      _t_id: 'dc7aaac5-6b04-44d9-83ec-727f9053e552',
      text: 'Name',
      value: 'garageName',
      type: 'link',
      clickEvent: 'garages:handle-select-garage',
    },
    {
      _t_id: '55ed631d-0f08-4cb9-af28-545d3ce9ac4d',
      text: 'Address',
      value: 'address/addressName',
    },
    {
      _t_id: '279994bb-8a48-4115-b9d5-055ce3bd8069',
      text: '# of Vehicles',
      value: 'noOfVehicles',
    },
  ]

  mounted(): void {
    EventBus.$on('garages:refresh', this.handleRefresh)
    EventBus.$on('garages:error', this.handleError)
    EventBus.$on('garages:handle-select-garage', this.handleSelectGarage)
  }

  beforeDestroy(): void {
    EventBus.$off('garages:refresh', this.handleRefresh)
    EventBus.$off('garages:error', this.handleError)
    EventBus.$off('garages:handle-select-garage', this.handleSelectGarage)
  }
}
