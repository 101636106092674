
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import CUCheckbox from '@/components/CUCheckbox.vue'
import { LineItemChargeTypes, LineItemSectionTypes } from '@/utils/enum'
import CUCurrency from '@/components/CUCurrency.vue'
import { validateRules, validationRules } from '@/utils/rules'
import sidebar from '@/store/modules/sidebar'
import {
  CompanyChargeOption,
  LineItemCharge,
  LineItemChargeType,
  LineItemSectionType,
} from '@/models/dto/LineItemCharge'
import tclient from '@/services/type'

@Component({
  components: { CUCurrency, CUCheckbox },
})
export default class CompanyChargeOptionForm extends Vue {
  @Prop({ required: false }) readonly companyChargeOption: CompanyChargeOption
  @Prop({ required: false, default: 'Custom Charge' }) readonly label!: string
  @Prop({ required: false, default: () => Object.values(LineItemSectionTypes) })
  readonly sections!: LineItemSectionTypes[]

  validation = validationRules

  isSubmitting = false
  form: CompanyChargeOption = new CompanyChargeOption()

  lineItemCharges: LineItemCharge[] = []
  lineItemChargeTypes: LineItemChargeType[] = []
  lineItemSectionTypes: LineItemSectionType[] = []

  isChargeType(type: LineItemChargeTypes): boolean {
    return this.form?.lineItemChargeType?.key === type
  }

  get isTax(): boolean {
    const type: LineItemSectionTypes = LineItemSectionTypes.SUBTOTAL
    return this.sections?.length === 1 && this.sections.includes(type)
  }

  get rate(): number {
    if (this.isChargeType(LineItemChargeTypes.PERCENTAGE)) {
      return this.form?.percentage || null
    }
    return this.form?.rate || null
  }

  get rateInputSign(): string {
    if (!this.rate || !this.form?.lineItemChargeType) {
      return ''
    }
    return this.isChargeType(LineItemChargeTypes.PERCENTAGE) ? '%' : '$'
  }

  handleLineItemChargeTypeChange(lineItemChargeType: LineItemChargeType): void {
    const lineItemChargeTypeId = lineItemChargeType?.id
    this.form = { ...this.form, lineItemChargeType, lineItemChargeTypeId }

    if (this.isChargeType(LineItemChargeTypes.PERCENTAGE) && this.form.rate) {
      this.form = {
        ...this.form,
        percentage: this.form.rate,
        rate: 0,
      }
    } else if (this.form.percentage) {
      this.form = {
        ...this.form,
        rate: this.form.percentage,
        percentage: 0,
      }
    }
  }

  handleLineItemSectionTypeChange(
    lineItemSectionType: LineItemSectionType
  ): void {
    const lineItemSectionTypeId = lineItemSectionType?.id
    this.form = { ...this.form, lineItemSectionType, lineItemSectionTypeId }
  }

  handleRateChange(rate: number): void {
    if (this.isChargeType(LineItemChargeTypes.PERCENTAGE)) {
      this.form = {
        ...this.form,
        percentage: rate,
        rate: 0,
      }
    } else {
      this.form = {
        ...this.form,
        percentage: 0,
        rate,
      }
    }
  }

  submit(): void {
    validateRules(this).then((isValid) => {
      if (isValid) {
        const type = this.form?.lineItemChargeType?.key
        const companyChargeOption: CompanyChargeOption = {
          ...this.form,
          isDefault: !!this.form.isDefault,
          isPerDriver: type === LineItemChargeTypes.PER_DRIVER,
          isPerPassenger: type === LineItemChargeTypes.PER_PASSENGER,
          isPerVehicle: type === LineItemChargeTypes.PER_VEHICLE,
        }
        this.$emit('submit', companyChargeOption)
      }
    })
  }

  cancel(): void {
    sidebar.pop()
  }

  filterSectionTypes(
    lineItemSectionTypes: LineItemSectionType[]
  ): LineItemSectionType[] {
    return (lineItemSectionTypes || []).filter((lineItemSectionType) =>
      this.sections.includes(lineItemSectionType?.key as LineItemSectionTypes)
    )
  }

  initFormChargeTypeForTax(): void {
    if (!this.companyChargeOption && this.isTax) {
      const percentageType = this.lineItemChargeTypes.find((type) => {
        return type.key === LineItemChargeTypes.PERCENTAGE
      })
      this.handleLineItemChargeTypeChange(percentageType)
    }
  }

  initFormSectionTypeForTax(): void {
    if (!this.companyChargeOption && this.isTax) {
      const subtotalType = this.lineItemSectionTypes.find((type) => {
        return type.key === LineItemSectionTypes.SUBTOTAL
      })
      this.handleLineItemSectionTypeChange(subtotalType)
    }
  }

  @Watch('companyChargeOption')
  onCompanyChargeOptionChange(): void {
    if (this.companyChargeOption) {
      this.form = { ...this.companyChargeOption }
    }
  }

  mounted(): void {
    if (this.companyChargeOption) {
      this.form = { ...this.companyChargeOption }
    }

    tclient
      .lineItemChargeTypes()
      .then((res) => res.data)
      .then(
        (types) =>
          (this.lineItemChargeTypes = types.filter(
            (type) =>
              ![
                'per_passenger',
                'per_driver',
                'per_vehicle',
                'per_total_mile',
                'per_live_mile',
                'per_dead_mile',
                'per_hour',
                'per_day',
              ].includes(type.key)
          ))
      )
      .then(this.initFormChargeTypeForTax)

    tclient
      .lineItemSectionTypes()
      .then((res) => res.data)
      .then(this.filterSectionTypes)
      .then((types) => (this.lineItemSectionTypes = types))
      .then(this.initFormSectionTypeForTax)
  }
}
