
import { Vue, Component } from 'vue-property-decorator'
import CUTabs from '@/components/CUTabs.vue'
import QuoteEmailTemplate from './QuoteEmailTemplate.vue'
import InvoiceEmailTemplate from './InvoiceEmailTemplate.vue'

@Component({
  components: {
    CUTabs,
  },
  metaInfo: { title: 'Templates' },
})
export default class SettingsTemplates extends Vue {
  tabs = [
    {
      label: 'Quote',
      hash: 'quote',
      component: QuoteEmailTemplate,
      title: 'Quote Email Template',
    },
    {
      label: 'Invoice',
      hash: 'invoice',
      component: InvoiceEmailTemplate,
      title: 'Invoice Email Template',
    },
  ]
}
