
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Address, Vehicle } from '@/models/dto'
import { Garage, GarageRequest } from '@/models/dto/Garage'
import { rules, validateRules } from '@/utils/rules'
import AutocompleteAddress from '@/components/AutocompleteAddress.vue'
import auth from '@/store/modules/auth'
import client from '@/services/garage'
import CUSimpleTable from '@/components/CUSimpleTable.vue'
import { DataTableHeader } from 'vuetify'
import { EventBus } from '@/utils/eventBus'

@Component({
  components: { CUSimpleTable, AutocompleteAddress },
})
export default class GarageForm extends Vue {
  @Prop({ required: false, default: undefined }) readonly garage: Garage
  auth = auth
  rules = rules

  isModeEdit = false
  submitting = false
  loading = false

  name = ''
  notes = ''
  address: Address = null
  vehicles: Vehicle[] = []

  async submit(): Promise<void> {
    if (!(await validateRules(this)) || !this.address?.addressName) {
      return
    }

    this.submitting = true
    const garageRequest: GarageRequest = {
      address: this.address as Address,
      defaultGarageNotes: this.notes,
      garage: true,
      name: this.name,
      parentCompanyId: this.auth.user.companyId,
    }
    try {
      const request = this.isModeEdit
        ? client.update(garageRequest, this.garage.garageId)
        : client.create(garageRequest)
      await request
      EventBus.$emit('garages:refresh')
    } catch (err) {
      EventBus.$emit('garages:error', err)
    } finally {
      this.submitting = false
    }
  }

  clear(): void {
    this.name = ''
    this.notes = ''
    this.submitting = false
    const form: any = this.$refs.form
    if (form) {
      form.reset()
    }
  }

  headers: DataTableHeader[] = [
    {
      text: 'Vehicle Name',
      align: 'start',
      value: 'vehicleName',
    },
    {
      text: 'Type',
      align: 'start',
      value: 'vehicleTypeName',
    },
    {
      text: 'Capacity',
      align: 'start',
      value: 'passengerCapacity',
    },
  ]

  @Watch('garage', { immediate: true, deep: true })
  async onGarageUpdate(): Promise<void> {
    if (this.garage) {
      this.isModeEdit = true
      this.name = this.garage.garageName
      this.notes = this.garage.defaultGarageNotes
      this.address = this.garage.address

      if (this.garage.garageId) {
        this.loading = true
        const res = await client.byId(this.garage.garageId)
        this.vehicles = res.data?.['vehicleDTOs']
        this.loading = false
      }
    } else {
      this.isModeEdit = false
      this.clear()
    }
  }
}
