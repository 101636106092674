
import { BusifyIntegration } from '@/utils/enum'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class BusifyPaymentFeatures extends Vue {
  busifyTexts = BusifyIntegration
  paymentTiles = [
    {
      icon: 'credit_card_check',
      alt: 'Credit Card with Checkmark',
      name: 'Seamless card processing',
    },
    {
      icon: 'currency_exchange',
      alt: 'Currency Exchange',
      color: 'green',
      name: 'Online Bank processing',
    },
    {
      icon: 'wallet_alt',
      color: 'bg-gray-6',
      alt: 'wallet_alt',
      name: 'Stored payment methods',
    },
    {
      icon: 'radar',
      alt: 'radar',
      color: 'additional-blue',
      name: 'Built in fraud detection',
    },
    {
      icon: 'settings_timelapse',
      alt: 'settings-timelapse',
      color: 'additional-yellow',
      name: 'Fast automated payouts',
    },
    {
      icon: 'gavel',
      alt: 'gavel',
      color: 'driver-pay-blue',
      name: 'Dispute resolution tools',
    },
    {
      icon: 'grid_view',
      alt: 'grid-view',
      color: 'additional-red',
      name: 'Easy to use payments dashboard',
    },
    {
      image: require('@/assets/images/quickbooks_icon.svg'),
      alt: 'group',
      name: 'Fully connected with our QuickBooks integration',
    },
  ]
}
