
import { Vue, Component } from 'vue-property-decorator'
import { subject, body } from '@/data/quoteTemplate'
import { EmailTemplate, ReplyToEmailOption } from '@/models/dto/Template'
import client from '@/services/quotes'
import auth from '@/store/modules/auth'
import { EventBus } from '@/utils/eventBus'

@Component({})
export default class QuoteEmailTemplate extends Vue {
  template = new EmailTemplate()
  quoteLinkModal = false
  quoteLinkText = ''
  isSubmitting = false
  showPersonalization = false
  isChanged = false
  selectedEmailOption = undefined

  tokens = [
    '{{ SENDER_FIRST_NAME }}',
    '{{ CONTACT_FIRST_NAME }}',
    '{{ SENDER_FULL_NAME }}',
    '{{ QUOTE_ID }}',
    '{{ SENDER_EMAIL }}',
  ]

  get emailOptions(): ReplyToEmailOption[] {
    const companyEmailOption = {
      data: {
        replyToEmail: auth.getCompany?.email || '',
        replyToEmailName: auth.getCompany?.emailSenderName || '',
      },
      displayText: '',
    }
    companyEmailOption.displayText = `${companyEmailOption.data.replyToEmailName} (${companyEmailOption.data.replyToEmail})`

    const userEmailOption = {
      data: {
        replyToEmail: auth.getUser?.email || '',
        replyToEmailName:
          auth.getUser.emailSenderName ||
          `${auth.getUser.firstName} ${auth.getUser.lastName}`,
      },
      displayText: '',
    }
    userEmailOption.displayText = `${userEmailOption.data.replyToEmailName} (${userEmailOption.data.replyToEmail})`
    return [companyEmailOption, userEmailOption]
  }

  save(): void {
    this.isSubmitting = true

    if (this.selectedEmailOption) {
      this.template = {
        ...this.template,
        replyToEmail: this.selectedEmailOption.replyToEmail,
        replyToEmailName: this.selectedEmailOption.replyToEmailName,
      }
    }

    if (this.template.emailCustomizationId) {
      client
        .updateEmailTemplate(this.template)
        .then((res) => res.data.emailCustomizationDTO)
        .then((template) => (this.template = template))
        .finally(() => {
          this.isSubmitting = false
          this.isChanged = false
        })
    } else {
      client
        .saveEmailTemplate(this.template)
        .then((res) => res.data.emailCustomizationDTO)
        .then((template) => (this.template = template))
        .finally(() => {
          this.isSubmitting = false
          this.isChanged = false
        })
    }
  }

  insertLink(): void {
    const ref: any = this.$refs['quote-email-template-richtext']
    this.quoteLinkText = ref.editor.state.doc.textBetween(
      ref.editor.state.selection.from,
      ref.editor.state.selection.to,
      ' '
    )
    this.quoteLinkModal = true
  }

  insertPersonalization(): void {
    this.showPersonalization = true
  }

  hidePersonalization(): void {
    this.showPersonalization = false
  }

  copyToClipboard(token: string): void {
    navigator.clipboard.writeText(token)
    EventBus.$emit('snackbar:success', 'Copied to clipboard!')
  }

  attachPDF(): void {
    this.isChanged = true
    this.template = { ...this.template, includePdf: true }
  }

  removePDF(): void {
    this.isChanged = true
    this.template = { ...this.template, includePdf: false }
  }

  handleChangeTemplateText(opener: string): void {
    this.isChanged = true
    this.template = { ...this.template, opener }
  }

  closeQuoteLinkModal(): void {
    this.quoteLinkText = ''
    this.quoteLinkModal = false
  }

  saveQuoteLink(): void {
    const ref: any = this.$refs['quote-email-template-richtext']
    const link = `<a href="{{ QUOTE_LINK }}" target="_blank">${this.quoteLinkText}</a>`
    ref.editor.commands.insertContent(link)
    this.quoteLinkModal = false
  }

  async created(): Promise<void> {
    const defaultTemplate = new EmailTemplate({
      subject: subject.replaceAll(
        '{{ COMPANY_NAME }}',
        auth.getCompany?.name || 'COMPANY'
      ),
      opener: body.replaceAll(
        '{{ COMPANY_NAME }}',
        auth.getCompany?.name || 'COMPANY'
      ),
      includePdf: true,
    })

    const res = await client.getEmailTemplate()
    const template = res.data.emailCustomizationDTO
    this.template = !template?.emailCustomizationId ? defaultTemplate : template
    this.selectedEmailOption = this.emailOptions.find(
      (option) =>
        option.data.replyToEmail === this.template.replyToEmail &&
        option.data.replyToEmailName === this.template.replyToEmailName
    )

    if (!this.selectedEmailOption) {
      this.selectedEmailOption = this.emailOptions[0]
    }
  }
}
