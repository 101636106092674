
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Type, VehicleType } from '@/models/dto'
import { validateRules, validationRules } from '@/utils/rules'
import { MarketRateType } from '@/models/dto/Rate'
import tclient from '@/services/type'
import client from '@/services/charterup'
import auth from '@/store/modules/auth'
import {
  CharterUpMarketRateGroup,
  CharterUpRate,
  CharterUpVehicle,
} from '@/models/dto/CharterUp'
import sidebar from '@/store/modules/sidebar'
import { toTitle } from '@/utils/string'
import HoldUpModal from '@/components/HoldUpModal.vue'

@Component({
  components: { HoldUpModal },
})
export default class SettingsIntegrationsCharterUpVehicleRateForm extends Vue {
  @Prop({ required: false }) readonly marketRateGroup: CharterUpMarketRateGroup
  form = new CharterUpMarketRateGroup()
  expanded = false
  isModalOpen = false

  validation = validationRules
  sidebar = sidebar
  auth = auth

  toTitle = toTitle

  vehicles: CharterUpVehicle[] = []
  vehicleTypes: VehicleType[] = []
  marketRateTypes: MarketRateType[] = []

  get dailyRate(): number {
    return this.form?.dailyRate?.highRate
  }

  get liveMileRate(): number {
    return this.form?.liveMileRate?.highRate
  }

  get deadMileRate(): number {
    return this.form?.deadMileRate?.highRate
  }

  get hourlyRate(): number {
    return this.form?.hourlyRate?.highRate
  }

  get hourlyMinimum(): number {
    return this.form?.hourlyRate?.hourlyMinimum
  }

  get transferRate(): number {
    return this.form?.transferRate?.highRate
  }

  handleVehicleChange(vehicleId: number): void {
    const vehicle = this.vehicles.find((v) => v.vehicleId === vehicleId)
    const vehicleType = this.vehicleTypes.find(
      (v) => v.id === vehicle?.vehicleTypeId
    )

    const marketRateVehicle = {
      vehicleId: vehicle?.vehicleId,
      vehicleName: vehicle?.vehicleName,
      vehicleTypeId: vehicleType?.id,
      vehicleTypeKey: vehicleType?.key,
      vehicleTypeDTO: vehicleType as Type,
    }
    if (vehicle && vehicleType) {
      this.form = {
        ...this.form,
        ...marketRateVehicle,
        dailyRate: { ...this.form.dailyRate, ...marketRateVehicle },
        liveMileRate: { ...this.form.liveMileRate, ...marketRateVehicle },
        deadMileRate: { ...this.form.deadMileRate, ...marketRateVehicle },
        hourlyRate: { ...this.form.hourlyRate, ...marketRateVehicle },
        transferRate: { ...this.form.transferRate, ...marketRateVehicle },
      }
    }
  }

  handleVehicleTypeChange(vehicleTypeKey: string): void {
    const vehicleType = this.vehicleTypes.find((v) => v.key === vehicleTypeKey)
    const marketRateVehicle = {
      vehicleId: null,
      vehicleName: null,
      vehicleTypeId: vehicleType?.id,
      vehicleTypeKey: vehicleType?.key,
      vehicleTypeDTO: vehicleType as Type,
    }
    if (vehicleType) {
      this.form = {
        ...this.form,
        ...marketRateVehicle,
        dailyRate: { ...this.form.dailyRate, ...marketRateVehicle },
        liveMileRate: { ...this.form.liveMileRate, ...marketRateVehicle },
        deadMileRate: { ...this.form.deadMileRate, ...marketRateVehicle },
        hourlyRate: { ...this.form.hourlyRate, ...marketRateVehicle },
        transferRate: { ...this.form.transferRate, ...marketRateVehicle },
      }
    }
  }

  handleUpdateDailyRate(rate: number): void {
    this.form = {
      ...this.form,
      dailyRate: {
        ...this.form.dailyRate,
        lowRate: rate,
        highRate: rate,
      },
    }
  }

  handleUpdateLiveMileRate(rate: number): void {
    this.form = {
      ...this.form,
      liveMileRate: {
        ...this.form.liveMileRate,
        lowRate: rate,
        highRate: rate,
      },
    }
  }

  handleUpdateDeadMileRate(rate: number): void {
    this.form = {
      ...this.form,
      deadMileRate: {
        ...this.form.deadMileRate,
        lowRate: rate,
        highRate: rate,
      },
    }
  }

  handleUpdateHourlyRate(rate: number): void {
    this.form = {
      ...this.form,
      hourlyRate: {
        ...this.form.hourlyRate,
        lowRate: rate,
        highRate: rate,
      },
    }
  }

  handleUpdateHourlyMinimum(hourlyMinimum: number): void {
    this.form = {
      ...this.form,
      hourlyRate: { ...this.form.hourlyRate, hourlyMinimum },
    }
  }

  handleUpdateTransferRate(rate: number): void {
    this.form = {
      ...this.form,
      transferRate: {
        ...this.form.transferRate,
        lowRate: rate,
        highRate: rate,
      },
    }
  }

  submit(): void {
    validateRules(this).then((isValid) => {
      if (isValid) {
        this.$emit(!this.marketRateGroup ? 'submit' : 'update', this.form)
      }
    })
  }

  cancel(): void {
    this.$emit('cancel')
  }

  deleteRate(): void {
    this.$emit('delete', this.marketRateGroup)
  }

  mounted(): void {
    if (this.marketRateGroup) {
      this.form = { ...this.marketRateGroup }
    } else {
      const marketplace = true
      const rate: Partial<CharterUpRate> = { marketplace }
      this.form = new CharterUpMarketRateGroup({}, rate)
    }

    client
      .getVehicles()
      .then((res) => res.data.data)
      .then((vehicles) => (this.vehicles = vehicles))

    tclient // Using Busify Types until Surfaced on Public API
      .vehicleTypes(true)
      .then((res) => res.data)
      .then((vehicleTypes) => (this.vehicleTypes = vehicleTypes))

    tclient // Using Busify Types until Surfaced on Public API
      .marketRateTypes()
      .then((res) => res.data)
      .then((marketRateTypes) => (this.marketRateTypes = marketRateTypes))
  }
}
