import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { MarketRate, MarketRateRequest } from '@/models/dto/MarketRate'

const httpService: HttpService = new HttpService()

export default {
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<MarketRate>>> {
    const { sorts = null, filters = null, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = apiBaseUrl()

    return httpService.get(`https://${host}/tables/market-rates?${query}`)
  },

  create(payload: MarketRateRequest): Promise<AxiosResponse<MarketRate>> {
    const url = `https://${apiBaseUrl()}/v2/market-rates`
    return httpService.post(url, payload)
  },

  partialUpdate(
    payload: MarketRateRequest
  ): Promise<AxiosResponse<MarketRate>> {
    const id = payload?.marketRateId
    const url = `https://${apiBaseUrl()}/v2/market-rates/${id}`
    return httpService.patch(url, payload)
  },

  delete(id: number): Promise<AxiosResponse<void>> {
    const url = `https://${apiBaseUrl()}/v2/market-rates/${id}`
    return httpService.delete(url)
  },
}
