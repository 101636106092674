import { Address, ApiResult, DefaultCompanyPaymentMethod, VehicleType } from '.'
import { CompanyBaseFarePricingType } from './Pricing'
import { RateType } from './Rate'
import { TableViewResult } from '../TableView'
import { EventType } from '../EventType'
import { NotificationsSetting } from '../NotificationsSetting'
import { CompanyQuickbooksSettings } from './Quickbooks'

export interface CompanyType {
  companyTypeId: number
  companyTypeName?: any
  key: string
}

export interface PartnerType {
  partnerTypeId: number
  key: string
  label: string
  description?: string
}

export interface SocialMediaType {
  id: number
  key: string
  label: string
  description?: string
  companyId?: number
  active: boolean
}

export interface SocialLink {
  companySocialLinkId?: number
  uri: string
  typeKey?: string
  typeLabel?: string
  companyId: number
  socialMediaTypeId: number
  socialMediaType?: SocialMediaType
}
// eslint-disable-next-line no-redeclare
export class SocialLink implements SocialLink {
  constructor(link?: SocialLink) {
    Object.assign(this, link)
  }
}

export type SocialLinkTableViewResult = TableViewResult<SocialLink>

export interface Company {
  name: string
  companyId: number
  address: Address
  email: string
  emailSenderName: string
  bccEmail: string
  phone: string
  opsPhone: string
  fax: string
  dotNumber: string
  dotVerified?: boolean
  broker: boolean
  isAdminCompany: boolean
  isClient: boolean
  hasTerms: boolean
  companyType: CompanyType
  websiteUrl?: string
  redirectPageUrl: string
  darkLogoUrl: string
  lightLogoUrl: string
  faviconUrl: string
  primaryColor: string
  secondaryColor: string
  signatureRequired: boolean
  ticketCancellationRequired: boolean
  usabilityStatus: boolean
  checkoutTypeId: number
  partnerType: PartnerType
  offeredCount: number
  acceptedCount: number
  rejectedCount: number
  driverCount: number
  parentCompanyId?: any
  garage: boolean
  active: boolean
  linkedAccountProfileId?: number
  socialLinks: SocialLink[]
  ada?: boolean
  spab?: boolean
  paymentMethod?: any
  isTrackingOdometry: boolean
  enableDriverAppPayStubs: boolean
  preferred: boolean
  yearFounded: number
  defaultGarageId?: number
  defaultPricingSelectionId?: number
  defaultCompanyPaymentMethods: DefaultCompanyPaymentMethod[]
  defaultExpirationDate?: number
  defaultEnableExpiration?: boolean
  defaultExpirationType?: number
  defaultExpireAfterFirstSend?: boolean
  defaultOverageRateAmount?: number
  defaultOverageRateType: RateType
  defaultOverageRateTypeId?: number
  defaultPaymentPolicy?: string
  defaultPercentageDueNow?: number
  defaultEnablePayLater?: boolean
  defaultPreTripArrivalTime?: string
  defaultPricingMethodTypeId?: number
  defaultDueDaysFromDate?: number
  defaultDueBeforePickup?: boolean
  defaultEnableTrackingLink?: boolean
  isBusifyPayProcessor?: boolean
  defaultRequireSignatureUponCheckout?: boolean
  defaultEnablePayFullAmountNow?: boolean
  defaultPostTripIsQuotedRate?: boolean
  showCharterUPIntegration?: boolean
  showBusifyPayIntegration?: boolean
  showSquareIntegration?: boolean
  showAuthNetIntegration?: boolean
  notificationsSetting?: NotificationsSetting
  quickBooksSettings?: CompanyQuickbooksSettings
  eventTypes?: EventType[]
  vehicleTypes?: VehicleType[]
  publicUri?: string
  companyHasQuotesCreatedThroughWidget?: boolean
  maxResBalance?: number
  defaultSpotTimeOffset?: number
  sendCheckoutConvertEmail?: boolean
  sendManualConvertEmail?: boolean
  enableDriverAssignmentAcceptance?: boolean
  enableSmsNumber?: boolean
  twilioApiPhoneNumber?: string
  busifyLink?: string
  busifySlogan?: string
  defaultEnablePONumber?: boolean
  defaultDateFormat?: string
}

// eslint-disable-next-line no-redeclare
export class Company implements Company {
  constructor(company?: Company) {
    this.address = new Address()
    Object.assign(this, company)
  }
}

export interface CompanyResult extends ApiResult {
  company: Company
}
