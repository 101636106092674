
import { Component, Vue } from 'vue-property-decorator'
import CUSimpleTable from '@/components/CUSimpleTable.vue'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import { TableAction } from '@/models/TableAction'
import { DataTableColumn } from '@/models/DataTableColumn'
import client from '@/services/taxes'
import {
  CompanyChargeOption,
  FlatCompanyChargeOption,
  toCompanyChargeOption,
} from '@/models/dto/LineItemCharge'
import { EventBus } from '@/utils/eventBus'
import sidebar from '@/store/modules/sidebar'
import CompanyChargeOptionForm from '@/components/CompanyChargeOptionForm.vue'
import { LineItemSectionTypes } from '@/utils/enum'
import HoldUpModal from '@/components/HoldUpModal.vue'
import messages from '@/data/messages'

@Component({
  components: { HoldUpModal, CUCollectionTable, CUSimpleTable },
})
export default class SettingsCustomChargesTaxes extends Vue {
  tableView = client.tableView

  isModalOpen = false
  selectedTaxes: FlatCompanyChargeOption[] = []

  get modalConfirmationMessage(): string {
    if (this.selectedTaxes?.length === 1) {
      return 'Are you sure you want to delete this tax?'
    }
    return 'Are you sure you want to delete these taxes?'
  }

  snackbarSuccess(): void {
    EventBus.$emit('snackbar:success', messages.success.taxCreated)
  }

  refreshTaxes(): void {
    EventBus.$emit('refresh-tableview')
  }

  handleAddTax(): void {
    sidebar.push({
      title: 'Add Tax',
      component: CompanyChargeOptionForm,
      props: {
        label: 'Tax',
        sections: [LineItemSectionTypes.SUBTOTAL],
      },
      on: {
        submit: this.handleCreateTax,
        cancel: this.handleSubmissionCancel,
      },
    })
  }

  handleEditTax(companyChargeOption: CompanyChargeOption): void {
    sidebar.popAllAndPush({
      title: 'Edit Tax',
      component: CompanyChargeOptionForm,
      props: {
        label: 'Tax',
        sections: [LineItemSectionTypes.SUBTOTAL],
        companyChargeOption,
      },
      on: {
        submit: this.handleUpdateTax,
        cancel: this.handleSubmissionCancel,
      },
    })
  }

  handleCreateTax(companyChargeOption: CompanyChargeOption): void {
    client
      .create(companyChargeOption)
      .then(this.snackbarSuccess)
      .then(this.refreshTaxes)
      .finally(sidebar.close)
  }

  handleUpdateTax(companyChargeOption: CompanyChargeOption): void {
    client
      .update(companyChargeOption)
      .then(this.refreshTaxes)
      .finally(sidebar.close)
  }

  handleTaxSelection(taxes: FlatCompanyChargeOption[] = []): void {
    this.selectedTaxes = taxes
    this.isModalOpen = true
  }

  handleDeleteTaxes(): void {
    if (this.selectedTaxes?.length) {
      const ids = this.selectedTaxes
        .map(toCompanyChargeOption)
        .map(({ id }) => id)
      client.removeAll(ids).then(this.refreshTaxes)
    }
  }

  async handleTaxMakeDefault(
    taxes: FlatCompanyChargeOption[] = []
  ): Promise<void> {
    this.selectedTaxes = taxes
    if (this.selectedTaxes?.length) {
      const taxes = this.selectedTaxes.map(toCompanyChargeOption).map((tax) => {
        return { ...tax, isDefault: true }
      })

      await client.batchUpdate(taxes)

      this.refreshTaxes()
    }
  }

  async handleTaxRemoveDefault(
    taxes: FlatCompanyChargeOption[] = []
  ): Promise<void> {
    this.selectedTaxes = taxes
    if (this.selectedTaxes?.length) {
      const taxes = this.selectedTaxes.map(toCompanyChargeOption).map((tax) => {
        return { ...tax, isDefault: false }
      })

      await client.batchUpdate(taxes)

      this.refreshTaxes()
    }
  }

  handleSubmissionCancel(): void {
    sidebar.close()
  }

  actions: TableAction[] = [
    {
      displayText: 'Delete',
      key: 'delete',
      icon: 'delete',
      action: this.handleTaxSelection,
    },
    {
      displayText: 'Make Default',
      key: 'make-default',
      icon: 'confirm',
      action: this.handleTaxMakeDefault,
    },
    {
      displayText: 'Remove Default',
      key: 'remove-default',
      icon: 'close',
      action: this.handleTaxRemoveDefault,
    },
  ]

  columns: DataTableColumn[] = [
    {
      _t_id: 'bfa7c6e9-8f90-4800-a263-136498247711',
      type: 'link',
      text: 'Name',
      value: 'name',
      filterBySearch: true,
      filterProp: 'name',
      sortable: true,
      sortProp: 'name',
      clickEvent: 'taxes:edit-tax',
    },
    {
      _t_id: '9eb26f0e-c6c2-4008-8cba-09cd51bb0963',
      type: 'text',
      text: 'Type',
      sortable: true,
      sortProp: 'lineItemChargeTypeLabel',
      value: 'lineItemChargeTypeLabel',
    },
    {
      _t_id: '9eb26f0e-c6c2-4008-8cba-09cd51bb0963',
      text: 'Rate',
      value: 'percentage',
      computedText: (row: FlatCompanyChargeOption): string =>
        row.percentage ? `${row.percentage}%` : '',
    },
    {
      _t_id: 'bf969273-06a5-4ffe-b27d-af1e848dd764',
      type: 'text',
      text: 'Placement',
      sortable: true,
      sortProp: 'lineItemSectionTypeLabel',
      value: 'lineItemSectionTypeLabel',
    },
    {
      _t_id: 'e532b46a-2d59-4b4a-8381-02a59038ae8e',
      text: 'Default on Quote',
      value: 'isDefault',
      width: '1px',
      filterable: true,
      filterProp: 'isDefault',
      filterType: 'eq',
      filterInterface: 'select',
      filterDropdownItems: [
        { text: 'Default', value: 'true' },
        { text: 'Not Default', value: 'false' },
      ],
      sortable: true,
      sortProp: 'isDefault',
      computedText: (row: FlatCompanyChargeOption): string =>
        row.isDefault ? 'Default' : 'Not Default',
    },
  ]

  mounted(): void {
    EventBus.$on('taxes:edit-tax', (tax: FlatCompanyChargeOption) =>
      this.handleEditTax(toCompanyChargeOption(tax))
    )
  }
}
