
import { Vue, Component } from 'vue-property-decorator'
import payments from '@/services/payments'
import SettingsIntegrationsBlock from '@/components/SettingsIntegrationsBlock.vue'
import sidebar from '@/store/modules/sidebar'
import SettingsIntegrationsAuthNet from '@/components/SettingsIntegrationsAuthNet.vue'
import SettingsIntegrationsSquare from '@/components/SettingsIntegrationsSquare.vue'
import Placeholder from '@/views/Placeholder.vue'
import {
  CompanyPaymentGatewayDetail,
  PaymentGatewayType,
  SettingsIntegrationBlockDetail,
} from '@/models/PaymentGateway'
import SettingsIntegrationsQuickbooks from './SettingsIntegrationsQuickbooks.vue'
import SettingsIntegrationsSamsara from './SettingsIntegrationsSamsara.vue'
import SettingsIntegrationsMotive from './SettingsIntegrationsMotive.vue'
import SettingsIntegrationsCharterUp from '@/components/SettingsIntegrationsCharterUp.vue'
import SettingsIntegrationsCharterUpIntegrated from '@/components/SettingsIntegrationsCharterUpIntegrated.vue'
import client from '@/services/charterup'
import auth from '@/store/modules/auth'
import app from '@/store/modules/app'
import { QuickbooksAuthCallbackInfo } from '@/models/dto/Quickbooks'
import SettingsIntegrationApplyForBusifyPay from './SettingsIntegrationApplyForBusifyPay.vue'
import SettingsIntegrationBusifyPay from './SettingsIntegrationBusifyPay.vue'

@Component({
  components: {
    SettingsIntegrationsBlock,
    SettingsIntegrationsAuthNet,
    SettingsIntegrationsSquare,
    SettingsIntegrationsQuickbooks,
    SettingsIntegrationsSamsara,
  },
  metaInfo: {
    title: 'Integrations',
  },
})
export default class SettingsIntegrations extends Vue {
  paymentGateways: CompanyPaymentGatewayDetail[] = []
  defaultPaymentGateway: CompanyPaymentGatewayDetail = null
  loading = false

  paymentGatewayType = {
    busifyPay: 'busify_pay',
    auth: 'auth_net',
    square: 'square',
  }

  get isIntegrationInstalled(): any {
    const integrationMap = {
      busify_pay: this.isPaymentGatewayInstalled('busify_pay'),
      auth_net: this.isPaymentGatewayInstalled('auth_net'),
      square: this.isPaymentGatewayInstalled('square'),
      charterup: this.isCharterUpIntegrationInstalled(),
      quickbooks: this.isQuickbooksIntegrationEnabled,
      samsara: false,
      motive: false,
    }
    return integrationMap
  }

  get isBusifyPayInstalled(): boolean {
    return this.isPaymentGatewayInstalled('busify_pay')
  }

  get isSyncing(): any {
    const syncingMap = {
      quickbooks: this.quickbooksSyncIsActive,
    }
    return syncingMap
  }

  get integrations(): SettingsIntegrationBlockDetail[] {
    const integrations = []

    if (auth.getCompany?.showBusifyPayIntegration) {
      integrations.push({
        title: 'Busify Payments',
        key: this.paymentGatewayType.busifyPay,
        logo: 'BusifyPaymentsLogo.svg',
        logoWidth: '90px',
        tagline: 'Built by Busify',
        description:
          'Our most powerful, fully integrated payment solution, with both card and ACH processing',
        isPaymentProcessor: true,
      })
    }
    if (auth.getCompany?.showAuthNetIntegration) {
      integrations.push({
        title: 'authorize.net',
        key: this.paymentGatewayType.auth,
        logo: 'AuthorizeNetLogo.png',
        tagline: 'Built by Busify',
        description:
          'Process credit and debit transactions for quotes and invoices.',
        logoWidth: 100,
        isPaymentProcessor: true,
      })
    }
    if (auth.getCompany?.showSquareIntegration) {
      integrations.push({
        title: 'Square',
        key: this.paymentGatewayType.square,
        logo: 'square-logo.svg',
        tagline: 'Built by Busify',
        description:
          'Process credit and debit transactions for quotes and invoices.',
        isPaymentProcessor: true,
      })
    }
    if (app.isQuickbooksIntegrationFeatureEnabled) {
      integrations.push({
        title: 'Quickbooks Online',
        logo: 'quickbooks_logo.svg',
        key: 'quickbooks',
        logoWidth: 150,
        tagline: 'Built by Busify',
        description: 'Sync your Busify payments and invoices with Quickbooks.',
        isPaymentProcessor: false,
      })
    }
    if (auth.getCompany?.showCharterUPIntegration) {
      integrations.push({
        title: 'CharterUP for Operators',
        logo: 'CharterUPLogoGrey.svg',
        key: 'charterup',
        logoWidth: 145.71,
        tagline: 'Built by Busify',
        description:
          'Receive and manage CharterUP referrals directly in Busify.',
        isPaymentProcessor: false,
      })
    }
    return integrations
  }

  get paymentIntegrations(): SettingsIntegrationBlockDetail[] {
    return [...this.integrations.filter((i) => i.isPaymentProcessor)]
  }

  get otherIntegrations(): SettingsIntegrationBlockDetail[] {
    return [...this.integrations.filter((i) => !i.isPaymentProcessor)]
  }

  get squareIntegration(): SettingsIntegrationBlockDetail {
    const integration = this.integrations.find(
      (i) => i.key === this.paymentGatewayType.square
    )
    const gatewayDetails = this.gatewayDetails(
      integration.key as PaymentGatewayType
    )
    return { ...integration, gatewayDetails }
  }

  get authNetIntegration(): SettingsIntegrationBlockDetail {
    const integration = this.integrations.find(
      (i) => i.key === this.paymentGatewayType.auth
    )
    const gatewayDetails = this.gatewayDetails(
      integration.key as PaymentGatewayType
    )
    return { ...integration, gatewayDetails }
  }

  get busifyIntegration(): SettingsIntegrationBlockDetail {
    const integration = this.integrations.find(
      (i) => i.key === this.paymentGatewayType.busifyPay
    )
    const gatewayDetails = this.gatewayDetails(
      integration.key as PaymentGatewayType
    )
    return { ...integration, gatewayDetails }
  }

  get isQuickbooksIntegrationEnabled(): boolean {
    return auth.isQuickbooksIntegrationEnabled
  }

  get quickbooksSyncIsActive(): boolean {
    return auth?.getCompanyQuickBooksSettings?.syncing || false
  }

  async isCharterUpIntegrationInstalled(): Promise<boolean> {
    try {
      const integration = await client.getCharterUpIntegration()
      return !!integration?.data
    } catch {
      return false
    }
  }

  isDefaultIntegration({ key }: SettingsIntegrationBlockDetail): boolean {
    return this.defaultPaymentGateway?.paymentGatewayTypeKey === key
  }

  get integrationQty(): number {
    return this.paymentGateways?.length || 0
  }

  gatewayDetails(key: PaymentGatewayType): CompanyPaymentGatewayDetail {
    return this.paymentGateways?.find((p) => p.paymentGatewayTypeKey === key)
  }

  isPaymentGatewayInstalled(key: PaymentGatewayType): boolean {
    return this.paymentGateways
      .map((pg) => pg.paymentGatewayTypeKey)
      .includes(key)
  }

  handleIntegrationClick(event: string, props: any = null): void {
    switch (event) {
      case this.paymentGatewayType.busifyPay:
        this.openBusifyPaymentsSidebar()
        break
      case this.paymentGatewayType.auth:
        sidebar.push({
          component: SettingsIntegrationsAuthNet,
          props: {
            isDefault: this.isDefaultIntegration(this.authNetIntegration),
            integrationDetail: this.authNetIntegration,
          },
          on: {
            'refresh-integrations': this.loadPaymentGateways,
          },
        })
        break
      case this.paymentGatewayType.square:
        sidebar.push({
          component: SettingsIntegrationsSquare,
          props: {
            isDefault: this.isDefaultIntegration(this.squareIntegration),
            integrationDetail: this.squareIntegration,
          },
          on: {
            'refresh-integrations': this.loadPaymentGateways,
          },
        })
        break
      case 'quickbooks':
        sidebar.push({
          component: SettingsIntegrationsQuickbooks,
          props: {
            quickbooksAuthCallbackInfo: props,
          },
        })
        break
      case 'samsara':
        sidebar.push({
          component: SettingsIntegrationsSamsara,
          on: {
            'refresh-integrations': this.loadPaymentGateways,
          },
        })
        break
      case 'motive':
        sidebar.push({
          component: SettingsIntegrationsMotive,
          on: {
            'refresh-integrations': this.loadPaymentGateways,
          },
        })
        break
      case 'charterup':
        this.openCharterUpSidebar()
        break
      default:
        sidebar.push({
          component: Placeholder,
        })
    }
  }

  async openCharterUpSidebar(): Promise<void> {
    const res = await client.getCharterUpIntegration()
    const isIntegrated = !!res.data
    sidebar.push({
      component: isIntegrated
        ? SettingsIntegrationsCharterUpIntegrated
        : SettingsIntegrationsCharterUp,
      width: isIntegrated ? 860 : undefined,
      on: { 'refresh-integrations': this.loadPaymentGateways },
    })
  }

  async loadPaymentGateways(): Promise<void> {
    const allGatewaysRes = await payments.getAllPaymentGateways()
    this.paymentGateways = allGatewaysRes?.data?.paymentGateways || []
    this.defaultPaymentGateway = this.paymentGateways.find(
      (g) => g.defaultGateway
    )
  }

  openBusifyPayIntegrationSidebar(): void {
    sidebar.push({
      component: SettingsIntegrationBusifyPay,
      props: {
        integrationDetail: this.busifyIntegration,
        isInstalled: this.isPaymentGatewayInstalled('busify_pay'),
      },
      on: {
        'refresh-integrations': this.loadPaymentGateways,
      },
    })
  }

  openBusifyPayApplicationSidebar(): void {
    sidebar.push({
      component: SettingsIntegrationApplyForBusifyPay,
      props: {
        integrationDetail: this.busifyIntegration,
      },
      on: {
        'show-busify-integration-panel': this.openBusifyPayIntegrationSidebar,
      },
      width: 800,
    })
  }

  openBusifyPaymentsSidebar(): void {
    if (this.isPaymentGatewayInstalled('busify_pay')) {
      this.openBusifyPayIntegrationSidebar()
    } else {
      this.openBusifyPayApplicationSidebar()
    }
  }

  async mounted(): Promise<void> {
    try {
      const gatewayPromise = this.loadPaymentGateways()
      const companyDetailPromise = auth.getUserCompanyDetail()
      const quickBooksDetailPromise = auth.getCompanyQuickBooksDetail()
      await Promise.allSettled([
        gatewayPromise,
        companyDetailPromise,
        quickBooksDetailPromise,
      ])
    } catch (e) {
      console.log(e)
    }
    if (
      this.$route.query?.code &&
      this.$route.query?.realmId &&
      this.$route.query?.state &&
      !this.isQuickbooksIntegrationEnabled
    ) {
      const quickbooksAuthCallbackInfo: QuickbooksAuthCallbackInfo = {
        code: this.$route.query?.code as string,
        realmId: this.$route.query?.realmId as string,
        state: this.$route.query?.state as string,
      }
      this.handleIntegrationClick('quickbooks', quickbooksAuthCallbackInfo)
    }
  }
}
