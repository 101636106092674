
import { Vue, Component } from 'vue-property-decorator'
import company from '@/services/company'
import auth from '@/store/modules/auth'
import SettingsGeneralDefaultsGeneral from '@/components/SettingsGeneralDefaultsGeneral.vue'
import SettingsGeneralDefaultsPayment from '@/components/SettingsGeneralDefaultsPayment.vue'
import SettingsGeneralDefaultsNotifications from '@/components/SettingsGeneralDefaultsNotifications.vue'
import { Tab } from '@/models/dto/Tab'
import { Company } from '@/models/dto/Company'
import { PaymentMethod, PaymentMethodKeys } from '@/models/dto'
import payments from '@/services/payments'
import { EventBus } from '@/utils/eventBus'
import app from '@/store/modules/app'

@Component({
  components: {
    SettingsGeneralDefaultsGeneral,
    SettingsGeneralDefaultsPayment,
    SettingsGeneralDefaultsNotifications,
  },
})
export default class SettingsGeneralDefaults extends Vue {
  company: Partial<Company> = {
    defaultCompanyPaymentMethods: [],
  }
  hasModifiedCompany = false
  loading = false
  companyLoading = false

  get tabs(): Tab[] {
    const tabs = [
      {
        label: 'General',
        component: SettingsGeneralDefaultsGeneral,
        hash: 'defaults-general',
        title: 'General Defaults',
        props: {
          company: this.company,
          hasModifiedCompany: this.hasModifiedCompany,
          loading: this.loading,
          companyLoading: this.companyLoading,
        },
        on: {
          save: this.save,
          updateCompanyPartial: this.updateCompanyPartial,
        },
      },
      {
        label: 'Payment',
        component: SettingsGeneralDefaultsPayment,
        hash: 'defaults-payments',
        title: 'Payment Defaults',
        props: {
          company: this.company,
          hasModifiedCompany: this.hasModifiedCompany,
          loading: this.loading,
        },
        on: {
          save: this.save,
          updateCompanyPartial: this.updateCompanyPartial,
        },
      },
      {
        label: 'Notifications',
        component: SettingsGeneralDefaultsNotifications,
        hash: 'defaults-notifications',
        title: 'Notifications Defaults',
        props: {
          company: this.company,
          hasModifiedCompany: this.hasModifiedCompany,
          loading: this.loading,
        },
        on: {
          save: this.save,
          updateCompanyPartial: this.updateCompanyPartial,
        },
      },
    ]

    return tabs
  }

  async refreshCompany(): Promise<void> {
    try {
      this.companyLoading = true
      const res = await company.byId(auth.getCompanyId)
      this.company = res.data.company
    } catch (e) {
      console.log(e)
    } finally {
      this.companyLoading = false
    }
  }

  async refreshCompanyEventTypes(): Promise<void> {
    try {
      this.companyLoading = true
      const res = await company.byId(auth.getCompanyId)
      const { company: companyData } = res.data
      if (companyData?.eventTypes) {
        this.company.eventTypes = companyData.eventTypes
      }
    } catch (e) {
      EventBus.$emit('snackbar:error', e)
    } finally {
      this.companyLoading = false
    }
  }

  async loadCompanySettings(): Promise<void> {
    try {
      this.companyLoading = true
      const res = await company.byId(auth.getCompanyId)
      this.company = res.data.company
      await this.loadPaymentMethods()
      this.$nextTick(() => {
        this.hasModifiedCompany = false
      })
    } catch (e) {
      console.log(e)
    } finally {
      this.companyLoading = false
    }
  }

  updateCompanyPartial(event: Partial<Company>): void {
    this.company = Object.assign({}, this.company, event)
    this.hasModifiedCompany = true
  }

  async loadPaymentMethods(): Promise<void> {
    const { data } = await payments.getPaymentMethodTypes()
    const paymentMethodKeys: PaymentMethodKeys[] =
      Object.values(PaymentMethodKeys)

    const paymentMethods: PaymentMethod[] = paymentMethodKeys.map((key) => {
      const paymentMethodType = data.find((method) => method.key === key)
      const paymentMethod: PaymentMethod = {
        paymentMethodTypeId: Number(paymentMethodType.id),
        paymentMethodType: {
          ...paymentMethodType,
          active: true,
          id: Number(paymentMethodType.id),
          key: paymentMethodType.key as PaymentMethodKeys,
        },
        processingFee: 0,
        note: '',
        isActive: false,
      }
      return paymentMethod
    })

    if (
      paymentMethods.length ===
      this.company.defaultCompanyPaymentMethods?.length
    ) {
      return
    }

    const populatedDefaultCompanyPaymentMethods = []
    for (const paymentMethod of paymentMethods) {
      const existingDefaultCompanyPaymentMethod =
        this.company.defaultCompanyPaymentMethods?.find(
          ({ paymentMethodTypeId }) =>
            paymentMethodTypeId === paymentMethod.paymentMethodTypeId
        )
      if (existingDefaultCompanyPaymentMethod) {
        populatedDefaultCompanyPaymentMethods.push(
          existingDefaultCompanyPaymentMethod
        )
      } else {
        populatedDefaultCompanyPaymentMethods.push(paymentMethod)
      }
    }
    this.company.defaultCompanyPaymentMethods =
      populatedDefaultCompanyPaymentMethods
  }

  async mounted(): Promise<void> {
    EventBus.$on('company:refresh', this.refreshCompany)
    EventBus.$on('company:refresh-event-types', this.refreshCompanyEventTypes)

    await this.loadCompanySettings()
  }

  beforeDestroy(): void {
    EventBus.$off('company:refresh', this.refreshCompany)
    EventBus.$off('company:refresh-event-types', this.refreshCompanyEventTypes)
  }

  async save(): Promise<void> {
    this.loading = true

    try {
      await company.update(auth.getCompanyId, this.company)
      this.hasModifiedCompany = false
      EventBus.$emit('snackbar:success', 'Defaults saved successfully.')
    } catch (e: any) {
      EventBus.$emit('snackbar:error', e.response.data.message)
    } finally {
      this.loading = false
    }
  }
}
