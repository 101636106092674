
import { Vue, Component, Prop } from 'vue-property-decorator'
import quickbooksService from '@/services/quickbooks'
import { BusifyIntegration } from '@/utils/enum'
import { EventBus } from '@/utils/eventBus'
import { httpHelper } from '@/utils/string'

@Component({})
export default class QuickbooksIntegrationSplashSidebar extends Vue {
  @Prop({ required: false, default: null }) readonly companyId!: number
  busifyTexts = BusifyIntegration

  async handleQuickbooksLogin(): Promise<void> {
    try {
      const response = await quickbooksService.quickbooksAuthRequest(
        this.companyId
      )
      const url = response.data.authorizationURL
      window.open(httpHelper(url), '_blank')
      // window.location.href = url
    } catch (e) {
      console.log(e)
      EventBus.$emit(
        'snackbar:error',
        'Unable to connect with Quickbooks at the moment, please try again later'
      )
    }
  }
}
