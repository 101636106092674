
import { Component, Vue } from 'vue-property-decorator'
import { Tab } from '@/models/dto/Tab'
import SettingsDriverPayOptions from '@/components/SettingsDriverPayOptions.vue'
import SettingsDriverPayPayroll from '@/components/SettingsDriverPayPayroll.vue'
import app from '@/store/modules/app'

@Component({ metaInfo: { title: 'Driver Pay' } })
export default class SettingsDriverPay extends Vue {
  tabs: Tab[] = [
    {
      label: 'Pay Options',
      hash: 'pay-options',
      component: SettingsDriverPayOptions,
      title: 'Pay Options',
    },
    {
      label: 'Payroll',
      hash: 'payroll',
      component: SettingsDriverPayPayroll,
      title: 'Payroll',
    },
  ]
}
