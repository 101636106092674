
import { Vue, Component, Prop } from 'vue-property-decorator'
import auth from '@/store/modules/auth'
import { EventBus } from '@/utils/eventBus'
import BusifySidebarHeader from './BusifySidebarHeader.vue'
import {
  CompanyPaymentGatewayDetail,
  SettingsIntegrationBlockDetail,
} from '@/models/PaymentGateway'
import sidebar from '@/store/modules/sidebar'
import { isNotEmptyInput } from '@/utils/validators'
import payments from '@/services/payments'

@Component({
  components: {
    BusifySidebarHeader,
  },
})
export default class SettingsIntegrationBusifyPay extends Vue {
  @Prop({ required: false, type: Boolean }) isInstalled!: boolean
  @Prop({ required: false, type: Boolean }) isDefault!: boolean
  @Prop({ required: false, default: () => ({}) })
  integrationDetail!: SettingsIntegrationBlockDetail

  auth = auth
  isDefaultProcessor = false
  isNotEmptyInput = isNotEmptyInput
  isMerchantIdEnabled = false
  isAccessTokenEnabled = false

  formData = {
    accessToken: '',
    applicationId: '',
  }

  mounted(): void {
    this.isDefaultProcessor = this.isDefault
    if (this.integrationDetail) {
      this.formData.applicationId =
        this.integrationDetail?.gatewayDetails?.apiKey
    }
  }

  close(): void {
    sidebar.pop()
    this.$emit('close')
  }

  async submit(): Promise<void> {
    if (this.isDefaultProcessor && this.integrationDetail?.gatewayDetails) {
      await payments.updateDefaultPaymentGateway(
        this.integrationDetail.gatewayDetails.companyPaymentGatewayId
      )
      sidebar.pop()
      this.$emit('refresh-integrations')
    }

    if (!this.$refs.form['validate']()) {
      return
    }

    const applicationId =
      this.formData.applicationId ||
      this.integrationDetail?.gatewayDetails?.apiKey
    const secretKey = this.formData.accessToken
    const payload: CompanyPaymentGatewayDetail = {
      apiKey: applicationId,
      clientKey: applicationId,
      companyId: auth.getCompanyId,
      paymentGatewayTypeKey: 'busify_pay',
      secretKey,
    }
    if (this.integrationDetail?.gatewayDetails) {
      payload.companyPaymentGatewayId =
        this.integrationDetail.gatewayDetails.companyPaymentGatewayId
      try {
        await payments.updatePaymentGateway(payload)
      } catch (e) {
        console.log(e)
        EventBus.$emit(
          'snackbar:error',
          'There was an error adding the payment information.'
        )
        return
      }
    } else {
      try {
        await payments.createPaymentGateway({
          ...payload,
          defaultGateway: this.isDefaultProcessor,
        })
      } catch (e) {
        console.log(e)
        EventBus.$emit(
          'snackbar:error',
          'There was an error adding the payment information.'
        )
        return
      }
    }
    EventBus.$emit(
      'snackbar:success',
      'Payment information added successfully!'
    )
    this.onClose()
  }

  onClose(): void {
    this.$emit('refresh-integrations')
    sidebar.close()
  }
}
