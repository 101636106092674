
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Company, SocialLink } from '@/models/dto/Company'
import { Address } from '@/models/dto'
import AutocompleteAddress from '@/components/AutocompleteAddress.vue'
import { states } from '@/utils/states'
import { validationRules } from '@/utils/rules'
import { findLink } from '@/utils/socialLink'
import auth from '@/store/modules/auth'
import { UserGroupIds } from '@/data/userGroups'
import { phoneFormatFilter } from '@/utils/string'
import { socialLinkTypes } from '@/data/types'

@Component({
  components: {
    AutocompleteAddress,
  },
})
export default class EditCompanyForm extends Vue {
  @Prop({ type: Object, required: true }) existingCompany: Company
  @Prop({ type: Array, required: true }) socialLinks: SocialLink[]
  @Prop({ type: Boolean, default: false }) isSettingsView: boolean

  states = states

  rules = validationRules
  mailingAddress = new Address()
  differentMailingAddress = false
  company = new Company()

  @Watch('existingCompany', { immediate: true })
  handleUpdateExistingCompany(): void {
    this.company = { ...this.existingCompany }
  }

  get companyPhone(): string {
    return phoneFormatFilter(this.company?.phone)
  }

  get facebookLink(): string {
    const link = findLink('facebook', this.socialLinks)
    return link?.uri
  }

  get instagramLink(): string {
    const link = findLink('instagram', this.socialLinks)
    return link?.uri
  }

  get twitterLink(): string {
    const link = findLink('twitter', this.socialLinks)
    return link?.uri
  }

  get isBusifyAdmin(): boolean {
    return (
      auth.getUser?.groupId === UserGroupIds.ADMIN &&
      !!auth.getRoles.find((role) => role.roleName === 'is_admin_admin')
    )
  }

  enableSmsNumberLabel(smsNumber: string): string {
    return `<p>Enable SMS Number${
      smsNumber ? `: <b>${smsNumber}</b></p>` : '</p>'
    }`
  }

  editLink(type: string, uri: string): void {
    const link = findLink(type, this.socialLinks)
    if (!link) {
      const socialType = socialLinkTypes.find((socialType) => {
        return socialType.typeKey === type
      })
      const typeId = socialType.socialMediaTypeId
      const newLink: SocialLink = {
        companyId: this.company?.companyId,
        socialMediaTypeId: typeId,
        uri,
        typeKey: type,
      }
      this.socialLinks.push(newLink)
      return
    }
    link.uri = uri
    this.$emit('edit-company-form:update-social-links', this.socialLinks)
  }

  handleUpdateOperationsAddressInput(input: string): void {
    this.company.address = { ...this.company.address, street1: input || '' }
    this.$emit('edit-company-form:update-company', this.company)
  }

  handleUpdateOperationsAddress(address: Address): void {
    let updatedCompany
    if (address) {
      address.street2 = this.company.address.street2
      updatedCompany = { ...this.company, address }
    } else {
      const { street1, ...rest } = this.company.address || new Address()
      updatedCompany = { ...this.company, address: new Address(rest) }
    }
    this.$emit('edit-company-form:update-company', updatedCompany)
  }

  handleUpdateMailingAddress(address: Address): void {
    if (address) {
      address.street2 = this.company.address.street2
      this.mailingAddress = address
    } else {
      const { street1, ...rest } = this.mailingAddress || new Address()
      this.mailingAddress = new Address(rest)
    }
  }
}
