
import { Component, Vue } from 'vue-property-decorator'
import { EventBus } from '@/utils/eventBus'
import { Markup, MarkupType } from '@/models/dto/Markup'
import { TableAction } from '@/models/TableAction'
import { DataTableColumn } from '@/models/DataTableColumn'
import client from '@/services/markup'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import HoldUpModal from '@/components/HoldUpModal.vue'
import sidebar from '@/store/modules/sidebar'
import MarkupForm from '@/components/MarkupForm.vue'
import messages from '@/data/messages'

@Component({
  components: { HoldUpModal, CUCollectionTable },
})
export default class SettingsCustomChargesMarkups extends Vue {
  tableView = client.tableView

  isModalOpen = false
  selectedMarkups: Markup[] = []

  markupTypes: Record<string, MarkupType> = {}

  get modalConfirmationMessage(): string {
    if (this.selectedMarkups?.length === 1) {
      return 'Are you sure you want to delete this markup?'
    }
    return 'Are you sure you want to delete these markups?'
  }

  markupTypeLabel(markup: Markup): string {
    return (
      markup.markupType?.label || this.markupTypes[markup.markupTypeId]?.label
    )
  }

  rateFormatter(markup: Markup): string {
    return `${markup.markupPercentage}%`
  }

  snackbarSuccess(): void {
    EventBus.$emit('snackbar:success', messages.success.markupCreated)
  }

  refreshMarkups(): void {
    EventBus.$emit('refresh-tableview')
  }

  handleAddMarkup(): void {
    sidebar.push({
      title: 'Add Markup',
      component: MarkupForm,
      on: {
        submit: this.handleCreateMarkup,
        cancel: this.handleSubmissionCancel,
      },
    })
  }

  handleEditMarkup(markup: Markup): void {
    sidebar.popAllAndPush({
      title: 'Edit Markup',
      component: MarkupForm,
      props: { markup },
      on: {
        submit: (markup: Markup) => this.handleUpdateMarkup(markup),
        cancel: this.handleSubmissionCancel,
      },
    })
  }

  handleCreateMarkup(markup: Markup): void {
    client
      .create([markup])
      .then(this.snackbarSuccess)
      .then(this.refreshMarkups)
      .finally(sidebar.close)
  }

  handleUpdateMarkup(markup: Markup): void {
    client.update(markup).then(this.refreshMarkups).finally(sidebar.close)
  }

  handleMarkupSelection(markups: Markup[] = []): void {
    this.selectedMarkups = markups
    this.isModalOpen = true
  }

  handleDeleteMarkups(): void {
    if (this.selectedMarkups?.length) {
      Promise.all(this.selectedMarkups.map(client.delete)).then(
        this.refreshMarkups
      )
    }
  }

  handleSubmissionCancel(): void {
    sidebar.pop()
  }

  actions: TableAction[] = [
    {
      displayText: 'Delete',
      key: 'delete',
      icon: 'delete',
      action: this.handleMarkupSelection,
    },
  ]

  columns: DataTableColumn[] = [
    {
      _t_id: 'ad6c92d5-cfd1-438f-9d8c-6881c8319dca',
      type: 'link',
      text: 'Name',
      value: 'name',
      filterBySearch: true,
      filterProp: 'name',
      sortable: true,
      sortProp: 'name',
      clickEvent: 'markups:edit-markup',
    },
    {
      _t_id: '65dceae2-9771-4944-9e72-909eeaca2ba6',
      type: 'text',
      text: 'Type',
      sortable: true,
      sortProp: 'markupType.key',
      value: 'markupType.key',
      computedText: this.markupTypeLabel,
    },
    {
      _t_id: '5c12c16e-7a1f-4849-a7e4-0886d28a35e2',
      type: 'text',
      text: 'Rate',
      value: 'markupPercentage',
      computedText: this.rateFormatter,
    },
    {
      _t_id: '2b1ca80f-3cc1-431c-916b-06ebe22dc208',
      type: 'text',
      text: 'Placement',
      value: '',
      computedText: (_: Markup): string => 'Base Fare',
    },
  ]

  mounted(): void {
    EventBus.$on('markups:edit-markup', this.handleEditMarkup)
  }

  beforeDestroy(): void {
    EventBus.$off('markups:edit-markup', this.handleEditMarkup)
  }
}
