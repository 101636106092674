
import { Vue, Component, Prop } from 'vue-property-decorator'
import BusifyPaymentFeatures from './BusifyPaymentFeatures.vue'
import BusifySidebarHeader from './BusifySidebarHeader.vue'
import sidebar from '@/store/modules/sidebar'
import CUModal from './CUModal.vue'
import { SettingsIntegrationBlockDetail } from '@/models/PaymentGateway'
import payments from '@/services/payments'
import { EventBus } from '@/utils/eventBus'

@Component({
  components: {
    BusifySidebarHeader,
    BusifyPaymentFeatures,
    CUModal,
  },
})
export default class BusifyIntegrationPanel extends Vue {
  @Prop({ type: Object, required: true })
  integrationDetail!: SettingsIntegrationBlockDetail

  isModalOpen = false

  openModal(): void {
    this.isModalOpen = true
  }

  async applyBusify(): Promise<void> {
    try {
      await payments.applyForBusifyPay()
      EventBus.$emit(
        'snackbar:success',
        'Successfully applied for a Busify Payments account'
      )
    } catch (e) {
      console.log(e)
      EventBus.$emit(
        'snackbar:error',
        'Error on applying for a Busify Payments account'
      )
      return
    } finally {
      sidebar.close()
    }
  }

  onClose(): void {
    sidebar.close()
  }
}
