
import { Vue, Component } from 'vue-property-decorator'
import { subject, body } from '@/data/invoiceTemplate'
import { EmailTemplate } from '@/models/dto/Template'
import client from '@/services/invoice'
import auth from '@/store/modules/auth'
import { EventBus } from '@/utils/eventBus'

@Component({})
export default class InvoiceEmailTemplate extends Vue {
  template = new EmailTemplate()
  invoiceLinkModal = false
  invoiceLinkText = ''
  isSubmitting = false
  showPersonalization = false
  isChanged = false

  tokens = [
    '{{ SENDER_FIRST_NAME }}',
    '{{ CONTACT_FIRST_NAME }}',
    '{{ SENDER_FULL_NAME }}',
    '{{ RESERVATION_ID }}',
    '{{ SENDER_EMAIL }}',
  ]

  // POST LAUNCH
  // sendPreview(): void {
  //   return
  // }

  save(): void {
    this.isSubmitting = true
    if (this.template.emailCustomizationId) {
      client
        .updateEmailTemplate(this.template)
        .then((res) => res.data)
        .then((template) => (this.template = template))
        .finally(() => {
          this.isSubmitting = false
          this.isChanged = false
        })
    } else {
      client
        .saveEmailTemplate(this.template)
        .then((res) => res.data)
        .then((template) => (this.template = template))
        .finally(() => {
          this.isSubmitting = false
          this.isChanged = false
        })
    }
  }

  insertLink(): void {
    const ref: any = this.$refs['invoice-email-template-richtext']
    this.invoiceLinkText = ref.editor.state.doc.textBetween(
      ref.editor.state.selection.from,
      ref.editor.state.selection.to,
      ' '
    )
    this.invoiceLinkModal = true
  }

  insertPersonalization(): void {
    this.showPersonalization = true
  }

  hidePersonalization(): void {
    this.showPersonalization = false
  }

  copyToClipboard(token: string): void {
    navigator.clipboard.writeText(token)
    EventBus.$emit('snackbar:success', 'Copied to clipboard!')
  }

  attachPDF(): void {
    this.isChanged = true
    this.template = { ...this.template, includePdf: true }
  }

  removePDF(): void {
    this.isChanged = true
    this.template = { ...this.template, includePdf: false }
  }

  handleChangeTemplateText(opener: string): void {
    this.isChanged = true
    this.template = { ...this.template, opener }
  }

  closeInvoiceLinkModal(): void {
    this.invoiceLinkText = ''
    this.invoiceLinkModal = false
  }

  saveInvoiceLink(): void {
    const ref: any = this.$refs['invoice-email-template-richtext']
    const link = `<a href="{{ INVOICE_LINK }}" target="_blank">${this.invoiceLinkText}</a>`
    ref.editor.commands.insertContent(link)
    this.invoiceLinkModal = false
  }

  mounted(): void {
    const defaultTemplate = new EmailTemplate({
      subject: subject.replaceAll(
        '{{ COMPANY_NAME }}',
        auth.getCompany?.name || 'COMPANY'
      ),
      opener: body.replaceAll(
        '{{ COMPANY_NAME }}',
        auth.getCompany?.name || 'COMPANY'
      ),
      includePdf: true,
    })
    client
      .getEmailTemplate()
      .then((res) => res.data)
      .then(
        (template) =>
          (this.template = !template?.emailCustomizationId
            ? defaultTemplate
            : template)
      )
  }
}
