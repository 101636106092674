
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Company, SocialLink } from '@/models/dto/Company'
import client from '@/services/company'
import socialLinksClient from '@/services/socialLinks'
import smsNumberClient from '@/services/smsNumber'
import { Address } from '@/models/dto'
import AutocompleteAddress from '@/components/AutocompleteAddress.vue'
import { EventBus } from '@/utils/eventBus'
import typeClient from '@/services/type'
import { states } from '@/utils/states'
import EditCompanyForm from '@/components/EditCompanyForm.vue'
import { formatAddressName } from '@/utils/string'
import { getCenteringDataByAddress } from '@/utils/address'

@Component({
  components: { AutocompleteAddress, EditCompanyForm },
})
export default class SettingsGeneralCompanyProfile extends Vue {
  @Prop({ required: false }) readonly companyId: number

  states = states

  company = new Company()
  mailingAddress = new Address()
  differentMailingAddress = false
  companyEdited = false
  socialLinks: SocialLink[] = []
  submitting = false

  @Watch('company', { deep: true })
  editCompany(): void {
    this.companyEdited = true
  }

  @Watch('mailingAddress', { deep: true })
  editMailingAddress(): void {
    this.companyEdited = true
  }

  @Watch('socialLinks', { deep: true })
  editSocialLinks(): void {
    this.companyEdited = true
  }

  updateCompany(partialCompany: Partial<Company>): void {
    this.company = Object.assign({}, this.company, partialCompany)
  }

  updateCompanyAddress(partialAddress: Partial<Address>): void {
    this.company.address = Object.assign(
      {},
      this.company.address,
      partialAddress
    )
  }

  async saveSocialLinks(): Promise<void> {
    const promises = []
    for (const link of this.socialLinks) {
      if (link.companySocialLinkId) {
        promises.push(
          socialLinksClient.modifySocialLink(link.companySocialLinkId, link)
        )
      } else {
        promises.push(socialLinksClient.addSocialLink(this.companyId, [link]))
      }
    }
    await Promise.all(promises)
  }

  async createSmsPhoneNumber(): Promise<void> {
    if (
      this.company.enableSmsNumber &&
      this.company.twilioApiPhoneNumber == null
    ) {
      try {
        await smsNumberClient.create(this.companyId)
      } catch (e: any) {
        EventBus.$emit('snackbar:error', 'Failed to create sms phone number')
      }
    }
  }

  async handleSave(): Promise<void> {
    const form: any = this.$refs.form
    if (!form.validate()) {
      return
    }
    this.submitting = true
    try {
      const addressName = formatAddressName(
        this.company.address || new Address()
      )
      const centeringData = await getCenteringDataByAddress(
        this.company.address
      )
      const newCompany = {
        ...this.company,
        address: {
          ...this.company.address,
          ...centeringData,
          name: addressName,
          addressName,
          title: null,
        },
      }

      await Promise.all([
        client.update(this.companyId, newCompany),
        this.saveSocialLinks(),
        this.createSmsPhoneNumber(),
      ])
      this.company = newCompany
    } catch {
      EventBus.$emit('snackbar:error', 'Error saving company.')
      return
    } finally {
      this.submitting = false
    }
    EventBus.$emit('snackbar:success', 'Company Profile saved successfully.')
    this.$nextTick(() => {
      this.companyEdited = false
    })
  }

  async loadCompany(): Promise<void> {
    const res = await client.byId(this.companyId)
    this.company = res.data.company
    if (!this.company.address) {
      this.company = { ...this.company, address: new Address() }
    }
  }

  async loadSocialLinks(): Promise<void> {
    const res = await socialLinksClient.tableView(this.companyId, {
      pageSize: -1,
    })
    this.socialLinks = res.data.resultList
  }

  async mounted(): Promise<void> {
    if (this.companyId) {
      const promises = []
      promises.push(this.loadCompany(), this.loadSocialLinks())
      await Promise.all(promises)
      this.companyEdited = false
    }
  }
}
