export interface DriverPaySettings {
  id: number
  companyId: number
  defaultMethod: string
  hourlyConfig: HourlyConfig
  percentConfig: PercentConfig
  perTripAdditionalPaymentOption: PerTripAdditionalPaymentOption
  perDiemAdditionalPaymentOption: PerDiemAdditionalPaymentOption
  pmFirstName: string
  pmLastName: string
  pmEmail: string
  paymentCycleType: string
  paymentCycle: PaymentCycle
}

export interface HourlyConfig {
  payExtraForLongTrip: boolean
  longTripThresholdInHours: number
  longTripAltRate: string
  payExtraForOvernightTrip: boolean
  overnightTripAltRate: string
}

export interface PercentConfig {
  type: string
}

export interface PerTripAdditionalPaymentOption {
  enabled: boolean
  rate: string
}

export interface PerDiemAdditionalPaymentOption {
  enabled: boolean
  threshold: number
  thresholdUnit: string
}

export interface PaymentCycle {
  firstDay: string
}

// eslint-disable-next-line no-redeclare
export class DriverPaySettings implements DriverPaySettings {
  constructor(settings?: Partial<DriverPaySettings>) {
    Object.assign(this, settings)
  }
}

// eslint-disable-next-line no-redeclare
export class HourlyConfig implements HourlyConfig {
  constructor(config?: Partial<HourlyConfig>) {
    Object.assign(this, config)
  }
}

// eslint-disable-next-line no-redeclare
export class PercentConfig implements PercentConfig {
  constructor(config?: Partial<PercentConfig>) {
    Object.assign(this, config)
  }
}

// eslint-disable-next-line no-redeclare
export class PerTripAdditionalPaymentOption
  implements PerTripAdditionalPaymentOption
{
  constructor(config?: Partial<PerTripAdditionalPaymentOption>) {
    Object.assign(this, config)
  }
}

// eslint-disable-next-line no-redeclare
export class PerDiemAdditionalPaymentOption
  implements PerDiemAdditionalPaymentOption
{
  constructor(config?: Partial<PerDiemAdditionalPaymentOption>) {
    Object.assign(this, config)
  }
}

// eslint-disable-next-line no-redeclare
export class PaymentCycle implements PaymentCycle {
  constructor(config?: Partial<PaymentCycle>) {
    Object.assign(this, config)
  }
}
