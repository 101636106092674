
import { Component, Vue } from 'vue-property-decorator'
import { DataTableColumn } from '@/models/DataTableColumn'
import { EventBus } from '@/utils/eventBus'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import CompanyStopsForm from '@/components/CompanyStopsForm.vue'
import client from '@/services/companyStops'
import sidebar from '@/store/modules/sidebar'
import { TableAction } from '@/models/TableAction'
import HoldUpModal from '@/components/HoldUpModal.vue'
import companyStopsStore from '@/store/modules/companyStops'
import { CompanyStop } from '@/models/CompanyStop'

@Component({
  components: {
    CompanyStopsForm,
    CUCollectionTable,
    HoldUpModal,
  },
  metaInfo: { title: 'Saved Stops' },
})
export default class CompanyStopsList extends Vue {
  tableView = client.tableView
  tableItemCount = 0
  search = ''
  selectedStops: CompanyStop[] = []
  isModalOpen = false
  tooltipText =
    'Save your frequently used stops here for quick access when editing itineraries.'

  handleRefresh(): void {
    sidebar.pop()
    EventBus.$emit('refresh-tableview')
    companyStopsStore.loadSavedCompanyStops(true)
  }

  popAndRefresh(): void {
    sidebar.pop()
    this.handleRefresh()
  }

  handleError(err: string): void {
    EventBus.$emit('snackbar:error', err)
  }

  handleUpdateCount(count: number): void {
    this.tableItemCount = count
  }

  handleAddCompanyStop(): void {
    sidebar.push({
      title: 'Add Saved Stop',
      component: CompanyStopsForm,
    })
  }

  handleSelectCompanyStop(companyStop: CompanyStop): void {
    sidebar.popAllAndPush({
      title: 'Saved Stop',
      component: CompanyStopsForm,
      props: { companyStop },
    })
  }

  async handleDeleteCompanyStops(stops: CompanyStop[]): Promise<void> {
    try {
      const promises = stops.map(({ id }) => id).map(client.delete)
      await Promise.all(promises)

      this.handleRefresh()
    } catch (err) {
      console.error(err)
    } finally {
      this.selectedStops = []
    }
  }

  deleteCompanyStops(stops: CompanyStop[]): void {
    this.isModalOpen = true
    this.selectedStops = stops
  }

  actions: TableAction[] = [
    {
      displayText: 'Delete',
      key: 'delete',
      icon: 'delete',
      action: this.deleteCompanyStops,
    },
  ]

  columns: DataTableColumn[] = [
    {
      _t_id: 'f27aed0d-12bf-4d56-89e7-a28aecd27dd2',
      text: 'Name',
      value: 'name',
      type: 'link',
      clickEvent: 'company-stops:handle-select-stop',
    },
    {
      _t_id: '896997ed-1e1e-43f0-add2-5e40424d40fd',
      text: 'Address',
      value: 'address/addressName',
    },
    {
      _t_id: '26ae0474-3333-4e67-8e0e-50538f7aa1c6',
      text: 'Notes',
      value: 'notes',
    },
  ]

  mounted(): void {
    EventBus.$on('company-stops:refresh', this.popAndRefresh)
    EventBus.$on('company-stops:error', this.handleError)
    EventBus.$on(
      'company-stops:handle-select-stop',
      this.handleSelectCompanyStop
    )
  }

  beforeDestroy(): void {
    EventBus.$off('company-stops:refresh', this.popAndRefresh)
    EventBus.$off('company-stops:error', this.handleError)
    EventBus.$off(
      'company-stops:handle-select-stop',
      this.handleSelectCompanyStop
    )
  }
}
