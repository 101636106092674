
import { Vue, Component, Prop } from 'vue-property-decorator'
import SettingsGeneralBranding from './SettingsGeneralBranding.vue'
import SettingsGeneralCompanyProfile from '@/components/SettingsGeneralCompanyProfile.vue'
import SettingsGeneralTerms from '@/components/SettingsGeneralTerms.vue'
import auth from '@/store/modules/auth'
import SettingsGeneralDefaults from '@/components/SettingsGeneralDefaults.vue'

@Component
export default class SettingsGeneral extends Vue {
  @Prop({ required: false, default: null }) readonly termsId: number

  tabs = []
  loading = true

  defaultTabIndex = 0

  setTabs(): void {
    let routeName = this.$route.name
    let mode = ''
    if (routeName === 'settings.general.terms.add') {
      mode = 'add'
      routeName = 'settings.general.terms'
    } else if (routeName === 'settings.general.terms.edit') {
      mode = 'edit'
      routeName = 'settings.general.terms'
    }

    this.tabs = [
      {
        label: 'Company Profile',
        name: 'settings.general.company-profile',
        hash: 'company-profile',
        component: SettingsGeneralCompanyProfile,
        title: 'Company Profile',
        props: { companyId: auth.getCompanyId },
      },
      {
        label: 'Defaults',
        name: 'settings.general.defaults',
        subtabHashes: [
          'defaults-general',
          'defaults-payments',
          'defaults-notifications',
        ],
        title: 'General Defaults',
        component: SettingsGeneralDefaults,
      },
      // POST LAUNCH
      // {
      //   label: 'Notifications',
      //   name: 'settings.general.notifications',
      //   component: Placeholder,
      // },
      {
        label: 'Branding',
        name: 'settings.general.branding',
        component: SettingsGeneralBranding,
        title: 'Branding',
        hash: 'branding',
        props: { companyId: auth.getCompanyId },
      },
      {
        label: 'Terms',
        name: 'settings.general.terms',
        hash: 'terms',
        title: 'Terms',
        component: SettingsGeneralTerms,
        props: {
          companyId: auth.getCompanyId,
          mode,
          termsId: this.termsId,
        },
      },
    ]
    this.defaultTabIndex = this.tabs.findIndex((tab) => {
      return tab.name === routeName
    })
  }

  mounted(): void {
    this.setTabs()
    this.loading = false
  }
}
