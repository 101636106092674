import {
  MarketRate,
  MarketRateGroup,
  MarketRateRequest,
} from '@/models/dto/MarketRate'
import {
  MarketRateTypeKeys,
  PricingMethodKeys,
  PricingMethodLabels,
  VehicleTypeKeys,
  VehicleTypeLabels,
} from '@/utils/enum'
import {
  CharterUpMarketRateGroup,
  CharterUpMarketRateRequest,
  CharterUpRate,
} from '@/models/dto/CharterUp'
import marketRates from '@/services/marketRates'

export const toMarketRateRequest = (
  marketRate: MarketRate
): MarketRateRequest => {
  const marketRateTypeKey =
    MarketRateTypeKeys[
      Object.keys(PricingMethodLabels)[
        Object.values(PricingMethodLabels).indexOf(
          marketRate.marketRateType as PricingMethodLabels
        )
      ]
    ]
  const vehicleTypeKey =
    VehicleTypeKeys[
      Object.keys(VehicleTypeLabels)[
        Object.values(VehicleTypeLabels).indexOf(
          marketRate.vehicleType as VehicleTypeLabels
        )
      ]
    ]
  return {
    marketRateId: marketRate.marketRateId,
    marketId: marketRate.marketId,
    lowRate: marketRate.lowRate,
    highRate: marketRate.highRate,
    companyId: marketRate.companyId,
    vehicleId: marketRate.vehicleId,
    marketplace: !!marketRate.marketplace,
    hourlyMinimum: marketRate.hourlyMinimum,
    active: true,
    vehicleTypeKey,
    marketRateTypeKey,
  }
}

export const toMarketRateGroups = (
  acc: Record<string, MarketRateGroup>,
  marketRate: MarketRate
): Record<string, MarketRateGroup> => {
  const key = `${marketRate.vehicleType}|${marketRate.vehicleId}`
  if (!acc[key]) {
    acc[key] = {
      id: 1 + Object.values(acc).length,
      vehicleType: marketRate.vehicleType,
      vehicleTypeId: marketRate.vehicleTypeId,
      vehicleId: marketRate.vehicleId,
      vehicleName: marketRate.vehicleName,
      hourlyMinimum:
        marketRate.marketRateType === PricingMethodLabels.HOURLY_RATE
          ? marketRate.hourlyMinimum
          : null,
      dailyRate:
        marketRate.marketRateType === PricingMethodLabels.DAILY_RATE
          ? marketRate
          : null,
      liveMileRate:
        marketRate.marketRateType === PricingMethodLabels.MILEAGE_RATE
          ? marketRate
          : null,
      deadMileRate:
        marketRate.marketRateType === PricingMethodLabels.DEAD_MILE_RATE
          ? marketRate
          : null,
      hourlyRate:
        marketRate.marketRateType === PricingMethodLabels.HOURLY_RATE
          ? marketRate
          : null,
    }
  } else if (marketRate.marketRateType === PricingMethodLabels.DAILY_RATE) {
    acc[key] = { ...acc[key], dailyRate: marketRate || null }
  } else if (marketRate.marketRateType === PricingMethodLabels.MILEAGE_RATE) {
    acc[key] = { ...acc[key], liveMileRate: marketRate || null }
  } else if (marketRate.marketRateType === PricingMethodLabels.DEAD_MILE_RATE) {
    acc[key] = { ...acc[key], deadMileRate: marketRate || null }
  } else if (marketRate.marketRateType === PricingMethodLabels.HOURLY_RATE) {
    acc[key] = {
      ...acc[key],
      hourlyRate: marketRate || null,
      hourlyMinimum: marketRate.hourlyMinimum,
    }
  }
  return acc
}

export const toCharterUpMarketRateRequest = (
  rate: CharterUpRate
): CharterUpMarketRateRequest => {
  return {
    marketRateId: rate.marketRateId,
    companyId: rate.companyId,
    marketId: rate.marketId,
    highRate: rate.highRate,
    lowRate: rate.lowRate,
    hourlyMinimum: rate.hourlyMinimum || null,
    marketRateTypeKey: rate.marketRateTypeKey,
    marketRateTypeDTO: rate.marketRateTypeDTO,
    vehicleTypeKey: rate.vehicleTypeKey,
    vehicleTypeDTO: rate.vehicleTypeDTO,
    marketplace: Number(rate.marketplace || 0),
    vehicleId: rate.vehicleId,
  }
}

export const toCharterUpMarketRateGroups = (
  acc: Record<string, CharterUpMarketRateGroup>,
  rate: CharterUpRate
): Record<string, CharterUpMarketRateGroup> => {
  const key = `${rate.vehicleTypeKey}`
  if (!acc[key]) {
    acc[key] = {
      id: 1 + Object.values(acc).length,
      vehicleTypeKey: rate.vehicleTypeKey,
      vehicleTypeId: null, // Not included in Rate Response
      vehicleId: null, // Not included in Rate Response
      vehicleName: null, // Not included in Rate Response
      dailyRate:
        rate.marketRateTypeKey === PricingMethodKeys.DAILY_RATE ? rate : null,
      liveMileRate:
        rate.marketRateTypeKey === PricingMethodKeys.MILEAGE_RATE ? rate : null,
      deadMileRate:
        rate.marketRateTypeKey === PricingMethodKeys.DEAD_MILE_RATE
          ? rate
          : null,
      hourlyRate:
        rate.marketRateTypeKey === PricingMethodKeys.HOURLY_RATE ? rate : null,
      transferRate:
        rate.marketRateTypeKey === PricingMethodKeys.TRANSFER_RATE
          ? rate
          : null,
    }
  } else if (rate.marketRateTypeKey === PricingMethodKeys.DAILY_RATE) {
    acc[key] = { ...acc[key], dailyRate: rate || null }
  } else if (rate.marketRateTypeKey === PricingMethodKeys.MILEAGE_RATE) {
    acc[key] = { ...acc[key], liveMileRate: rate || null }
  } else if (rate.marketRateTypeKey === PricingMethodKeys.DEAD_MILE_RATE) {
    acc[key] = { ...acc[key], deadMileRate: rate || null }
  } else if (rate.marketRateTypeKey === PricingMethodKeys.HOURLY_RATE) {
    acc[key] = { ...acc[key], hourlyRate: rate || null }
  } else if (rate.marketRateTypeKey === PricingMethodKeys.TRANSFER_RATE) {
    acc[key] = { ...acc[key], transferRate: rate || null }
  }
  acc[key].hourlyMinimum = rate.hourlyMinimum
  return acc
}

export const applyToMarketRateGroup = <T>(
  marketRateGroup: MarketRateGroup,
  // eslint-disable-next-line no-unused-vars
  applyFn: (_: MarketRate) => T
): T[] => {
  return [
    applyFn(marketRateGroup.dailyRate),
    applyFn(marketRateGroup.liveMileRate),
    applyFn(marketRateGroup.deadMileRate),
    applyFn(marketRateGroup.hourlyRate),
  ]
}

export const applyToCharterUpMarketRateGroup = <T>(
  charterUpMarketRateGroup: CharterUpMarketRateGroup,
  // eslint-disable-next-line no-unused-vars
  applyFn: (_: CharterUpRate) => T
): T[] => {
  return [
    applyFn(charterUpMarketRateGroup.dailyRate),
    applyFn(charterUpMarketRateGroup.liveMileRate),
    applyFn(charterUpMarketRateGroup.deadMileRate),
    applyFn(charterUpMarketRateGroup.hourlyRate),
    applyFn(charterUpMarketRateGroup.transferRate),
  ]
}
