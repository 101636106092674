import { PricingMethodLabels } from '@/utils/enum'

export interface MarketRate {
  marketRateId: number
  marketId: number
  lowRate: number
  highRate: number
  hourlyMinimum?: number
  vehicleType: string
  marketRateType: string
  companyId: number
  vehicleTypeId: number
  marketplace: number
  companyName?: string
  vehicleId?: number
  vehicleName?: string
  retroactive: boolean
}

// eslint-disable-next-line no-redeclare
export class MarketRate implements MarketRate {
  constructor(marketRate?: Partial<MarketRate>) {
    Object.assign(this, marketRate)
  }
}

export interface MarketRateGroup {
  id: number
  vehicleType: string
  vehicleTypeId: number
  vehicleId?: number
  vehicleName?: string
  liveMileRate: MarketRate
  deadMileRate: MarketRate
  hourlyRate: MarketRate
  dailyRate: MarketRate
  hourlyMinimum?: number
}

// eslint-disable-next-line no-redeclare
export class MarketRateGroup implements MarketRateGroup {
  constructor(
    marketRateGroup?: Partial<MarketRateGroup>,
    marketRate?: Partial<MarketRate>
  ) {
    this.dailyRate = new MarketRate({
      marketRateType: PricingMethodLabels.DAILY_RATE,
      ...marketRate,
    })
    this.liveMileRate = new MarketRate({
      marketRateType: PricingMethodLabels.MILEAGE_RATE,
      ...marketRate,
    })
    this.deadMileRate = new MarketRate({
      marketRateType: PricingMethodLabels.DEAD_MILE_RATE,
      ...marketRate,
    })
    this.hourlyRate = new MarketRate({
      marketRateType: PricingMethodLabels.HOURLY_RATE,
      ...marketRate,
    })
    this.hourlyMinimum = marketRateGroup?.hourlyMinimum
    Object.assign(this, marketRateGroup)
  }
}

export interface MarketRateRequest {
  marketRateId: number
  marketId: number
  lowRate: number
  highRate: number
  hourlyMinimum?: number
  vehicleTypeKey: string
  marketRateTypeKey: string
  companyId: number
  vehicleId?: number
  marketplace: boolean
  active: boolean
}
