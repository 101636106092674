
import sidebar from '@/store/modules/sidebar'
import { EventBus } from '@/utils/eventBus'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import userClient from '@/services/user'
import driverClient from '@/services/driver'
import { validationRules, validateRules } from '@/utils/rules'
import { UserRequest, VehicleType } from '@/models/dto'
import CreateUserTypeSelector from '@/components/CreateUserTypeSelector.vue'
import auth from '@/store/modules/auth'
import typeClient from '@/services/type'
import { parseExistingUserError } from '@/utils/error'

@Component({
  components: {
    CreateUserTypeSelector,
  },
})
export default class CreateUserSidebar extends Vue {
  @Prop({ default: false, type: Boolean }) readonly companyOnly: boolean

  rules = validationRules
  newUser = new UserRequest()
  selectedUserType = ''
  loading = false
  accessSettingsRoleNames: string[] = []
  errors = {
    email: null,
  }

  @Watch('newUser.email')
  emailChanged(): void {
    this.errors.email = null
  }

  handleUserTypeChange(type: string): void {
    this.selectedUserType = type
    switch (type) {
      case 'Admin':
        this.newUser.groupId = 1
        this.newUser.treatAsDriver = false
        break
      case 'Office User':
        this.newUser.groupId = 2
        this.newUser.treatAsDriver = false
        break
      case 'Driver':
        this.newUser.groupId = 4
        this.newUser.treatAsDriver = true
        break
      case 'Admin + Driver':
        this.newUser.groupId = 1
        this.newUser.treatAsDriver = true
        break
      case 'Office User + Driver':
        this.newUser.groupId = 2
        this.newUser.treatAsDriver = true
        break
    }
  }

  cancel(): void {
    sidebar.pop()
  }

  async submit(): Promise<void> {
    if (!(await validateRules(this))) {
      return
    }
    this.loading = true

    const user = {
      ...this.newUser,
      phone: this.newUser.phone.replace(/\D+/g, ''),
      companyId: auth.getCompanyId,
      locale: 'en_US',
    }

    if (user.userRoleNames == null) {
      user.userRoleNames = []
    }
    user.userRoleNames.push(...this.accessSettingsRoleNames)

    let userResult = null
    if (user.treatAsDriver) {
      try {
        await driverClient
          .createFromUserForm(user)
          .then((res) => res.data)
          .then((data) => {
            userResult = data
          })
        EventBus.$emit('create-user:refresh', userResult.userId)
        sidebar.close()
        EventBus.$emit('refresh-tableview')
      } catch (err) {
        this.handleUserCreationError(err)
      } finally {
        this.loading = false
      }
    } else {
      try {
        await userClient
          .create(user)
          .then((res) => res.data)
          .then((data) => {
            userResult = data
          })
        EventBus.$emit('create-user:refresh', userResult.userId)
        sidebar.close()
        EventBus.$emit('refresh-tableview')
      } catch (err) {
        this.handleUserCreationError(err)
      } finally {
        this.loading = false
      }
    }
  }

  handleUserCreationError(e: any): void {
    const message = e.response.data.message
    if (message.includes('email')) {
      this.errors.email = parseExistingUserError(message)
    } else {
      EventBus.$emit('create-user:error', e)
    }
  }

  async getVehicleTypes(): Promise<VehicleType[]> {
    const response = await typeClient.vehicleTypeTableView({ pageSize: -1 })
    return response.data.resultList
  }

  async loadDriverDetailVehicleTypes(): Promise<void> {
    const response = await this.getVehicleTypes()
    this.newUser.driverSupportedVehicles = response.map((vehicleType) => ({
      supported: false,
      vehicleTypeId: vehicleType.id,
      label: vehicleType.label,
    }))
  }

  updateAccessSettings(accessSettingsRoleNames: string[]): void {
    this.accessSettingsRoleNames = accessSettingsRoleNames
  }

  created(): void {
    this.loadDriverDetailVehicleTypes()
  }

  mounted(): void {
    EventBus.$on('user-access-settings:updated', this.updateAccessSettings)
  }

  beforeDestroy(): void {
    EventBus.$off('user-access-settings:updated', this.updateAccessSettings)
  }
}
