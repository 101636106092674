import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { ApiResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  create(id: number): Promise<AxiosResponse<ApiResult>> {
    return httpService.post(`https://${apiBaseUrl()}/sms/smsNumber/${id}`, null)
  },
}
