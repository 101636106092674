
import { DriverPaySettings, PaymentCycle } from '@/models/dto/DriverPaySettings'
import auth from '@/store/modules/auth'
import driverPay from '@/services/driverpay'
import { EventBus } from '@/utils/eventBus'
import { Component, Vue } from 'vue-property-decorator'
import CUButton from './CUButton.vue'
import CUTextField from './CUTextField.vue'

@Component({ components: { CUButton, CUTextField } })
export default class SettingsDriverPayPayroll extends Vue {
  frequency = [
    { label: 'Weekly', key: 'WEEKLY' },
    { label: 'Twice a Month', key: 'TWICE_A_MONTH' },
  ]
  days = [
    { label: 'Monday', key: 'MONDAY' },
    { label: 'Tuesday', key: 'TUESDAY' },
    { label: 'Wednesday', key: 'WEDNESDAY' },
    { label: 'Thursday', key: 'THURSDAY' },
    { label: 'Friday', key: 'FRIDAY' },
    { label: 'Saturday', key: 'SATURDAY' },
    { label: 'Sunday', key: 'SUNDAY' },
  ]

  settings: DriverPaySettings = new DriverPaySettings()
  original: string

  get isModified(): boolean {
    return JSON.stringify(this.settings) !== this.original
  }

  get paymentCycle(): PaymentCycle {
    return this.settings?.paymentCycle || new PaymentCycle()
  }

  updatePaymentCycle(firstDay: string): void {
    if (!this.settings.paymentCycle) {
      this.settings.paymentCycle = new PaymentCycle()
    }

    this.settings.paymentCycle.firstDay = firstDay
  }

  async save(): Promise<void> {
    try {
      await driverPay.updateCompanyDefaults(this.settings)
      EventBus.$emit('snackbar:success', 'Payroll settings saved successfully.')
    } catch (err) {
      EventBus.$emit('snackbar:error', 'Failed to save payroll settings.')
    } finally {
      this.original = JSON.stringify(this.settings)
      this.settings = JSON.parse(this.original)
    }
  }

  async mounted(): Promise<void> {
    try {
      const res = await driverPay.getCompanyDefaults(auth.getCompanyId)
      this.settings = res.data
      this.original = JSON.stringify(this.settings)
    } catch (err) {
      EventBus.$emit('snackbar:error', 'Failed to load payroll settings.')
    }
  }
}
