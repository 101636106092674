
import { Component, Vue } from 'vue-property-decorator'
import { Tab } from '@/models/dto/Tab'
import SettingsCustomChargesCustomCharges from '@/components/SettingsCustomChargesCustomCharges.vue'
import SettingsCustomChargesMarkups from '@/components/SettingsCustomChargesMarkups.vue'
import SettingsCustomChargesTaxes from '@/components/SettingsCustomChargesTaxes.vue'

@Component({ metaInfo: { title: 'Charges' } })
export default class SettingsCustomCharges extends Vue {
  tabs: Tab[] = [
    {
      label: 'Custom Charges',
      hash: 'custom-charges',
      component: SettingsCustomChargesCustomCharges,
      title: 'Custom Charges',
    },
    {
      label: 'Markups',
      hash: 'markups',
      component: SettingsCustomChargesMarkups,
      title: 'Markups',
    },
    {
      label: 'Taxes',
      hash: 'taxes',
      component: SettingsCustomChargesTaxes,
      title: 'Taxes',
    },
  ]
}
