
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Terms } from '@/models/dto/Terms'
import { validateRules, validationRules } from '@/utils/rules'
import { apiBaseUrl } from '@/utils/env'
import { EventBus } from '@/utils/eventBus'

@Component({})
export default class SettingsGeneralTermsForm extends Vue {
  @Prop({ required: false }) readonly value!: Terms
  rules = validationRules
  terms = new Terms()

  get isModeEdit(): boolean {
    return !!this.value
  }

  cancel(): void {
    this.$emit('cancel')
  }

  submit(): void {
    validateRules(this).then((isValid) => {
      if (isValid) {
        this.$emit('submit', this.terms)
      }
    })
  }

  handleCopyTermsLink(): void {
    const url = `https://customers.${apiBaseUrl(null)}/terms/${this.value.hash}`
    navigator.clipboard.writeText(url)
    EventBus.$emit('snackbar:success', 'Link copied to clipboard!')
  }

  mounted(): void {
    if (this.value) {
      this.terms = { ...this.value }
    }
  }
}
